import React from 'react';

import {
  footerNavigationClickEvent,
  footerNavigationEvent,
} from 'utils/analytics';
import { Maybe } from '__generated__/graphql';
import { CallToActionObjectType } from 'groq/objects/CallToActionObject';

import { FooterLink } from './FooterLink';

type FooterLinksProps = {
  links?: Maybe<CallToActionObjectType[]>;
  parentNavigationName: Capitalize<string>;
  listItemStyle?: string;
  linkStyle?: string;
};

export const FooterLinks: React.FC<FooterLinksProps> = ({
  links,
  parentNavigationName,
  listItemStyle = 'flex',
  linkStyle = '',
}) => {
  return (
    <>
      {links?.map(({ link, title, id, newtab }) => (
        <li key={id} role="none" className={listItemStyle}>
          <FooterLink
            role="menuitem"
            newtab={!!newtab}
            linkStyle={linkStyle}
            onClick={() => {
              if (title) {
                footerNavigationEvent({
                  parentNavigation: `${parentNavigationName}:${title}`,
                  linkClicked: title,
                });
              }
              footerNavigationClickEvent(`${parentNavigationName}:${title}`);
            }}
            href={link}
          >
            {title}
          </FooterLink>
        </li>
      ))}
    </>
  );
};
