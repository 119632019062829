import { LocaleString } from 'groq/shared/LocaleString';
import { MainNavItem, MainNavItemType } from 'groq/shared/MainNavItem';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import { MainNavItemLink } from 'groq/shared/MainNavItemLink';
import { Maybe } from 'utils/types';

export type MainNavQueryType = {
  _id: string;
  userMenuQuicklinks?: Maybe<Array<CallToActionObjectType>>;
  children?: Maybe<Array<MainNavItemType>>;
};

export type MainNavQueryTypeWithMobile = {
  mainNav: {
    _id: string;
    userMenuQuicklinks?: Maybe<Array<CallToActionObjectType>>;
    children?: Maybe<Array<MainNavItemType>>;
  };
  mobileOnly: {
    _id: string;
    children?: Maybe<Array<MainNavItemType>>;
  };
};

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    MainNav: MainNavQueryTypeWithMobile;
  }
}

const children = `children[] {
    "id": _key,
    _type,
    ${LocaleString('label')},
    ${MainNavItemLink('link')},
    ${MainNavItem('navChildren[]')},
    ${MainNavItem('children[]', 4)},
    availableOn
  }`;

const mainNav = `*[_type == "MainNav" && country == $country][0] {
  _id,
  ${CallToActionObject('userMenuQuicklinks[]')},
    ${children}[!defined(availableOn)
    || availableOn == "all"
    || availableOn == "web"
    || availableOn == null]
}`;

const mainNavMobileOnly = `*[_type == "MainNav" && country == $country][0] {
  _id,
  ${children}[availableOn == "mobileApp"]
}`;

export const MainNavQuery = `{
    "mainNav": ${mainNav},
    "mobileOnly": ${mainNavMobileOnly}
}`;
