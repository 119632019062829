const excludedCharacters = [
  `'`,
  `,`,
  `™`,
  `®`,
  `”`,
  `(`,
  `)`,
  '`',
  '!',
  '#',
  '%',
  '&',
  '*',
  '_',
  '+',
  ':',
  ';',
  '=',
  '?',
  '@',
  '[',
  `\\`, // Required for escaping brackets
  ']',
  '{',
  '}',
  '"',
  `\\\\`,
  '|',
  '~',
  '^',
  '<',
  '>',
  ' ',
  '’',
];

const replaceCharactersList = {
  á: 'a',
  é: 'e',
  è: 'e',
  í: 'i',
  ó: 'o',
  ú: 'u',
  ñ: 'n',
  ä: 'ae',
  ë: 'e',
  ï: 'i',
  ö: 'oe',
  ü: 'ue',
  ÿ: 'y',
  $: 'S',
  æ: 'ae',
  ç: 'c',
  ē: 'e',
  à: 'a',
  ì: 'i',
  ò: 'o',
  ô: 'o',
  ù: 'u',
  '⁰': '0',
  '¹': '1',
  '²': '2',
  '³': '3',
  '⁴': '4',
  '⁵': '5',
  '⁶': '6',
  '⁷': '7',
  '⁸': '8',
  '⁹': '9',
};

const replaceCharacters = (productName: string): string => {
  return productName
    .split('')
    .map(char => replaceCharactersList[char] || char)
    .join('');
};

export const encodeProductNameForUri = (
  productName?: string | null
): string => {
  const regex = new RegExp(`[${excludedCharacters.join('')}]`, 'g');
  const strippedProductName = (productName ?? 'product-name')
    .toLowerCase()
    .replace(regex, '');
  return encodeURIComponent(replaceCharacters(strippedProductName))
    .replace(/%20/g, '-') // Space
    .replace(/%2F/g, '-') // Forward slash
    .replace(/\./g, '-');
};
