import { event } from './analytics-utils';
import {
  AnalyticsEvents,
  HandleIntersectionCarouselProps,
} from './analytics.types';

export const searchAnalyticsEvent = (
  resultsNumber: string,
  isPDP?: boolean
) => {
  const keys = [
    'search_type',
    'search_term',
    'suggested_section_click',
    'suggested_element_click',
    'user_action',
  ];

  const sessionValues = keys.map(key => window.sessionStorage.getItem(key));

  const eventParams = {
    search_type: sessionValues[0] ?? undefined,
    search_term: sessionValues[1] ?? undefined,
    results_number: !isPDP ? resultsNumber : undefined,
    suggested_section_click: sessionValues[2]?.toLowerCase(),
    suggested_element_click: sessionValues[3]?.toLowerCase(),
    user_action: sessionValues[4] ?? undefined,
  };
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.SEARCH,
    event_params: eventParams,
  });

  keys.forEach(key => window.sessionStorage.removeItem(key));
};

export const handleIntersectionCarousel = ({
  productId,
  intersectedProductsRef,
  intersectAllProductsRef,
  screenSizeCarousel,
  itemListId,
  itemListName,
  itemEpId,
  itemEpName,
  listProducts,
  pageviewEventHasFired,
  countProductsTriggeredRef,
}: HandleIntersectionCarouselProps) => {
  const productFinder = intersectedProductsRef.current.some(
    obj => obj?.item_id === productId
  );
  const productFinderAux = intersectAllProductsRef.current.some(
    obj => obj?.item_id === productId
  );
  if (productFinder || productFinderAux || listProducts.length <= 0) return;

  const filterProductsById = listProducts?.find(
    product => productId === product?.item_id
  );
  if (filterProductsById) {
    intersectedProductsRef.current = [
      ...intersectedProductsRef.current,
      filterProductsById,
    ];
    intersectAllProductsRef.current = [
      ...intersectAllProductsRef.current,
      filterProductsById,
    ];
  }

  const itemsLeft = listProducts.length - countProductsTriggeredRef.current;
  let isLastBatch =
    intersectAllProductsRef.current.length === listProducts.length &&
    itemsLeft < screenSizeCarousel;
  if (
    intersectedProductsRef.current.length === screenSizeCarousel ||
    isLastBatch
  ) {
    if (pageviewEventHasFired) {
      event(AnalyticsEvents.GA4EC_ItemListView, {
        event_name: AnalyticsEvents.VIEW_ITEM_LIST,
        ecommerce: {
          item_list_id: itemListId,
          item_list_name: itemListName,
          item_id_ep: itemEpId,
          item_name_ep: itemEpName,
          items: intersectedProductsRef.current,
        },
      });
    }
    intersectedProductsRef.current = [];
    countProductsTriggeredRef.current += screenSizeCarousel;
    isLastBatch = false;
  }
};
