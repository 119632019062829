import { CartItem, VariantProduct } from '__generated__/graphql';
import { ActiveCart, ActiveCartItem } from 'types/cart';
import { AnalyticsCartProduct } from 'types/analyticTypes';
import { isNotNullish } from 'utils/isNotNullish';
import { DIGITAL_GIFT_CARD_NAME } from 'utils/products';
import { getGa4Item } from 'utils/ga4Items';

import { event, getAnalyticsDimensions } from './analytics-utils';
import { AnalyticsEvents, ViewCartAnalyticsEvent } from './analytics.types';
import { transformListProductToAnalyticsItem } from './analytics-pdp';

export const sessionCartTotalEvent = (cartTotal?: number | null) =>
  event(AnalyticsEvents.GA4_SessionCartTotal, {
    event_name: AnalyticsEvents.CART,
    cart: {
      cart_total: cartTotal || 0,
    },
  });

export const toAnalyticsCart = (data: ActiveCart) => ({
  total: data?.estimatedTotal ?? 0,
  items:
    data?.products
      ?.map(x => transformCartProductToAnalyticsCartProduct(x))
      .filter(isNotNullish) ?? [],
  items_qty:
    data?.products.reduce(
      (acc, item) =>
        acc + (item.__typename === 'ProductCartItem' ? item.quantity : 1),
      0
    ) ?? 0,
});

export const transformCartProductToAnalyticsCartProduct = (
  item: ActiveCartItem | CartItem,
  includeExtraAnalyticsDimensions = false
): AnalyticsCartProduct | undefined => {
  if (item.__typename === 'ProductCartItem') {
    let coupon = '';
    if (item.adjustments) {
      coupon = item.adjustments
        .map(a => `${a.couponCode}|${a.promotionId}`)
        .join(', ');
    }

    const analyticsCartProduct: AnalyticsCartProduct = {
      coupon,
      id: item.product.styleNumber,
      variant: item.product.id,
      ean: item.product.ean ?? '',
      name: item.product.name,
      price: item.inCartPrice,
      brand: item.product.brand ?? 'PUMA',
      category: item.product.primaryCategoryId ?? '',
      quantity: item.quantity,
      full_price: item.product.price,
      unitDiscount: item.product.salePrice
        ? parseFloat((item.product.price - item.product.salePrice).toFixed(2))
        : undefined,
      ...(includeExtraAnalyticsDimensions && {
        ...getAnalyticsDimensions(item.product as VariantProduct),
      }),
      imageUrl: item.product.images[0].href,
    };

    if ('taxRate' in item && isNotNullish(item.taxRate)) {
      analyticsCartProduct.taxRate = parseFloat(
        Number(item.taxRate).toFixed(2)
      );
    }

    return analyticsCartProduct;
  }
  if (item.__typename === 'GiftCardCartItem') {
    return {
      id: '000001_01',
      variant: '',
      ean: '',
      name: DIGITAL_GIFT_CARD_NAME,
      price: item.amount,
      brand: 'PUMA',
      category: '',
      quantity: 1,
      recipient_email: item.recipientEmail,
      recipient_name: item.recipientName,
      imageUrl: item.imageUrl,
    };
  }
};

export const getCombinedProductsValue = (products: any[]): number => {
  return products.reduce(
    (acc, product) =>
      acc + product.inCartPrice * (product.quantityInCart ?? product.quantity),
    0
  );
};

export const viewCartAnalyticsEvent = ({
  currency,
  products,
  couponItems,
}: ViewCartAnalyticsEvent) => {
  const value = getCombinedProductsValue(products);
  const items = products.map(p => {
    const ga4Item = getGa4Item(p.product?.styleNumber);
    return transformListProductToAnalyticsItem({
      product: p.product,
      currency,
      quantity: p.quantity,
      categories: {
        item_category: p.product?.primaryCategoryId,
      },
      size: p.product?.size?.value,
      adjustments: p.adjustments,
      itemListId: ga4Item?.lid,
      itemListName: ga4Item?.lname,
      index: ga4Item?.idx,
      creativeName: ga4Item?.cname,
      creativeSlot: ga4Item?.cslot,
      promotionId: ga4Item?.pid,
      promotionName: ga4Item?.pname,
    });
  });
  event(AnalyticsEvents.GA4EC_ViewCart, {
    event_name: AnalyticsEvents.VIEW_CART,
    ecommerce: {
      currency,
      value: Number(value.toFixed(2)),
      coupon: couponItems?.length
        ? couponItems.map(c => c.code).join('|')
        : undefined,
      items,
    },
  });
};
