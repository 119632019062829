export const hashEmail = (
  email: string | undefined | null
): string | undefined => {
  const crypto = require('crypto');

  if (email) {
    const hash = crypto.createHash('sha256');
    hash.update(email);

    const hashedEmail = hash.digest('hex');

    return hashedEmail;
  }
};
