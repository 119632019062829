import { Maybe } from 'utils/types';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import {
  LocalePortableText,
  LocalePortableTextType,
} from 'groq/shared/LocalePortableText';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/QuoteCallout.ts

export type QuoteCalloutDocumentType = {
  id: string;
  _id: string;
  _type?: Maybe<string>;
  header?: Maybe<LocaleStringType>;
  copy?: Maybe<LocaleStringType>;
  headerColor?: Maybe<string>;
  copyColor?: Maybe<string>;
  quote?: Maybe<LocaleStringType>;
  ctas?: Maybe<Array<CallToActionObjectType>>;
  disclaimer?: Maybe<LocalePortableTextType>;
};

export const QuoteCalloutDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    ${LocaleString('header')},
    ${LocaleString('copy')},
    headerColor,
    copyColor,
    ${LocaleString('quote')},
    ${CallToActionObject('ctas[]')},
    ${LocalePortableText('disclaimer')},
  }
`;
