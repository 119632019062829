import { CouponItem } from '__generated__/graphql';
import { PaymentMethodsGA4Analytics, PaymentMethod } from 'utils/constants';
import { getGa4Item } from 'utils/ga4Items';

import { event } from './analytics-utils';
import {
  AddPaymentInfoAnalyticsEvent,
  AnalyticsEvents,
  FormType,
} from './analytics.types';
import { getCombinedProductsValue } from './analytics-cart';
import { transformListProductToAnalyticsItem } from './analytics-pdp';

export const paymentClickEvent = () => {
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.PAYMENT_CLICK,
    event_params: {
      user_action: '3rd party payment',
    },
  });
};

export const convertToGA4PaymentMethod = (
  paymentMethod: PaymentMethod | string
): PaymentMethodsGA4Analytics => {
  const paymentMethodMapping = {
    [PaymentMethod.AFTERPAY]: PaymentMethodsGA4Analytics.AFTERPAY,
    [PaymentMethod.ALIPAY]: PaymentMethodsGA4Analytics.ALIPAY,
    [PaymentMethod.AMAZON_PAY]: PaymentMethodsGA4Analytics.AMAZON_PAY,
    [PaymentMethod.AMBASSADOR_CARD]: PaymentMethodsGA4Analytics.AMBASSADOR_CARD,
    [PaymentMethod.APPLE_PAY]: PaymentMethodsGA4Analytics.APPLE_PAY,
    [PaymentMethod.COD]: PaymentMethodsGA4Analytics.CASH_ON_DELIVERY,
    [PaymentMethod.CREDIT_CARD]: PaymentMethodsGA4Analytics.CREDIT_CARD,
    [PaymentMethod.GIFT_CARD]: PaymentMethodsGA4Analytics.GIFT_CARD,
    [PaymentMethod.GOOGLE_PAY]: PaymentMethodsGA4Analytics.GOOGLE_PAY,
    [PaymentMethod.KLARNA]: PaymentMethodsGA4Analytics.KLARNA,
    [PaymentMethod.ONLINE_BANKING_IN]: PaymentMethodsGA4Analytics.NET_BANKING,
    [PaymentMethod.PAY_PAY]: PaymentMethodsGA4Analytics.PAY_PAY,
    [PaymentMethod.PAYPAL]: PaymentMethodsGA4Analytics.PAYPAL,
    [PaymentMethod.UPI]: PaymentMethodsGA4Analytics.UPI,
    [PaymentMethod.WALLET]: PaymentMethodsGA4Analytics.WALLETS,
    [PaymentMethod.ZIP_PAY]: PaymentMethodsGA4Analytics.ZIP_PAY,
    [PaymentMethod.WECHAT_PAY]: PaymentMethodsGA4Analytics.WECHAT_PAY,
    [PaymentMethod.ADYEN_COMPONENT]: PaymentMethodsGA4Analytics.ADYEN_COMPONENT,
    [PaymentMethod.ADYEN_PAYPAL]: PaymentMethodsGA4Analytics.PAYPAL,
    [PaymentMethod.KLARNA_ACCOUNT]: PaymentMethodsGA4Analytics.KLARNA_ACCOUNT,
    [PaymentMethod.KLARNA_PAYMENTS]: PaymentMethodsGA4Analytics.KLARNA_PAYMENTS,
    [PaymentMethod.NET_BANKING_DEPRECATED]:
      PaymentMethodsGA4Analytics.NET_BANKING,
    [PaymentMethod.PAYTM]: PaymentMethodsGA4Analytics.PAYTM,
    [PaymentMethod.SCHEME]: PaymentMethodsGA4Analytics.CREDIT_CARD,
    [PaymentMethod.STORE_CREDIT]: PaymentMethodsGA4Analytics.STORE_CREDIT,
    [PaymentMethod.UPI_COLLECT]: PaymentMethodsGA4Analytics.UPI_COLLECT,
    [PaymentMethod.AFTERPAY_TOUCH]: PaymentMethodsGA4Analytics.AFTERPAY_TOUCH,
    [PaymentMethod.RAZORPAY]: PaymentMethodsGA4Analytics.RAZORPAY,
  };

  return (
    paymentMethodMapping[paymentMethod] ||
    (paymentMethod && paymentMethod.split('_').join(' ').toLowerCase())
  );
};

export const addPaymentInfoAnalyticsEvent = ({
  currency,
  products,
  couponItems,
  paymentInstruments,
  paymentMethodGA4,
}: AddPaymentInfoAnalyticsEvent) => {
  const value = getCombinedProductsValue(products);
  const items = products.map(product => {
    const ga4Item = getGa4Item(product.product?.styleNumber);
    return transformListProductToAnalyticsItem({
      product: product.product,
      currency: currency,
      quantity: product.quantity,
      categories: {
        item_category: product.product?.primaryCategoryId,
      },
      size: product.product?.size?.label,
      adjustments: product.adjustments,
      itemListId: ga4Item?.lid,
      itemListName: ga4Item?.lname,
      index: ga4Item?.idx,
      creativeName: ga4Item?.cname,
      creativeSlot: ga4Item?.cslot,
      promotionId: ga4Item?.pid,
      promotionName: ga4Item?.pname,
    });
  });

  const paymentType = paymentInstruments?.length
    ? paymentInstruments
        .map(c => convertToGA4PaymentMethod(c.type as PaymentMethod))
        .join('|')
    : paymentMethodGA4;

  event(AnalyticsEvents.GA4EC_AddPaymentInfo, {
    event_name: AnalyticsEvents.ADD_PAYMENT_INFO,
    ecommerce: {
      currency,
      value: Number(value.toFixed(2)),
      coupon:
        couponItems?.length > 0 &&
        !couponItems.every(item => item.discountAmount === null)
          ? couponItems.map(c => c.code).join('|')
          : undefined,
      payment_type: paymentType,
      items,
    },
  });
};

export const addShippingInfoEvent = (
  currency: string,
  shipmentMethod: string,
  couponItems: CouponItem[],
  products: any[]
) => {
  const value = getCombinedProductsValue(products);
  const items = products.map(({ product, quantityInCart, adjustments }) => {
    const size = product?.size?.label || '';
    const ga4Item = getGa4Item(product?.styleNumber);
    return transformListProductToAnalyticsItem({
      product,
      currency,
      quantity: quantityInCart,
      categories: {
        item_category: product?.primaryCategoryId,
      },
      size,
      adjustments,
      itemId: product?.styleNumber,
      itemListId: ga4Item?.lid,
      itemListName: ga4Item?.lname,
      index: ga4Item?.idx,
      creativeName: ga4Item?.cname,
      creativeSlot: ga4Item?.cslot,
      promotionId: ga4Item?.pid,
      promotionName: ga4Item?.pname,
    });
  });
  const shippingTier = shipmentMethod.toLowerCase();
  event(AnalyticsEvents.GA4EC_AddShippingInfo, {
    event_name: AnalyticsEvents.ADD_SHIPPING_INFO,
    ecommerce: {
      currency,
      value: Number(value.toFixed(2)),
      coupon: couponItems?.length
        ? couponItems.map(c => c.code).join('|')
        : undefined,
      shipping_tier: shippingTier,
      items,
    },
  });
};

export const formErrorEvent = (formType: FormType, errors: string[]) => {
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.FORM_ERROR,
    event_params: {
      section: formType,
      field_type: errors.join(' | '),
    },
  });
};
