import React from 'react';
import { useFormContext } from 'react-hook-form';
import { InputSelect } from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { useValidation } from 'hooks/useValidation';
import { useFormField } from 'ui/forms/custom/hooks/useFormField';
import {
  checkoutAddressHelpers,
  translateSelectOptions,
} from 'ui/forms/custom/helpers';
import { CustomDropdown } from 'ui/forms/custom/types';
import { matchStateAndPostalCode } from 'utils/matchStateAndPostalCode';
import { useSiteConfig } from 'hooks/useSiteConfig';

const CustomFormDropdown = ({
  dropdownField,
  prefix,
  required = false,
}: {
  dropdownField: CustomDropdown;
  prefix?: string;
  required?: boolean;
}) => {
  const form = useFormContext();
  const { env } = useSiteConfig();

  const t = useTranslate();
  const { resolveInputError } = useFormField(dropdownField);
  const [validate, dynamicValidation] = useValidation();

  const options = dropdownField.translateLabels
    ? translateSelectOptions(dropdownField.options, t)
    : dropdownField.options;

  const { watch, register } = form;

  const value = watch(dropdownField.name);

  return (
    <InputSelect
      key={dropdownField.key}
      name={dropdownField.name}
      label={dropdownField.label}
      id={dropdownField.id}
      dataTestId={dropdownField.dataTestId}
      placeholder={dropdownField.placeholder}
      required={required}
      options={options}
      errorText={resolveInputError()}
      value={value}
      disabled={dropdownField.disabled}
      ref={register({
        validate: {
          requiredField: value =>
            required && !value ? t('requiredField') : undefined,

          postalStateMatch: async value => {
            const stateFields = new Set([
              'shippingAddress.stateCode',
              'billingAddress.stateCode',
            ]);

            if (!stateFields.has(dropdownField.name)) return undefined;

            const postalCode = form.getValues(
              dropdownField.name.replace('stateCode', 'postalCode')
            );

            // Will not error if postalcode is not defined yet
            if (!postalCode || form.formState.errors.postalCode)
              return undefined;

            const watchedCountryCode =
              form.watch('shippingAddress.countryCode') ||
              form.watch('billingAddress.countryCode');

            const { isMatch } = await matchStateAndPostalCode({
              postalCode,
              stateCode: value ? (value as string) : null,
              country: watchedCountryCode,
              googleApiKey: env.googleApiKey,
            });

            // State selected does not match postalCode provided
            if (!isMatch) return t('stateInvalidPostcode');
          },

          otherValidations: async (value: string) => {
            let errorMessage: string | undefined;
            if (dropdownField.validations) {
              for (const v of dropdownField.validations) {
                if (typeof v === 'string') {
                  errorMessage = validate[v](value);
                } else {
                  errorMessage = dynamicValidation(value, form, v);
                }
                if (errorMessage) break;
              }
            }
            return errorMessage;
          },
        },
      })}
      onChange={() => {
        if (dropdownField.onChangeTrigger) {
          const helperFunc =
            checkoutAddressHelpers[dropdownField.onChangeTrigger];
          if (helperFunc) helperFunc(form, prefix);
        }
      }}
    />
  );
};

export default React.memo(CustomFormDropdown);
