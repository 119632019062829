import { useSiteConfig } from 'hooks/useSiteConfig';

export const Missing = component => {
  const { env } = useSiteConfig();
  if (env.STAGE === 'production') return null;
  return (
    <div className="bg-gray-100 py-24 text-center">
      <h1 className="text-2xl font-bold">
        {component.__typename || component._type}
      </h1>
      <p className="uppercase opacity-50">Missing component for this type</p>
    </div>
  );
};
