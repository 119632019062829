import { ProductSearchHit } from '__generated__/graphql';

import { transformListProductToAnalyticsItem } from './analytics-pdp';
import { event } from './analytics-utils';
import {
  AnalyticsEvents,
  HandleIntersectionPLPProps,
  PromoTileProps,
} from './analytics.types';

export const handleIntersectionPLP = ({
  productId,
  intersectedProductsRef,
  intersectAllProductsRef,
  listProducts,
  screenSize,
  currency,
  offset,
  pageviewEventHasFired,
  currentCategory,
  searchTerm,
  promotion,
}: HandleIntersectionPLPProps) => {
  const productFinder = intersectedProductsRef.current.some(
    obj => obj?.variantProduct?.id === productId
  );
  const productFinderAux = intersectAllProductsRef.current.some(
    obj => obj?.variantProduct?.id === productId
  );

  if (productFinder || productFinderAux) return;

  let idx = -1;
  const filterProductsById = listProducts?.find((p, i) => {
    if (productId === p?.variantProduct?.id) {
      idx = i;
      return true;
    }
    return false;
  });
  if (filterProductsById) {
    intersectedProductsRef.current = [
      ...intersectedProductsRef.current,
      { ...filterProductsById, idx },
    ];
    intersectAllProductsRef.current = [
      ...intersectAllProductsRef.current,
      { ...filterProductsById, idx },
    ];
  } else {
    if (productId === 'positioned-promo-tile') {
      const objectPromoTile: ProductSearchHit | PromoTileProps = {
        id: productId,
        variantProduct: {
          id: productId,
        },
      };
      intersectedProductsRef.current = [
        ...intersectedProductsRef.current,
        objectPromoTile,
      ];
      intersectAllProductsRef.current = [
        ...intersectAllProductsRef.current,
        objectPromoTile,
      ];
    }
  }

  if (
    intersectedProductsRef.current.length === screenSize ||
    intersectedProductsRef.current.length === listProducts.length
  ) {
    const filterIntersectedProductsRef = intersectedProductsRef.current?.filter(
      product => product.id !== 'positioned-promo-tile'
    );
    const listItems = filterIntersectedProductsRef.map(product => {
      return transformListProductToAnalyticsItem({
        product: product.variantProduct,
        currency: currency.code,
        quantity: 1,
        itemListId: searchTerm ? searchTerm : currentCategory?.id,
        itemListName: searchTerm ? searchTerm : currentCategory?.name,
        index: offset + product['idx'],
        categories: {
          // NOTE: send `item_category` anyway but with undefined value
          item_category: undefined,
        },
        ...(promotion && { ...promotion }),
      });
    });
    if (pageviewEventHasFired) {
      event(AnalyticsEvents.GA4EC_ItemListView, {
        event_name: AnalyticsEvents.VIEW_ITEM_LIST,
        ecommerce: {
          item_list_id: searchTerm ? searchTerm : currentCategory?.id,
          item_list_name: searchTerm ? searchTerm : currentCategory?.name,
          // NOTE:  "item_id_ep" & "item_name_ep" send with data
          //        only on PDP, in PLP send with "undefined" value
          item_id_ep: undefined,
          item_name_ep: undefined,
          items: listItems,
        },
      });
    }
    intersectedProductsRef.current = [];
  }
};
