const GA4_ITEMS_MAP = 'Ga4ItemsMap';
const MAX_ENTRIES = 50;

export type Ga4Item = {
  lid?: string;
  lname?: string;
  idx?: number;
  cname?: string;
  cslot?: string;
  pid?: string;
  pname?: string;
  cart?: string;
};

export type Ga4ItemTypeMap = Map<string, Ga4Item>;

const getGa4ItemsMap = (): Ga4ItemTypeMap | undefined => {
  if (typeof window === 'undefined') return undefined;

  const localStorageMap = localStorage.getItem(GA4_ITEMS_MAP);

  // If there's no data in localStorage, return an empty Map
  if (!localStorageMap) {
    return new Map<string, Ga4Item>();
  }

  try {
    // Parse the JSON and cast it into a Map with the correct types
    return new Map<string, Ga4Item>(JSON.parse(localStorageMap));
  } catch (error) {
    return new Map<string, Ga4Item>();
  }
};

export const getLastRelativeEntry = masterId => {
  const itemsMap = getGa4ItemsMap();
  if (itemsMap) {
    return Array.from(itemsMap.entries())
      .filter(e => e[0].startsWith(masterId))
      .pop();
  }
  return itemsMap;
};

export const getGa4Item = (id?: string): Ga4Item | undefined =>
  id ? getGa4ItemsMap()?.get(id) : undefined;

export const setGa4ItemInMap = (
  id: string,
  item: Ga4Item,
  preserveCartValue?: boolean
) => {
  const itemsMap = getGa4ItemsMap();
  if (itemsMap) {
    const existingItem = itemsMap.get(id);
    if (existingItem) {
      itemsMap.delete(id);
      if (preserveCartValue) {
        itemsMap.set(id, item);
      } else {
        itemsMap.set(id, { ...item, cart: existingItem.cart });
      }
    } else {
      if (itemsMap.size == MAX_ENTRIES) {
        const filteredMap = new Map();
        for (const [key, value] of itemsMap) {
          if (value.cart !== '1') {
            filteredMap.set(key, value);
          }
        }
        if (filteredMap.size > 0) {
          const victimId = Array.from(filteredMap.keys()).shift();
          itemsMap.delete(victimId);
          itemsMap.set(id, item);
        }
      } else {
        itemsMap.set(id, item);
      }
    }

    localStorage.setItem(
      GA4_ITEMS_MAP,
      JSON.stringify(Array.from(itemsMap.entries()))
    );
  }
  return itemsMap;
};

export const removeGa4ItemFromMap = (id: string) => {
  const itemsMap = getGa4ItemsMap();
  if (itemsMap) {
    itemsMap.delete(id);
    localStorage.setItem(
      GA4_ITEMS_MAP,
      JSON.stringify(Array.from(itemsMap.entries()))
    );
  }
};

export const removeGa4ItemsMap = () => {
  if (typeof window !== 'undefined') localStorage.removeItem(GA4_ITEMS_MAP);
};
