import { Maybe } from 'utils/types';
import { AdditionalContentListTextImage } from '__generated__/graphql';
import {
  LocalePortableText,
  LocalePortableTextType,
} from 'groq/shared/LocalePortableText';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/AdditionalContentSection.ts

export type AdditionalContentSectionDocumentType = {
  _id: string;
  _type: string;
  text?: Maybe<Array<Maybe<AdditionalContentListTextImage>>>;
  localeText?: Maybe<LocalePortableTextType>;
};

export const AdditionalContentSectionDocument = (field: string) => `
  ${field} {
    _id,
    _type,
    text,
    ${LocalePortableText('localeText')}
  }
`;
