type LogData = Record<string, any>;
type ErrorData = LogData & {
  err?: any;
};

/**
 * Client Side Logging Utilities
 * Client Side NextJS requires a logger based on
 * browser console to avoid importing pino to the browser bundle
 */
export const clientLogger = {
  info(data: LogData, message: string) {
    log('info', data, message);
  },
  warn: async (data: LogData, message: string) => {
    const Sentry = await import('@sentry/nextjs');

    log('warn', data, message);

    Sentry.captureMessage(message, { tags: data });
  },
  error: async (data: ErrorData, message: string) => {
    const Sentry = await import('@sentry/nextjs');

    log('error', data, message);
    Sentry.addBreadcrumb({ message });
    if (data?.err) {
      const { err, ...tags } = data;
      Sentry.captureException(err, { tags });
    }
  },
};

function log(level: string, data: any, message: string) {
  // eslint-disable-next-line no-console
  const logFn = console[level] || console.info;
  if (logFn) {
    logFn(message, data);
  }
}
