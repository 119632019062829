import React, {
  RefObject,
  createRef,
  useCallback,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { tw } from 'twind/css';

import { Link } from 'ui/elements/Link';
import { useDeviceInteraction } from 'hooks/useDeviceInteraction';
import { HoverIntent } from 'ui/components/hoverIntent';
import { useMainNavContext } from 'hooks/useMainNav';
import {
  categoryHeaderNavigationEvent,
  headerNavigationClickEvent,
} from 'utils/analytics';
import useAvailableSpace from 'hooks/useAvailableSpace';
import { MainNavItemType } from 'groq/shared/MainNavItem';

import { NavCategorySubMenu } from './NavCategorySubMenu';

export const NavMenuBar = (props: {
  categories: MainNavItemType[];
  setLinksHidden: Dispatch<SetStateAction<boolean | undefined>>;
}) => {
  const { categories } = props;
  const navListRef = useRef<HTMLUListElement>(null);

  const { calculated, items } = useAvailableSpace<
    HTMLUListElement,
    MainNavItemType
  >(navListRef, categories);

  const hiddenItems = items.filter(i => i.hidden);
  const shouldHideAllItems = hiddenItems.length > 0;

  const menuItems = useRef<RefObject<HTMLElement>[]>(
    categories.map(() => createRef())
  );

  const { isKeyboardActive } = useDeviceInteraction();

  const {
    setShowSubMenu,
    selectedCategory,
    clickedCategory,
    setSelectedCategory,
    handleCategoryClick,
    subMenuItems,
  } = useMainNavContext();

  const handleKeydown = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>, categoryId: string) => {
      if (event.key === 'ArrowDown') {
        setSelectedCategory(categoryId);
      }
    },
    [setSelectedCategory]
  );

  useEffect(() => {
    if (calculated) props.setLinksHidden(shouldHideAllItems);
  }, [calculated, shouldHideAllItems]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ul
      className="flex self-stretch flex-1 items-stretch overflow-x-hidden ml-5 hover:nav-fade-non-selected"
      role="menubar"
      ref={navListRef}
    >
      {categories.map((category, idx) => {
        const ariaSelected =
          isKeyboardActive && category.id === selectedCategory;
        return (
          <li
            key={category.id}
            data-test-id="main-nav-category-item"
            role="none"
            className={tw(
              'group whitespace-nowrap flex',
              shouldHideAllItems && 'invisible'
            )}
          >
            <HoverIntent
              className="flex self-stretch hover:nav-fade-non-selected"
              enterCallback={() => {
                if (
                  (category.children && category.children?.length > 0) ||
                  (category.navChildren && category.navChildren.length > 0)
                ) {
                  setShowSubMenu(true);
                  setSelectedCategory(category.id);
                }
              }}
              leaveCallback={() => {
                setShowSubMenu(false);
                setSelectedCategory('');
              }}
              data-test-id="hoverable-nav-item"
            >
              <div className="flex-1 flex relative">
                <Link
                  data-link-name={category.label}
                  data-link-location="top-nav-level-1"
                  onKeyDown={event => handleKeydown(event, category.id)}
                  role="menuitem"
                  aria-haspopup={true}
                  aria-expanded={ariaSelected}
                  href={category.link?.href ?? '/'}
                  query={category.link?.query}
                  variant="white"
                  ref={menuItems.current[idx]}
                  absoluteForCrawlers={true}
                  className={tw(
                    'transition-opacity flex items-center self-stretch px-4 font-bold'
                  )}
                  onClick={() => {
                    categoryHeaderNavigationEvent(category);
                    headerNavigationClickEvent(category);
                    handleCategoryClick(category.id);
                  }}
                >
                  <span
                    className={tw(
                      'text-base block mt-1 pb-1 whitespace-nowrap nav-underline',
                      ariaSelected || clickedCategory === category.id
                        ? 'nav-underline-selected'
                        : 'nav-underline-selected(group-hover:& group-focus:&)'
                    )}
                  >
                    {category.label}
                  </span>
                </Link>
              </div>
              <NavCategorySubMenu
                category={category}
                ariaSelected={ariaSelected}
                ownerRef={menuItems.current[idx]}
                subMenuItems={subMenuItems}
              />
            </HoverIntent>
          </li>
        );
      })}
    </ul>
  );
};
