import '@global-ecom/nitro-uds/fonts/index.css';

import { useCallback, useEffect, useRef, useState } from 'react';
import App, { AppProps } from 'next/app';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { Client } from 'urql';
import { useRouter } from 'next/router';
import withTwindApp from '@twind/next/shim/app';
import type { AppContext } from 'next/app';
import type { NextUrqlAppContext, WithUrqlProps } from 'next-urql';
import {
  ToastViewport,
  ToastProvider,
  TooltipProvider,
} from '@global-ecom/nitro-uds/elements';

import { CrawlerProvider } from 'hooks/useIsCrawler';
import { CONTENT_QUERY, groqPreviewInput } from 'hooks/useGroqQuery';
import { activateOptimize } from 'utils/analytics';
import { BrowsingHistoryProvider } from 'hooks/useBrowsingHistory';
import { InjectedScript } from 'ui/elements/InjectedScript';
import { Toast, ToastProps } from 'ui/elements/Toast';
import { StickyStackContextProvider } from 'hooks/useSticky';
import { runUpdates } from 'utils/updateScripts';
import {
  SUPPORTED_COUNTRY,
  SUPPORTED_LANGUAGE,
  SUPPORTED_LOCALE,
  isServer,
} from 'utils/constants';
import {
  TranslationsContext,
  getInitialTranslations,
} from 'hooks/useTranslations';
import { LocationContextProvider } from 'hooks/useLocation';
import { PreferencesProvider } from 'hooks/usePreferences';
import { SiteConfigContext, SiteConfigState } from 'hooks/useSiteConfig';
import { authEventEmitter } from 'utils/authEventEmitter';
import { AuthContextState, AuthProvider } from 'hooks/useAuth';
import { Env, PublicEnvironmentConfig, SiteConfig } from 'sites/types';
import {
  FeatureFlags,
  FrontendConfigurationDocument,
  FrontendConfigurationQuery,
  useVerifyUserAffiliationsMutation,
  useCreateShopperContextMutation,
} from '__generated__/graphql';
import { ImpersonationBanner } from 'ui/components/ImpersonationBanner';
import { Header } from 'ui/components/Header';
import { MainFooter } from 'ui/components/MainFooter';
import { GooglePayProvider } from 'hooks/useGooglePay';
import { PageEventsProvider } from 'hooks/usePageEventsContext';
import { DeviceInteractionProvider } from 'hooks/useDeviceInteraction';
import { MainNavProvider } from 'hooks/useMainNav';
import { UdsProviderWrapper } from 'ui/components/UdsProviderWrapper';
import {
  getUrlFromLocale,
  parseRequestUrl,
  removeLocalePrefix,
} from 'utils/locale';
import {
  MainNavQuery,
  MainNavQueryType,
  MainNavQueryTypeWithMobile,
} from 'groq/queries/MainNavQuery';
import { useCancelCustomerOrder } from 'hooks/useCancelOrder';
import { MiniCartProvider } from 'hooks/useMiniCart';
import { Resources } from 'locales/localeType';
import { getStoredAuthData } from 'gql/exchanges/auth';
import { getFetchOptions, withUrql } from 'gql/client';
import { CookieSettingsProvider } from 'hooks/useCookieSettings';
import { ProductHistoryProvider } from 'hooks/useProductHistory';
import { EmarsysWebExtendProvider } from 'hooks/useEmarsysWebExtend';
import { useIsServer } from 'hooks/useIsServer';
import { ForterProvider } from 'hooks/useForter';
import { GA4Provider } from 'hooks/useGA4Events';
import { ToastProvider as NewToastProvider } from 'hooks/useToast';
import { StickyButtonProvider } from 'hooks/useStickyButton';
import { AccountSettingsProvider } from 'hooks/useAccountSettings';
import { isConsentAccepted } from 'utils/dynamicYield';
import { gtmScriptText, GtmNoScript } from 'utils/googleTagManager';
import { isCrawlerCheck } from 'utils/isCrawlerCheck';

import twindConfig from '../twind.config';

const LOCAL_STORAGE_USER_CONSENT = 'user-consent-accepted';

const MinimalFooter = dynamic<Record<string, unknown>>(
  () => import('ui/components/MinimalFooter').then(x => x.MinimalFooter),
  { ssr: false }
);

const ContentManagementToolbox = dynamic(
  () => import('ui/components/ContentManagementToolbox'),
  { ssr: false }
);

const PickUpWhereYouLeftOff = dynamic<Record<string, unknown>>(
  () =>
    import('ui/components/PickUpWhereYouLeftOff').then(
      x => x.PickUpWhereYouLeftOff
    ),
  { ssr: false }
);

const CookieBanner = dynamic(
  () => import('ui/components/CookieConsentLayer/CookieBanner'),
  { ssr: false }
);

const LocationCheck = dynamic<Record<string, unknown>>(
  () => import('ui/components/LocationCheck'),
  { ssr: false }
);

if (process.env.NEXT_PUBLIC_GQL_MOCKS === 'true') {
  require('mocks');
}

interface PumaAppProps {
  locale: SUPPORTED_LOCALE;
  language: SUPPORTED_LANGUAGE;
  country: SUPPORTED_COUNTRY;
  siteConfig: PublicEnvironmentConfig;
  resources: Resources;
  host: string;
  mainNavQuery: MainNavQueryTypeWithMobile;
  isCrawler: boolean | undefined;
}

function PumaApp({
  Component,
  pageProps,
  locale,
  language,
  country,
  siteConfig,
  resources,
  host,
  mainNavQuery,
  resetUrqlClient,
  isCrawler,
}: PumaAppProps & AppProps & WithUrqlProps) {
  const router = useRouter();
  const isServer = useIsServer();

  const [pathname, queryString] = router.asPath.split('?');
  const qs = new URLSearchParams(queryString);
  const requestSource = qs.get('requestSource');
  // --------------------------------------------------------------------
  // Application updates
  // --------------------------------------------------------------------

  // If the user leaves the site then comes back to the site using their
  // back button in the browser then clientside JS is not re-executed so we
  // need to force a page reload to bootstrap the app again
  useEffect(() => {
    const handler = event => event.persisted && window.location.reload();
    window?.addEventListener('pageshow', handler);
    return () => window?.removeEventListener('pageshow', handler);
  }, []);

  // Check to see if there are any new updates to be run on the client browser
  // This should be done as early as possible in case data needs to be altered
  // or updated in a way which the rest of the app expects
  useEffect(() => {
    const shouldReload = runUpdates();
    if (!isServer && shouldReload) window.location.reload();
  }, [isServer]);

  // --------------------------------------------------------------------
  // Auth state & shopping cart
  // --------------------------------------------------------------------
  const [auth, setAuth] = useState<AuthContextState>(() => getStoredAuthData());

  useCancelCustomerOrder(auth.customerId);

  useEffect(() => {
    // Triggered after setting cookies in response to initial guestLogon, login, register or logout
    const onTokenChanged = () => setAuth(getStoredAuthData());
    authEventEmitter.on('token-changed', onTokenChanged as any);
    return () => authEventEmitter.off('token-changed', onTokenChanged as any);
  }, []);

  // Reset the urql client (and cache) if the customer ID changes
  // This occurs on login, register, logout
  const [prevCustomerId, setPrevCustomerId] = useState<string | undefined>();
  useEffect(() => {
    if (
      prevCustomerId &&
      auth.customerId &&
      prevCustomerId !== auth.customerId &&
      resetUrqlClient
    ) {
      resetUrqlClient();
    }

    setPrevCustomerId(auth.customerId);
    // We only want to re-run this if the customerId changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.customerId, resetUrqlClient]);

  // --------------------------------------------------------------------
  // Site config
  // --------------------------------------------------------------------
  const [siteContext] = useState<SiteConfigState>(() => {
    // Since "getInitialProps" only runs on the server once, siteConfig will be undefined
    // on subsequent client side transition. (See next js docs on how getInitialProps work).
    // We must capture the siteConfig in the first render in a state / closure.
    // That will be our single source of truth for the lifecycle of the app.
    // What implications does that have?
    // Never use siteConfig in _app directly. That siteConfig will be undefined on the next
    // client side routing. Use siteContext always.

    const selectFeatureFlagsEnabled = (featureFlags: FeatureFlags) => {
      return Object.entries(featureFlags)
        .filter(([_, enabled]) => Boolean(enabled))
        .flatMap(flag => flag[0].replace(/Enabled/, ''));
    };

    const localizedUrl = getUrlFromLocale(locale);
    if (!isServer) {
      window.sessionStorage.setItem(
        'analyticsReleaseVersion',
        siteConfig?.analytics.releaseVersion
      );
    }

    const localizeUrlPath = (originalPath: string, absolute?: boolean) => {
      const path = originalPath.replace(/^\//, ''); // Remove leading slash
      const localizedPath = path ? `${localizedUrl}/${path}` : localizedUrl;

      if (absolute) {
        const hostPrefix = host.startsWith('localhost')
          ? 'http://'
          : 'https://';
        const absoluteHost = `${hostPrefix}${host}`;

        return `${absoluteHost}${localizedPath}`;
      } else {
        return localizedPath;
      }
    };

    return {
      ...siteConfig,
      locale,
      country,
      host,
      language,
      localizeUrlPath,
      localizeDate: date => new Date(date).toLocaleDateString(locale),
      selectFeatureFlagsEnabled,
    } as SiteConfigState;
  });

  // --------------------------------------------------------------------
  // Translations / text
  // --------------------------------------------------------------------

  // This callback is a curry of the getInitialTranslations function
  // which has an expensive but cacheable initial setup call
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const t = useCallback(getInitialTranslations(resources), []);

  // --------------------------------------------------------------------
  // Nav links
  // --------------------------------------------------------------------
  const [allNavLinks] = useState<MainNavQueryTypeWithMobile>(mainNavQuery);
  const navLinks = allNavLinks.mainNav as MainNavQueryType;
  const mobileNavLinks = allNavLinks.mobileOnly;

  // --------------------------------------------------------------------
  // Analytics
  // --------------------------------------------------------------------
  const didStartAnalytics = useRef(false);
  useEffect(() => {
    if (didStartAnalytics.current) return;

    didStartAnalytics.current = true;

    activateOptimize();
    // We only want to re-run this effect when the site starts
  }, [auth]);

  // --------------------------------------------------------------------
  // Shopper context / source code promotions
  // --------------------------------------------------------------------
  const [sourceCodeSetInContext, setSourceCodeSetInContext] = useState(false);
  const [, createShopperContext] = useCreateShopperContextMutation();

  const sourceCode = qs.get('src');

  const hasSourceCodeInQuerystring = !!sourceCode;
  useEffect(() => {
    if (!hasSourceCodeInQuerystring || sourceCodeSetInContext) return;
    (async () => {
      await createShopperContext({ input: { sourceCode } });
      setSourceCodeSetInContext(true);
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // --------------------------------------------------------------------
  // Shopper context / ID.me Authentication
  // --------------------------------------------------------------------
  const idMeAuthenticationCode = qs.get('code');
  const [toast, setToast] = useState<ToastProps>({});

  const [, verifyUserAffiliations] = useVerifyUserAffiliationsMutation();
  const idMeEnabled = !!siteContext.env.idMeClientId;

  // Verifies user's affiliations and updates shopper context accordingly
  useEffect(() => {
    if (!idMeEnabled || !idMeAuthenticationCode) return;
    else {
      (async () => {
        const response = await verifyUserAffiliations({
          input: { authorizationCode: idMeAuthenticationCode },
        });

        if (response.data) {
          const isSuccess = !!response.data.verifyUserAffiliations?.verified;
          const toastProps: ToastProps = {
            title: isSuccess ? t('congratulations') : t('uhoh'),
            description: isSuccess ? t('idMeSuccess') : t('idMeFailure'),
            variant: isSuccess ? 'success' : 'error',
            dataTestId: 'idMeToastMessage',
          };
          setToast(toastProps);
        }
      })();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    abTestingScript,
    branchKey,
    dynamicYieldStaticUrl,
    dynamicYieldDynamicUrl,
    googleTagManagerId: googleTagManagerStringIdsFromDoppler,
    yottaaScriptUrl,
    STAGE: stage,
  } = siteContext.env;

  const {
    injectDynamicYieldScripts,
    hidePickUpWhereYouLetOff,
    isSalesfloorEnabled,
  } = siteContext.staticFeatures;

  const path = removeLocalePrefix(pathname, siteContext.locale);

  const showMinimalPageFurniture =
    path.includes('adyen') ||
    path.startsWith('/live-chat') ||
    (path.startsWith('/help') && qs.has('minimal')) ||
    (path.includes('/paypay') &&
      requestSource &&
      ['mobile-app-ios', 'mobile-app-android'].includes(requestSource));

  const setUserConsent = (val: boolean) => {
    localStorage.setItem(LOCAL_STORAGE_USER_CONSENT, val ? 'true' : 'false');
  };

  // NOTE: example of possible googleTagManagerStringIdsFromDoppler:
  // " GTM-XL6RHPD, GTM-XL6RHPD ,GTM-XL6RHPD "
  const gtmIds = googleTagManagerStringIdsFromDoppler
    .split(',')
    .map(str => str.trim())
    .filter(str => str);

  useEffect(() => {
    const val = isConsentAccepted();
    setUserConsent(val);
  }, []);

  const SF_WIDGET_CLASS = 'sf-widget-hide-all';

  const SF_WIDGET_RESTRICTED_PATHS = ['/cart', '/checkout'];
  const isSfRestrictedInThisPage = SF_WIDGET_RESTRICTED_PATHS.some(
    restrictedUrl => pathname.includes(restrictedUrl)
  );

  useEffect(() => {
    if (!isSfRestrictedInThisPage || !isSalesfloorEnabled) return;

    document.body.classList.add(SF_WIDGET_CLASS);

    return () => {
      document.body.classList.remove(SF_WIDGET_CLASS);
    };
  }, [isSfRestrictedInThisPage, isSalesfloorEnabled]);

  return (
    <>
      <Head>
        <meta charSet="utf-8" key="charSet" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        {/*
          Prevent indexing of country/locales combinations that would cause robots
          to discover duplicate content and penalise the site
        */}
        {!siteContext.searchEngineIndexableLanguages.includes(
          siteContext.language
        ) && <meta name="robots" content="noindex, nofollow" />}
        {yottaaScriptUrl && (
          <>
            <link rel="preconnect" href="https://qoerum.yottaa-prod.com" />
            <script type="text/javascript" src={yottaaScriptUrl}></script>
          </>
        )}
        {siteContext.env.STAGE !== 'development' &&
          siteContext.staticFeatures.shapeSecurity && (
            <script src="/_next/dynamic/chunks/mecrury-f937bd6abdfdd14e.js"></script>
          )}
        {abTestingScript && (
          <script type="text/javascript" src={abTestingScript}></script>
        )}
        {injectDynamicYieldScripts && (
          <>
            <link rel="preconnect" href="https://cdn-eu.dynamicyield.com" />
            <link rel="preconnect" href="https://st-eu.dynamicyield.com" />
            <link rel="preconnect" href="https://rcom-eu.dynamicyield.com" />
            <link rel="dns-prefetch" href="https://cdn-eu.dynamicyield.com" />
            <link rel="dns-prefetch" href="https://st-eu.dynamicyield.com" />
            <link rel="dns-prefetch" href="https://rcom-eu.dynamicyield.com" />
            <InjectedScript
              script={`
                  window.DY = window.DY || {};
                  DY.userActiveConsent = {
                    accepted: localStorage.getItem('${LOCAL_STORAGE_USER_CONSENT}') === 'true'
                  }
              `}
            />
            <InjectedScript
              script={`(function (id, d, s, src) {
                    if (d.getElementById(id)) return;
                    let js = d.createElement(s);
                    js.id = id; js.src = src; js.async = true;
                    let fs = d.getElementsByTagName(s)[0];
                    fs.parentNode.insertBefore(js, fs);
                  })('dy-dinamic',document,'script','${dynamicYieldDynamicUrl!}')`}
              type="text/javascript"
            />
            <InjectedScript
              script={`(function (id, d, s, src) {
                    if (d.getElementById(id)) return;
                    let js = d.createElement(s);
                    js.id = id; js.src = src; js.async = true;
                    let fs = d.getElementsByTagName(s)[0];
                    fs.parentNode.insertBefore(js, fs);
                  })('dy-static', document, 'script', '${dynamicYieldStaticUrl!}')`}
              type="text/javascript"
            />
          </>
        )}
        <link
          rel="apple-touch-icon-precomposed"
          sizes="57x57"
          href="/assets/apple-touch-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="60x60"
          href="/assets/apple-touch-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="72x72"
          href="/assets/apple-touch-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="76x76"
          href="/assets/apple-touch-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="114x114"
          href="/assets/apple-touch-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="120x120"
          href="/assets/apple-touch-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="144x144"
          href="/assets/apple-touch-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon-precomposed"
          sizes="152x152"
          href="/assets/apple-touch-icon-152x152.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/android-chrome-192x192.png"
          sizes="192x192"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/android-chrome-512x512.png"
          sizes="512x512"
        />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicon-48x48.png"
          sizes="48x48"
        />
        <meta name="application-name" content="&nbsp;" />
        <meta name="msapplication-TileColor" content="#FFFFFF" />
        <meta
          name="msapplication-TileImage"
          content="/assets/mstile-144x144.png"
        />
        <meta
          name="msapplication-square70x70logo"
          content="/assets/mstile-70x70.png"
        />
        <meta
          name="msapplication-square150x150logo"
          content="/assets/mstile-150x150.png"
        />
        <meta
          name="msapplication-wide310x150logo"
          content="/assets/mstile-310x150.png"
        />
        <meta
          name="msapplication-square310x310logo"
          content="/assets/mstile-310x310.png"
        />
        {siteContext.countryCode.toUpperCase() === SUPPORTED_COUNTRY.JP && (
          <>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
          </>
        )}
        <meta
          name="google-site-verification"
          content="fuTkqTLherNJsYYYxW6VfBMV-OHAOuOMtUp8jwytNAY"
        />
        <meta name="msvalidate.01" content="2A9EAE6A41044CA61F6FCAD3454E70A6" />
        {/* ───────────────────────────────────────────────────── */}
        {gtmIds.map((gtmId, index) => (
          <meta
            key={`metaGoogleTagManagerId${index}`}
            name={`googleTagManagerId${index}`}
            content={gtmId}
          />
        ))}
        <InjectedScript
          async
          script={
            process.env.NEXT_PUBLIC_GQL_MOCKS === 'true'
              ? `window.dataLayer = [];
              window.pageData = {
                navigation: {},
                customer: {},
              };`
              : gtmIds.map(gtmId => gtmScriptText({ gtmId })).join('')
          }
        />
        {/* ───────────────────────────────────────────────────── */}
        {siteContext.env.STAGE === 'production' && branchKey && (
          <InjectedScript
            script={`
            (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})(window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking qrCode".split(" "), 0);
            const params = navigator.userAgent.match(/((iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)|(PUMAMobileApp))/i) ? {'no_journeys': true} : undefined;
            branch.init('${branchKey}', params);
            `}
          />
        )}
        {siteContext.emarsysMerchantId && (
          <InjectedScript
            script={`
              var ScarabQueue = ScarabQueue || [];
              (function(id) {
                if (document.getElementById(id)) return;
                var js = document.createElement('script'); js.id = id;
                js.src = '//cdn.scarabresearch.com/js/${siteContext.emarsysMerchantId}/scarab-v2.js';
                var fs = document.getElementsByTagName('script')[0];
                fs.parentNode.insertBefore(js, fs);
              })('scarab-js-api');
            `}
          />
        )}
        {siteContext.staticFeatures.isPNARegion &&
          siteContext.env.klarnaDataClientId && (
            <>
              <script
                async
                data-environment={
                  stage === 'development' ? 'playground' : 'production'
                }
                src="https://js.klarna.com/web-sdk/v1/klarna.js"
                data-client-id={siteContext.env.klarnaDataClientId}
              ></script>

              <script
                src="https://x.klarnacdn.net/kp/lib/v1/api.js"
                async
              ></script>
            </>
          )}
      </Head>
      {gtmIds.map((gtmId, index) => (
        <GtmNoScript key={`GtmNoScript${index}`} gtmId={gtmId} />
      ))}
      {siteContext.countryCode.toUpperCase() === SUPPORTED_COUNTRY.GB && (
        <Script
          id="chatbot"
          strategy="afterInteractive"
          src="//eu.fw-cdn.com/12873622/840604.js"
        />
      )}
      {siteContext.liveChat?.provider === 'zendesk' && (
        <Script
          defer
          id="ze-snippet"
          strategy="lazyOnload"
          src={siteContext.liveChat.script}
        />
      )}

      {siteContext.insiderIntegration && (
        <Script
          async
          src={`//${siteContext.insiderIntegration.partnerName}.api.useinsider.com/ins.js?id=${siteContext.insiderIntegration.partnerId}`}
        />
      )}

      {/** Only inject in production */}
      {siteContext.env.STAGE === 'production' &&
        siteContext.env.CORALOGIX_RUM_KEY && (
          <Script
            key="coralogix-script"
            src="https://cdn.rum-ingress-coralogix.com/coralogix/browser/latest/coralogix-browser-sdk.js"
            onReady={() => {
              window.CoralogixRum.init({
                public_key: siteContext.env.CORALOGIX_RUM_KEY,
                application: `nitro${
                  siteContext.env.NAMESPACE === 'pre' ? '-pre-prod' : '-prod'
                }`,
                instrumentations: {
                  xhr: true,
                  fetch: true,
                  web_vitals: true,
                  interactions: true,
                  custom: true,
                  errors: true,
                  long_tasks: true,
                  resources: true,
                },
                version: siteContext.env.SHA,
                coralogixDomain: 'EU1',
                user_context: {},
                labels: {},
                sessionConfig: {
                  sessionSampleRate: isCrawler ? 100 : 1, // Percentage of overall sessions being tracked, defaults to 100%
                },
              });
            }}
          />
        )}

      <SiteConfigContext.Provider value={siteContext}>
        <TranslationsContext.Provider value={{ t }}>
          <UdsProviderWrapper>
            <AuthProvider
              auth={auth}
              setAuth={setAuth}
              country={country}
              language={language}
            >
              <BrowsingHistoryProvider>
                <LocationContextProvider>
                  <PreferencesProvider>
                    <ProductHistoryProvider>
                      <DeviceInteractionProvider>
                        <PageEventsProvider>
                          <MainNavProvider
                            navLinks={navLinks}
                            mobileNavLinks={mobileNavLinks}
                          >
                            <GooglePayProvider>
                              <MiniCartProvider>
                                <AccountSettingsProvider>
                                  <EmarsysWebExtendProvider>
                                    <StickyStackContextProvider>
                                      <CookieSettingsProvider>
                                        <GA4Provider>
                                          <StickyButtonProvider>
                                            <TooltipProvider>
                                              <CrawlerProvider
                                                isCrawler={isCrawler}
                                              >
                                                <ToastProvider
                                                  duration={7000}
                                                  label={t('notifications')}
                                                >
                                                  <NewToastProvider>
                                                    <ImpersonationBanner />
                                                    <ForterProvider>
                                                      <div className="relative min-h-screen flex flex-col">
                                                        {showMinimalPageFurniture ? (
                                                          <main>
                                                            <Component
                                                              {...pageProps}
                                                            />
                                                          </main>
                                                        ) : (
                                                          <>
                                                            <a
                                                              href="#puma-skip-here"
                                                              className="absolute left-0 top-0 px-4 py-3 text-base font-bold bg-puma-black-900 text-puma-black-900 transform -translate-y-[200%] focus:translate-y-0"
                                                            >
                                                              {t(
                                                                'skipToContent'
                                                              )}
                                                            </a>
                                                            <Header />
                                                            <main
                                                              id="puma-skip-here"
                                                              className="flex-grow"
                                                            >
                                                              <Component
                                                                {...pageProps}
                                                              />
                                                              <LocationCheck />
                                                              <CookieBanner
                                                                setCookieConsentMarketing={
                                                                  setUserConsent
                                                                }
                                                              />
                                                              {!hidePickUpWhereYouLetOff && (
                                                                <PickUpWhereYouLeftOff />
                                                              )}
                                                            </main>
                                                            {router.asPath.includes(
                                                              '/checkout'
                                                            ) ||
                                                            router.asPath.includes(
                                                              'showQuickRegister'
                                                            ) ? (
                                                              <MinimalFooter />
                                                            ) : (
                                                              <MainFooter />
                                                            )}
                                                          </>
                                                        )}
                                                        {toast.description && (
                                                          <Toast {...toast} />
                                                        )}
                                                      </div>
                                                    </ForterProvider>
                                                    {siteContext.env.STAGE !==
                                                      'production' && (
                                                      <ContentManagementToolbox />
                                                    )}
                                                    <ToastViewport />
                                                  </NewToastProvider>
                                                </ToastProvider>
                                              </CrawlerProvider>
                                            </TooltipProvider>
                                          </StickyButtonProvider>
                                        </GA4Provider>
                                      </CookieSettingsProvider>
                                    </StickyStackContextProvider>
                                  </EmarsysWebExtendProvider>
                                </AccountSettingsProvider>
                              </MiniCartProvider>
                            </GooglePayProvider>
                          </MainNavProvider>
                        </PageEventsProvider>
                      </DeviceInteractionProvider>
                    </ProductHistoryProvider>
                  </PreferencesProvider>
                </LocationContextProvider>
              </BrowsingHistoryProvider>
            </AuthProvider>
          </UdsProviderWrapper>
        </TranslationsContext.Provider>
      </SiteConfigContext.Provider>
    </>
  );
}

if (isServer) {
  PumaApp.getInitialProps = async (
    appContext: NextUrqlAppContext
  ): Promise<PumaAppProps> => {
    const context = appContext.ctx;
    // hack to get urqlClient working because urqlClient is typed as being under appContext,
    // but it's actually under appContext.ctx
    const client = (context as any).urqlClient as Client;

    // The servers request and response objects which should always
    // exist because we only run getInitialProps on the server
    const req = context.req!;
    const res = context.res!;

    const fetchOptions = getFetchOptions(context);
    fetchOptions.headers['gcdn-force'] = '1';

    // The full path of the request
    const url = req.url || '';
    const { host, locale } = req.headers;

    // For PR environments and localhost as we don't have Fastly, we use crawlerCheck to know if user is crawler or not,
    // In the other environments we use Fastly with Vary header.
    const namespace = process.env.NAMESPACE;
    const isCrawler = isCrawlerCheck({ host, namespace, req });

    // Appends "ssr" to the surrogate-key header so that Fastly can purge cache for SSR pages
    // as a group (duplicate keys are collapsed): https://docs.fastly.com/en/guides/working-with-surrogate-keys
    const surrogateKey = res.getHeader('surrogate-key');
    res.setHeader('surrogate-key', [surrogateKey, 'ssr'].flat().join(' '));

    // The requested URL that we can extract country and language
    // to create the locale that the user requested
    const { country, language } = parseRequestUrl(req);

    // Request any URL redirect and frontend configuration from
    // graphql using the urql client and appropriate queries
    const frontendConfig = await client
      .query<FrontendConfigurationQuery>(
        FrontendConfigurationDocument,
        {},
        {
          fetchOptions,
        }
      )
      .toPromise();

    // If we don't get a frontend config back something is very wrong
    // and we can't confidently proceed so we return 500 in order to make
    // sure that fastly doesn't cache the SSR'd response.
    if (frontendConfig.error || !frontendConfig.data) {
      // eslint-disable-next-line no-console
      console.error('Unable to load frontend config', {
        err: frontendConfig.error,
        url,
      });
      // @ts-expect-error
      return res.writeHead(500).end();
    }
    const frontendConfigData = frontendConfig.data?.frontendConfiguration;

    // --------------------------------------------------------------------
    // Redirect if maintenance mode is enabled
    // --------------------------------------------------------------------
    const { webInMaintenanceMode } = frontendConfigData.environment;

    if (webInMaintenanceMode?.toLowerCase() === 'true') {
      // On the server, we'll use an HTTP response to
      // redirect with the status code of our choice.
      // 307 is for temporary redirects.
      res.writeHead(307, {
        Location: `/maintenance/${country}/${language}`,
      });
      res.end();
    }
    // --------------------------------------------------------------------

    // This query is fetching the needed nav links for the mainNav component
    const mainNavQuery = await client
      .query(
        CONTENT_QUERY('MainNavChildren'),
        {
          input: {
            query: MainNavQuery,
            params: { url: '/' },
            src: context.query.src as string,
            draft: context.query.draft as string,
            preview: groqPreviewInput(context.query),
          },
        },
        {
          fetchOptions,
        }
      )
      .toPromise();

    // Dynamically import the siteconfig and translations for this locale
    const [staticSiteConfig, resources] = await Promise.all([
      import(`../sites/${country}/config`).then(
        mod => mod.config as SiteConfig
      ),
      import(`../locales/${locale}`).then(res => res.default as Resources),
    ]);

    // Safe downcast to `AppContext`. `next-urql` isn't aware of `pageProps`.
    const appProps = await App.getInitialProps(
      appContext as unknown as AppContext
    );

    const processEnv = {
      CORALOGIX_RUM_KEY: process.env.CORALOGIX_RUM_KEY,
      STAGE: process.env.STAGE,
      SHA: process.env.SHA,
      NAMESPACE: namespace,
    };

    return {
      ...appProps,
      mainNavQuery: mainNavQuery?.data?.content,
      locale: locale as SUPPORTED_LOCALE,
      language: language as SUPPORTED_LANGUAGE,
      country: country.toUpperCase() as SUPPORTED_COUNTRY,
      isCrawler,
      resources,
      // Current frontend setup: (siteConfig, staticConfig, frontendConfig, siteContext).
      // "StaticConfig" is a json file that we dynamically import based on the country.
      // This way we ensure that the rendering server only sends corresponding data to the client.
      // In that json file we have data that are exposable, mostly config values:
      // currency, flag ... .
      // These values can be extracted later on to Sanity.

      // The frontedConfig query returns the exposable "secrets" and the feature flags.
      // Behind the scenes (see resolvers/frontendConfig) we reach out to Sanity to fetch the feature
      // flags (they are highly dynamic and we'd like content creators to control them).
      // As for the exposable secrets:
      // the API is already aware all of the secrets. Therefore it just needs to filter out those that can be
      // safely sent over.
      // The "stage" variable is dependent on the CI/CD setup -> it is coming from the "process.env" var.
      // All of these item make the "siteConfig" that we return as a prop to our _app component.
      // Reminder: our _app getInitialProps only executed on the server opposed to a 'regular' "getInitialProps"
      // which is executed on the server when that is the first paint, or on the client if a client-side route
      // transition takes place. That will become important later. Next step: see the siteContext on line 344.
      siteConfig: {
        ...staticSiteConfig,
        ...frontendConfigData.siteConfig,
        features: frontendConfigData.featureFlags,
        env: {
          ...(frontendConfigData.environment as unknown as Env),
          ...(processEnv as unknown as Env),
        },
      },
      host: host!,
    };
  };
}

/**
 * Note that if we move this away we'll have to move the AuthContext.Provider as well, this could
 * be done through a HOC comparable to withAuth. This is needed because inside of a server-side render
 * we'll traverse the VDOM tree, if the Provider isn't part of this we won't be giving accurate results.
 */
// @ts-expect-error
export default withUrql(withTwindApp(twindConfig, PumaApp));
