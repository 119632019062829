import {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useCallback,
} from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import { parseCookies } from 'nookies';

import {
  COOKIE_BANNER_ACKNOWLEDGED_EVENT_NAME,
  isServer,
  MaxAgeExpiration,
} from 'utils/constants';
import {
  getHasSeenCookieBanner,
  getHasSeenCookieBannerOT,
  parseConsentCookie,
  setConsentCookie,
} from 'utils/cookie';
import { useSiteConfig } from 'hooks/useSiteConfig';

import { useFeature } from './useFeature';
import { usePreferences } from './usePreferences';

const OPTANON_CONSENT_KEY = 'OptanonConsent';

export const cookieOptions = [
  {
    title: 'yourPrivacy',
    label: 'privacy',
    toggle: false,
  },
  {
    title: 'functional',
    label: 'func',
    toggle: false,
  },
  {
    title: 'userExperienceAnalytics',
    label: 'userExperience',
    toggle: true,
  },
  {
    title: 'marketingSocialMedia',
    label: 'marketing',
    toggle: true,
  },
];

export type CookieSettings = {
  showCookieSettings: boolean;
  setShowCookieSettings: Dispatch<SetStateAction<boolean>>;
  showCookieBanner: boolean;
  acceptCookies: () => void;
  showingCookieBannerOT: boolean;
  setShowingCookieBannerOT: Dispatch<SetStateAction<boolean>>;
  maxAgeCookiesExpires?: MaxAgeExpiration;
  form: UseFormMethods<{
    userExperience: boolean;
    marketing: boolean;
  }>;
};

const CookieSettingsContext = createContext<CookieSettings>(
  {} as CookieSettings
);

export const CookieSettingsProvider = ({ children }) => {
  const {
    countryCode,
    maxAgeCookiesExpires,
    staticFeatures: { showCookieBanner },
  } = useSiteConfig();
  const { preferences } = usePreferences();
  const showCookieConsentLayer = useFeature('SHOW_COOKIE_CONSENT_LAYER');
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(
    getHasSeenCookieBanner()
  );
  const optanonCookie = parseCookies()[OPTANON_CONSENT_KEY];

  useEffect(() => {
    // If the client supports GCP and it's enabled then opt-out without showing the banner
    if (!isServer && navigator.globalPrivacyControl && !optanonCookie) {
      setConsentCookie(false, false, maxAgeCookiesExpires);
    }
  }, [optanonCookie, maxAgeCookiesExpires]);

  const [showCookieSettings, setShowCookieSettings] = useState(false);

  const [showingCookieBannerOT, setShowingCookieBannerOT] = useState(
    !getHasSeenCookieBannerOT()
  );

  useEffect(() => {
    if (countryCode === 'ca' && hasAcceptedCookies) {
      window.__rmcp2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    }
  }, [hasAcceptedCookies, countryCode]);

  const form = useForm({
    defaultValues: parseConsentCookie(),
    shouldUnregister: false,
    mode: 'onChange',
  });

  const acceptCookies = useCallback(() => {
    const { userExperience, marketing } = form.getValues();
    setShowCookieSettings(false);
    setHasAcceptedCookies(true);
    setConsentCookie(userExperience, marketing, maxAgeCookiesExpires);
    window.dispatchEvent(new Event(COOKIE_BANNER_ACKNOWLEDGED_EVENT_NAME));
  }, [form, maxAgeCookiesExpires]);

  const value = {
    form,
    acceptCookies,
    showCookieBanner:
      !!showCookieBanner &&
      !showCookieSettings &&
      showCookieConsentLayer &&
      preferences['prefers-region'] &&
      !hasAcceptedCookies &&
      !isServer &&
      !navigator.globalPrivacyControl,
    showCookieSettings,
    setShowCookieSettings,
    showingCookieBannerOT,
    setShowingCookieBannerOT,
  };

  return (
    <CookieSettingsContext.Provider value={value}>
      {children}
    </CookieSettingsContext.Provider>
  );
};

export const useCookieSettings = () => {
  const context = useContext(CookieSettingsContext);
  if (!context || Object.keys(context).length === 0) {
    throw new Error(
      'useCookieSettings must be used within a CookieSettingsProvider'
    );
  }
  return context;
};
