import React from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  InputText,
  LiveRegion,
  Stack,
} from '@global-ecom/nitro-uds/elements';

import { useTranslate } from 'hooks/useTranslations';
import { useValidation } from 'hooks/useValidation';
import { useFormData } from 'hooks/useFormData';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useLoginAndRegister } from 'hooks/useLoginAndRegister';
import { NavigationFunction, OTPAuthStage } from 'ui/forms/OTPForm';
import { extractError } from 'utils/extractError';

export type LoginOTPFormType = { phoneNo: string };

export const LoginOTPForm = ({
  navigate,
}: {
  navigate: NavigationFunction;
}) => {
  const t = useTranslate();
  const [validate] = useValidation();

  const {
    validation: {
      phone: {
        prefix,
        length: { max },
      },
    },
  } = useSiteConfig();

  const {
    register: registerField,
    handleSubmit,
    errors,
    formState,
    watch,
  } = useForm<{ phoneNo: string }>({
    mode: 'onChange',
    defaultValues: { phoneNo: prefix },
  });

  const { data, setValues } = useFormData();

  const phoneNoFieldValue = watch('phoneNo');

  React.useEffect(() => {
    setValues && setValues({ phoneNo: phoneNoFieldValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNoFieldValue]);

  const {
    loginWithOTP: [loginWithOTPResult, loginWithOTP],
  } = useLoginAndRegister();

  const onSubmit = async (data: LoginOTPFormType) => {
    const result = await loginWithOTP({
      phoneNo: data.phoneNo,
    });

    if (result.data?.loginWithOTP) {
      setValues({ phoneNo: data.phoneNo, pinId: result.data.loginWithOTP });
      navigate(OTPAuthStage.confirm_otp);
    }
  };

  const error = extractError(loginWithOTPResult) ?? data?.error;

  return (
    <Stack asChild dataTestId="login-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        {error && (
          <LiveRegion type="assertive">
            <Alert
              data-test-id="login-form-error"
              variant="error"
              content={error}
            />
          </LiveRegion>
        )}
        <InputText
          label={t('phoneNumberLabel')}
          id="phoneNo"
          dataTestId="auth-field-phoneNo"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.phoneNumber,
            maxLength: {
              value: max,
              message: t<'genericFieldMaxLength'>('genericFieldMaxLength', {
                length: max,
              }),
            },
          })}
          maxLength={max}
          name="phoneNo"
          errorText={errors.phoneNo?.message}
          placeholder={t('phonePlaceholder')}
          type="tel"
          required
          crossOrigin={undefined}
        />
        <Button
          dataTestId="get-otp-btn"
          id="login-submit"
          loading={loginWithOTPResult.fetching}
          disabled={loginWithOTPResult.fetching || !formState.isValid}
          type="submit"
          label={t('getOTP')}
          dimmedReason={t('pleaseCorrectInputErrors')}
        />
        <p>{t('otpLoginHeader')}</p>
        <Stack direction={{ initial: 'row' }} justify="start" gapY="none">
          <Button
            variant="underline"
            dataTestId="otp-login-with-email-password"
            onClick={() => navigate(OTPAuthStage.login_with_email)}
            label={t('loginWithEmailAndPassword')}
          />
          <Button
            variant="underline"
            dataTestId="otp-login-with-phone-password"
            onClick={() => navigate(OTPAuthStage.login_with_phone_password)}
            label={t('loginWithPhoneAndPassword')}
          />
        </Stack>
      </form>
    </Stack>
  );
};
