import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  InputPassword,
  InputText,
  LiveRegion,
  Stack,
} from '@global-ecom/nitro-uds/elements';
import { useRouter } from 'next/router';
import { tw } from 'twind';

import { useTranslate } from 'hooks/useTranslations';
import { useValidation } from 'hooks/useValidation';
import { useFormData } from 'hooks/useFormData';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useLoginAndRegister } from 'hooks/useLoginAndRegister';
import TermsAndConditions from 'ui/components/TermsAndConditions';
import TermsAndConditionsBackup from 'ui/components/TermsAndConditionsBackup';
import { extractCode, extractError } from 'utils/extractError';
import { loginEvent } from 'utils/analytics';
import { usePageEventsContext } from 'hooks/usePageEventsContext';
import { useMainNavContext } from 'hooks/useMainNav';

import { NavigationFunction, OTPAuthStage } from './OTPForm';

export const LoginWithPhoneAndPassword = ({
  onSuccess,
  navigate,
  onForgotPasswordClick,
  isInPopover,
}: {
  onSuccess?: () => void;
  navigate?: NavigationFunction;
  onForgotPasswordClick: () => void;
  isInPopover?: boolean;
}) => {
  const t = useTranslate();
  const [validate] = useValidation();

  const {
    validation: {
      phone: { prefix },
    },
  } = useSiteConfig();

  const {
    register: registerField,
    handleSubmit,
    errors,
    formState,
    watch,
  } = useForm<{ phoneNo: string; password: string }>({
    mode: 'onChange',
    defaultValues: { phoneNo: prefix },
  });

  const router = useRouter();

  const { data, setValues } = useFormData();
  const { pageviewEventHasFired } = usePageEventsContext();
  const { isForgotPasswordFormOpen, mobileMenuOpen } = useMainNavContext();

  const phoneFieldValue = watch('phoneNo');

  useEffect(() => {
    setValues && setValues({ phoneNo: phoneFieldValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneFieldValue]);

  const {
    loginWithPhoneAndPassword: [loginResult, login],
  } = useLoginAndRegister();

  const onSubmit = async (data: { password: string; phoneNo: string }) => {
    const result = await login({
      ...data,
    });

    if (result.data?.loginWithPhone) {
      const { email } = result.data?.loginWithPhone.user || {};
      const { customerId } = result.data?.loginWithPhone || {};

      if (pageviewEventHasFired) loginEvent(router.asPath, email, customerId);
      onSuccess && onSuccess();
    }
  };

  const error =
    extractCode(loginResult) === 'CUSTOMER_NOT_FOUND'
      ? "Customer with phone number provided wasn't found. Please check phone number or use another login option."
      : extractError(loginResult) ?? data?.error;

  return (
    <Stack
      className={tw(isForgotPasswordFormOpen && !mobileMenuOpen && 'hidden')}
      asChild
      dataTestId="login-form"
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {error && (
          <LiveRegion type="assertive">
            <Alert
              dataTestId="login-form-error"
              content={error}
              variant="error"
            />
          </LiveRegion>
        )}

        <p>{t('enterPhoneAndPassword')}</p>
        <InputText
          id="auth-field-phoneNo"
          label={t('phoneNumberLabel')}
          dataTestId="auth-field-phoneNo"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.phoneNumber,
          })}
          name="phoneNo"
          errorText={errors.phoneNo?.message}
          placeholder={t('phonePlaceholder')}
          type="tel"
          required
          crossOrigin={undefined}
        />
        <InputPassword
          label={t('passwordLabel')}
          id="password"
          dataTestId="auth-field-password"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.password,
          })}
          name="password"
          errorText={errors.password?.message}
          placeholder={t('passwordPlaceholder')}
          required
          crossOrigin={undefined}
        />
        {!isInPopover && (
          <TermsAndConditions
            page="tacOnCreateAccount"
            backupContent={
              <TermsAndConditionsBackup page="tacOnCreateAccount" />
            }
          ></TermsAndConditions>
        )}
        <Button
          dataTestId="auth-button-login"
          label={t('login')}
          id="login-submit"
          loading={loginResult.fetching}
          disabled={loginResult.fetching || !formState.isValid}
          type="submit"
          dimmedReason={t('pleaseCorrectInputErrors')}
        />
        <Stack direction={{ initial: 'column' }} items="start" gapY="none">
          <Button
            variant="underline"
            dataTestId="auth-button-otp-login"
            onClick={() => navigate?.(OTPAuthStage.login)}
            label={t('loginWithOTP')}
          />
          <Button
            variant="underline"
            dataTestId="auth-button-forgot-password"
            onClick={onForgotPasswordClick}
            label={t('resetPassword')}
          />
        </Stack>
      </form>
    </Stack>
  );
};
