import { Maybe } from 'utils/types';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';
import { BackgroundOverlayObjectType } from 'groq/objects/BackgroundOverlayObject';
import {
  LocalePortableText,
  LocalePortableTextType,
} from 'groq/shared/LocalePortableText';

import { HorizontalAlignment, Puid, VerticalAlignment } from '../global-types';

import {
  CountdownClockDocument,
  CountdownClockDocumentType,
} from './CountdownClockDocument';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/FullBleedHero.ts

export type FullBleedHeroType = {
  id: string;
  _id: string;
  _type: string;
  backgroundColor?: Maybe<string>;
  backgroundImage: SanityImageObjectType;
  backgroundOverlay?: Maybe<BackgroundOverlayObjectType>;
  copy?: Maybe<LocaleStringType>;
  countdownClock?: Maybe<CountdownClockDocumentType>;
  ctas?: Maybe<Array<CallToActionObjectType>>;
  footnote?: Maybe<LocaleStringType>;
  header?: Maybe<LocaleStringType>;
  disclaimer?: Maybe<LocalePortableTextType>;
  horizontalAlignment?: Maybe<HorizontalAlignment>;
  logo?: Maybe<SanityImageObjectType>;
  logoMobile?: Maybe<SanityImageObjectType>;
  logoPosition?: Maybe<string>;
  minAspectRatio: number;
  mobileBackgroundImage?: Maybe<SanityImageObjectType>;
  mobileBackgroundOverlay?: Maybe<BackgroundOverlayObjectType>;
  overlayContent?: Maybe<boolean>;
  puid?: Maybe<Puid>;
  textAlignment?: Maybe<HorizontalAlignment>;
  textColor?: Maybe<string>;
  verticalAlignment?: Maybe<VerticalAlignment>;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
};

export const FullBleedHeroDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    ${SanityImageObject('backgroundImage', 'desktop', 'mobileBackgroundImage')},
    ${SanityImageObject('mobileBackgroundImage', 'mobile')},
    ${SanityImageObject('logo', 'desktop', 'logoMobile')},
    ${SanityImageObject('logoMobile', 'mobile')},
    ${LocaleString('header')},
    ${LocaleString('copy')},
    ${LocaleString('footnote')},
    ${CallToActionObject('ctas[]')},
    ${CallToActionObject('link')},
    ${LocalePortableText('disclaimer')},
    linkType,
    logoPosition,
    textAlignment,
    horizontalAlignment,
    verticalAlignment,
    textColor,
    backgroundColor,
    overlayContent,
    minAspectRatio,
    puid,
    ${CountdownClockDocument('countdownClock->')},
    backgroundOverlay { ... },
    mobileBackgroundOverlay { ... },
    availableFrom,
    availableTo,
  }
`;
