import { lowerCaseOrBlank, lowerCaseRemoveSpecialChars } from 'utils/string';
import { CartItem, StandardOrder, VariantProduct } from '__generated__/graphql';

export const getOrderConfirmationFitAnalyticsData = (products: CartItem[]) => {
  return {
    products: products
      .filter((cartItem: CartItem) => cartItem.__typename === 'ProductCartItem')
      .map((cartItem: CartItem) => {
        let productCartItem: VariantProduct;
        if (
          cartItem.__typename === 'ProductCartItem' &&
          cartItem.product.__typename
        ) {
          productCartItem = cartItem.product;
        } else {
          return cartItem;
        }

        return {
          purchasedSize: productCartItem.size.label,
          shopArticleCode: productCartItem.upc ?? '',
          productId: productCartItem.styleNumber,
          price: cartItem.inCartPrice,
          quantity: cartItem.quantity,
          ean: productCartItem.ean ?? '',
        };
      }),
  };
};

export const getUserOrderInformation = (order: StandardOrder) => {
  return {
    order_email: lowerCaseOrBlank(order?.customer?.email),
    phone_number: lowerCaseOrBlank(order?.shipment?.address?.phone),
    address_fname: lowerCaseRemoveSpecialChars(
      order?.shipment?.address?.firstName
    ),
    address_lname: lowerCaseRemoveSpecialChars(
      order?.shipment?.address?.lastName
    ),
    address_street: lowerCaseRemoveSpecialChars(
      order?.shipment?.address?.address1
    ),
    address_state: lowerCaseRemoveSpecialChars(
      order?.shipment?.address?.stateCode
    ),
    address_postal: lowerCaseRemoveSpecialChars(
      order?.shipment?.address?.postalCode
    ),
    address_city: lowerCaseRemoveSpecialChars(order?.shipment?.address?.city),
    address_country: order?.shipment?.address?.countryCode,
  };
};
