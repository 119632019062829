import emojiRegex from 'emoji-regex';
import { useState, useCallback, useMemo } from 'react';
import { OperationResult, useClient } from 'urql';
import { FieldValues, UseFormMethods } from 'react-hook-form';

import { HOLDER_NAME_NOT_ALLOWED_CHARACTERS } from 'sites/jp/fields';
import { SUPPORTED_COUNTRY } from 'utils/constants';
import { PINCODE_QUERY } from 'gql/queries/pincode';
import {
  PincodeQuery,
  PincodeServiceability,
  QueryPincodeArgs,
} from '__generated__/graphql';
import { FormFieldsResources } from 'locales/localeType';

import { useFeature } from './useFeature';
import { useSiteConfig } from './useSiteConfig';
import { useTranslate } from './useTranslations';

const UNSAFE_INPUT_REGEX = /[<>{}]|^\s+$/;
const ADDRESS_UNSAFE_INPUT_REGEX = /[<>{}%]|^\s+$/;
const NUMBERS_ONLY_REGEX = /^[0-9]*$/;
const ALPHANUMERIC_ONLY_REGEX = /^[a-zA-Z0-9 ]+$/;
const US_DATE_FORMAT_REGEX =
  /^((?:0[1-9])|(?:1[0-2]))\/((?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))\/(\d{4})$/;
const GB_DATE_FORMAT_REGEX =
  /^((?:0[1-9])|(?:[1-2][0-9])|(?:3[0-1]))\/((?:0[1-9])|(?:1[0-2]))\/(\d{4})$/;
// Does not contain only spaces. Contains A..Za-z single space between words.
const ENGLISH_LETTERS_ONLY_REGEX = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
// Does not contain only spaces. Only latin characters including the one with accent on top and German special.
const LATIN_ONLY_REGEX = /^(?!\s+$)[\u0000-~\u0080-ž’”]+$/;

type ValidationFunctionReturn = string | undefined;

type ValidationFunction = (
  input: string,
  values?: string | object
) => ValidationFunctionReturn;

type DynamicValidationOps = 'is';
type DynamicValidationCondition = 'requiredIf';
export type DynamicValidationFormat =
  `${DynamicValidationCondition}.${string}.${DynamicValidationOps}.${string}`;
export type DynamicValidation = {
  validation: DynamicValidationFormat;
  error?: keyof FormFieldsResources;
};
export type DynamicValidationFunction = <T extends FieldValues>(
  input: string,
  form: UseFormMethods<T>,
  format: DynamicValidation
) => ValidationFunctionReturn;

type ConfirmPasswordValidationFunction = (
  input: string,
  otherPasswordInput?: string | object
) => ValidationFunctionReturn;

type PostalCodeValidationFunction = (
  postalCode: string,
  countryCode?: string | object
) => ValidationFunctionReturn;

export type Validations = {
  addressLine1: ValidationFunction;
  addressLine2: ValidationFunction;
  addressTitle: ValidationFunction;
  alphanumericOnly: ValidationFunction;
  confirmPassword: ConfirmPasswordValidationFunction;
  dateOfBirth: ValidationFunction;
  email: ValidationFunction;
  englishLettersOnly: ValidationFunction;
  holderName: ValidationFunction;
  holderNameExcludeCharacters: ValidationFunction;
  latinLettersOnly: ValidationFunction;
  name: ValidationFunction;
  noEmoji: ValidationFunction;
  numbersOnly: ValidationFunction;
  orderNumberValidation: ValidationFunction;
  password: ValidationFunction;
  phoneNumber: ValidationFunction;
  postalCode: PostalCodeValidationFunction;
  safeInput: ValidationFunction;
  companyName: ValidationFunction;
};

export type PincodeValidationResponse =
  | OperationResult<{ pincode: PincodeServiceability }, QueryPincodeArgs>
  | undefined;

export type PincodeValidationPayload = [
  { fetching: boolean; data?: PincodeQuery },
  (value: string) => Promise<PincodeValidationResponse>
];

export const usePincodeValidation = (): PincodeValidationPayload => {
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState<PincodeQuery>();

  // We use "useClient" because useQuery's refetch function returns void.
  // in other words, it fires up our intent to refetch the data, but we dont have any indication when the data arrives.
  // For async validation we need that information to indicate if the network request is finished.
  // validate: async () => await refetch() // when refetch finishes we know our validation result came back, but this cannot be done with void.
  const client = useClient();

  const isPincodeEnabled = useFeature('PINCODE_SERVICEABILITY');

  const callback = useCallback(
    async (value: string): Promise<PincodeValidationResponse> => {
      if (!value || !isPincodeEnabled) return;

      try {
        setFetching(true);

        const res = await client
          .query<{ pincode: PincodeServiceability }, QueryPincodeArgs>(
            PINCODE_QUERY,
            { pincode: value },
            { requestPolicy: 'network-only' }
          )
          .toPromise();

        setData(res.data);

        return res;
      } finally {
        setFetching(false);
      }
    },
    [isPincodeEnabled, client]
  );

  return useMemo(() => {
    return [{ fetching, data }, callback];
  }, [callback, fetching, data]);
};

export const useValidation = (): [Validations, DynamicValidationFunction] => {
  const t = useTranslate();

  const { validation, countryCode } = useSiteConfig();

  const safeInput = useCallback(
    (input: string): string | undefined => {
      if (UNSAFE_INPUT_REGEX.test(input)) return t('invalidCharacters');
    },
    [t]
  );

  const noEmoji = useCallback(
    (input: string): string | undefined => {
      if (emojiRegex().test(input)) return t('invalidCharacters');
    },
    [t]
  );

  const addressLine1 = useCallback(
    (input: string): string | undefined => {
      if (ADDRESS_UNSAFE_INPUT_REGEX.test(input)) return t('invalidCharacters');

      if (!new RegExp(validation.address.line1).test(input))
        return t('invalidAddress');
    },
    [t, validation.address.line1]
  );

  const addressLine2 = useCallback(
    (input: string): string | undefined => {
      if (ADDRESS_UNSAFE_INPUT_REGEX.test(input)) return t('invalidCharacters');

      if (!validation.address.line2) return;

      if (!new RegExp(validation.address.line2).test(input))
        return t('invalidAddress');
    },
    [t, validation.address.line2]
  );

  const addressTitle = useCallback(
    (input: string): string | undefined => {
      if (ADDRESS_UNSAFE_INPUT_REGEX.test(input)) return t('invalidCharacters');

      const noEmojiError = noEmoji(input);
      if (noEmojiError) return noEmojiError;

      if (!validation.address.title) return;

      if (!new RegExp(validation.address.title).test(input)) {
        return t('invalidCharacters');
      }
    },
    [noEmoji, t, validation.address.title]
  );

  const email = useCallback(
    (input: string): string | undefined => {
      if (validation.email && !new RegExp(validation.email).test(input))
        return t('invalidEmail');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const name = useCallback(
    (input: string): string | undefined => {
      const safeInputError = safeInput(input);
      if (safeInputError) return safeInputError;

      const noEmojiError = noEmoji(input);
      if (noEmojiError) return noEmojiError;

      if (!validation.name?.regex) return;

      if (!new RegExp(validation.name.regex).test(input)) {
        return t('invalidCharacters');
      }
    },
    [noEmoji, safeInput, t, validation.name.regex]
  );

  const companyName = useCallback(
    (input: string): string | undefined => {
      const safeInputError = safeInput(input);
      if (safeInputError) return safeInputError;

      const noEmojiError = noEmoji(input);
      if (noEmojiError) return noEmojiError;

      if (!validation.name?.regex) return;

      if (!new RegExp(validation.name.regex).test(input)) {
        return t('invalidCharacters');
      }
    },
    [noEmoji, safeInput, t, validation.name.regex]
  );

  const englishLettersOnly = useCallback(
    (input: string): string | undefined => {
      if (input && !new RegExp(ENGLISH_LETTERS_ONLY_REGEX).test(input)) {
        return t('invalidCharacters');
      }
    },
    [t]
  );

  const latinLettersOnly = useCallback(
    (input: string): string | undefined => {
      if (input && !new RegExp(LATIN_ONLY_REGEX).test(input)) {
        return t('invalidNonLatinCharacters') || t('invalidCharacters');
      }
    },
    [t]
  );

  const phoneNumber = useCallback(
    (input: string): string | undefined => {
      if (!new RegExp(validation.phone.regex).test(input))
        return t('enterValidPhoneNumber');
    },
    [t, validation.phone.regex]
  );

  const alphanumericOnly = useCallback(
    (text: string): string | undefined => {
      if (!ALPHANUMERIC_ONLY_REGEX.test(text))
        return (
          t('inputAlphanumericCharactersWarning') || t('invalidCharacters')
        );
    },
    [t]
  );

  const postalCode: PostalCodeValidationFunction = useCallback(
    (inputPostalCode, inputCountryCode) => {
      if (typeof inputCountryCode === 'object') return;
      const finalCountryCode = inputCountryCode || countryCode;

      if (
        !new RegExp(validation.postalCode[finalCountryCode.toUpperCase()]).test(
          inputPostalCode
        )
      ) {
        if (inputCountryCode && inputCountryCode in SUPPORTED_COUNTRY) {
          const countryKey =
            inputCountryCode.toLowerCase() as Lowercase<SUPPORTED_COUNTRY>;
          return t(`invalidPostalCode_${countryKey}`) || t('invalidPostalCode');
        }
        return t(`invalidPostalCode_${countryCode}`) || t('invalidPostalCode');
      }
    },
    [countryCode, t, validation.postalCode]
  );

  const dateOfBirth = useCallback(
    (input: string): string | undefined => {
      // TODO: Ideally this would be based on site config to support non-US date formats,
      // but the only place it is currently used (SignUpForm) requires this format.
      switch (countryCode) {
        case 'gb':
          if (!GB_DATE_FORMAT_REGEX.test(input)) {
            return t('newsletterPopoverDobInvalid');
          }
          break;

        default:
          if (!US_DATE_FORMAT_REGEX.test(input)) {
            return t('newsletterPopoverDobInvalid');
          }
          break;
      }
    },
    [t, countryCode]
  );

  const password = useCallback(
    (input: string): string | undefined => {
      const safeInputError = safeInput(input);
      if (safeInputError) return safeInputError;

      if (validation.password && !new RegExp(validation.password).test(input))
        return t('passwordWarning');
    },
    [safeInput, t, validation.password]
  );

  const confirmPassword: ConfirmPasswordValidationFunction = useCallback(
    (input, otherPasswordInput): string | undefined => {
      if (typeof otherPasswordInput === 'object') return;
      const passwordError = password(input);
      if (passwordError) return passwordError;

      if (input !== otherPasswordInput) return t('passwordsDontMatch');
    },
    [password, t]
  );

  const numbersOnly = useCallback(
    (
      text: string,
      number?: string | object | undefined
    ): string | undefined => {
      if (!NUMBERS_ONLY_REGEX.test(text))
        switch (number) {
          case '4':
            return t('cvvNumberOnlyAmbassador', {
              count: number,
            });
          case '19':
            return t('numberCardAmbassador', {
              count: number,
            });
          default:
            return 'Input may only contain numbers.';
        }
    },
    [t]
  );

  const orderNumberValidation = useCallback(
    (input: string): string | undefined => {
      const safeInputError = safeInput(input);
      if (safeInputError) return safeInputError;

      const noEmojiError = noEmoji(input);
      if (noEmojiError) return noEmojiError;

      if (!validation.orderNo) return;

      if (!new RegExp(validation.orderNo).test(input)) {
        return t('orderNoValidationError');
      }
    },
    [noEmoji, safeInput, t, validation.orderNo]
  );

  const holderName = useCallback(
    (input: string): string | undefined => {
      if (!validation.holderName?.regex) return;
      if (!new RegExp(validation.holderName.regex).test(input))
        return t('invalidCharacters');
    },
    [t, validation.holderName?.regex]
  );

  const holderNameExcludeCharacters = useCallback(
    (input: string): string | undefined => {
      const notAllowedCharacters =
        '[' + HOLDER_NAME_NOT_ALLOWED_CHARACTERS + ']+';
      if (input && new RegExp(notAllowedCharacters).test(input)) {
        return t('requiredFieldHolderNameAccount');
      }
    },
    [t]
  );

  const dynamicValidation: DynamicValidationFunction = useCallback(
    (input, form, v) => {
      const matches = v.validation.match(
        /(?<condition>requiredIf)\.\[(?<subject>.+)\]\.(?<op>is)\.(?<value>.+)/
      );

      if (matches) {
        const condition = matches.groups?.condition;
        const subject = matches.groups?.subject;
        const op = matches.groups?.op as DynamicValidationOps;
        const value = matches.groups?.value;

        // Modify this block as needed to support more dynamic validation
        if (subject && op && value) {
          const targetValue = form.getValues(subject);

          if (condition === 'requiredIf' && op === 'is') {
            if (
              typeof targetValue === 'string' &&
              targetValue?.toLowerCase() === value.toLowerCase()
            ) {
              if (!input) {
                return v.error ? t(v.error) : t('requiredField');
              }
            }
          }
        }
      }
    },
    [t]
  );

  return [
    {
      addressLine1,
      addressLine2,
      addressTitle,
      alphanumericOnly,
      confirmPassword,
      dateOfBirth,
      email,
      englishLettersOnly,
      holderName,
      holderNameExcludeCharacters,
      latinLettersOnly,
      name,
      noEmoji,
      numbersOnly,
      orderNumberValidation,
      password,
      phoneNumber,
      postalCode,
      safeInput,
      companyName,
    },
    dynamicValidation,
  ];
};
