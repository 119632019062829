import { useEffect, useRef } from 'react';

import { isServer } from '../utils/constants';

export const useIsServer = () => {
  // A ref is used as it will not trigger a rerender,
  // so the value will always be consistent
  const isServerRef = useRef(isServer);

  useEffect(() => {
    // Updates on the client after hydration
    isServerRef.current = false;
  }, []);

  return isServerRef.current;
};
