import { gql } from 'urql';

// This fragment has all the non-optional fields. This must be shared between PLP product nodes and pdp queries
// so the cahce will not treat the entry as a miss.
export const MANDATORY_VARIANT_FIELDS = gql`
  fragment mandatoryVariantFields on Variant {
    id
    masterId
    variantId
    name
    header
    subHeader
    price
    colorValue
    colorName
    ean
    sizeGroups {
      label
      description
      sizes {
        id
        label
        value
        productId
        orderable
        maxOrderableQuantity
      }
    }
    preview
    images {
      alt
      href
      verticalImageHref
    }
  }
`;

export const PDP_MANDATORY_VARIANT_FIELDS = gql`
  fragment pdpMandatoryVariantFields on Variant {
    id
    masterId
    variantId
    name
    header
    subHeader
    price
    colorValue
    colorName
    ean
    preview
    images {
      alt
      href
      verticalImageHref
    }
  }
`;

export const PDP_MANDATORY_EXTRA_VARIANT_FIELDS = gql`
  fragment pdpMandatoryExtraVariantFields on Variant {
    id
    sizeGroups {
      label
      description
      sizes {
        id
        label
        value
        productId
        orderable
        maxOrderableQuantity
      }
    }
  }
`;

// This fragment has all the non-optional fields. This must be shared between PLP product nodes and pdp queries
// so the cahce will not treat the entry as a miss.
export const MANDATORY_MASTER_FIELDS = gql`
  fragment mandatoryMasterFields on Product {
    name
    id
    header
    subHeader
    orderableColorCount
    displayOutOfStock {
      soldout
      soldoutWithRecommender
      comingsoon
      backsoon
      presale
      displayValue
    }
    colors {
      name
      value
      image {
        href
        verticalImageHref
        alt
      }
    }
    image {
      href
      verticalImageHref
      alt
    }
    showExploreCollectionCTA
  }
`;

export const SIZES_FRAGMENT = gql`
  fragment sizes on Product {
    productMeasurements {
      metric
      imperial
    }
  }
`;

export const PRODUCT_ANALYTICS_DIMENSIONS_FRAGMENT = gql`
  fragment productAnalyticsDimensionsFields on VariantProduct {
    colorDescription
    sportName
    technology
    gender
    ageGroup
    lineName
    businessUnit
    businessSegment
    businessSubSegment
    division
    articleGroup
    articleType
    franchise
    collectionMark
  }
`;
