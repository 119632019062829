import { Maybe } from 'utils/types';
import { Puid, VerticalAlignment } from 'groq/global-types';
import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import {
  SanityImageObject,
  SanityImageObjectType,
} from 'groq/objects/SanityImageObject';
import {
  SanityVideoObject,
  SanityVideoObjectType,
} from 'groq/objects/SanityVideoObject';
import {
  LocalePortableText,
  LocalePortableTextType,
} from 'groq/shared/LocalePortableText';
import { LocaleString, LocaleStringType } from 'groq/shared/LocaleString';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/documents/PromoTile.ts

export type PromoTileDocumentType = {
  id: string;
  _id: string;
  _type: 'PromoTile';
  backgroundColor?: Maybe<string>;
  backgroundImage?: Maybe<SanityImageObjectType>;
  contentBelow?: Maybe<boolean>;
  copy?: Maybe<LocaleStringType>;
  cta?: Maybe<CallToActionObjectType>;
  header?: Maybe<LocaleStringType>;
  mobileBackgroundImage?: Maybe<SanityImageObjectType>;
  puid?: Maybe<Puid>;
  textColor?: Maybe<string>;
  verticalAlignment?: Maybe<VerticalAlignment>;
  video?: Maybe<SanityVideoObjectType>;
  availableFrom?: Maybe<string>;
  availableTo?: Maybe<string>;
  disclaimer?: Maybe<LocalePortableTextType>;
};

export const PromoTileDocument = (field: string) => `
  ${field} {
    "id": _id,
    _id,
    _type,
    ${LocaleString('header')},
    ${LocaleString('copy')},
    textColor,
    backgroundColor,
    verticalAlignment,
    contentBelow,
    ${LocalePortableText('disclaimer')},
    ${SanityVideoObject('video')},
    ${SanityImageObject('backgroundImage', 'desktop', 'mobileBackgroundImage')},
    ${SanityImageObject('mobileBackgroundImage', 'mobile')},
    ${CallToActionObject('cta')},
    availableFrom,
    availableTo,
    puid,
  }
`;
