import React, { useState, useLayoutEffect } from 'react';
import { style, tw } from 'twind/style';
import { createPortal } from 'react-dom';

import { useFocusTrap, useBodyScrollLock, useDialogDismiss } from 'hooks/a11y';
import { isServer } from 'utils/constants';
import { useResizeObserver } from 'hooks/useResizeObserver';
import { getIsMobile } from 'utils/media';

export type ModalProps = {
  onClickOutside?: () => void | undefined;
  className?: string;
  dataTestId?: string;
  variant?: 'light' | 'dark' | 'darkOpacity';
  layout?: 'fullscreen' | 'drawerLeft' | 'drawerRight';
  animateClassName?: string;
  containerClassName?: string;
  isFocused?: boolean;
};

const overlay = style({
  base: 'flex fixed left-0 top-0 m-0 w-full h-screen z-50 overflow-y-auto font-display p-4 xs:p-8 overflow-y-scroll',
  variants: {
    variant: {
      light: 'bg-white bg-opacity-50',
      dark: 'bg-puma-black bg-opacity-50',
      darkOpacity: 'bg-puma-black bg-opacity-10',
    },
    layout: {
      fullscreen: 'xs:p-0 p-0',
      drawerLeft: 'xs:p-0 p-0',
      drawerRight: 'xs:p-0 p-0',
    },
  },
  defaults: {
    variant: 'light',
  },
});

const modal = style({
  base: 'relative bg-white m-auto shadow-xl rounded-md',
  variants: {
    variant: {
      light: '',
      dark: '',
      darkOpacity: '',
    },
    layout: {
      drawerLeft: 'w-full max-w-md rounded-none ml-0 min-h-full',
      drawerRight: 'w-full max-w-md rounded-none mr-0 min-h-full',
      fullscreen: 'w-full rounded-none shadow-none min-h-full',
    },
  },
});

const Modal: React.FC<ModalProps> = props => {
  const { ref } = useResizeObserver() || {};

  const [container] = useState(() => {
    const element = document.createElement('div');
    element.setAttribute('tabindex', '-1');
    return element;
  });

  useLayoutEffect(() => {
    document.body.insertBefore(container, null);
    return () => {
      if (document.body.contains(container)) {
        document.body.removeChild(container);
      }
    };
  }, [container]);

  const modalRef = React.useRef(null);

  useDialogDismiss({
    ref: modalRef,
    handler: e => {
      // This prevents the Insider modal from interfering with the MiniCart modal's onClickOutside event
      // https://pumaglobal.atlassian.net/browse/CBC-7400
      if ((e?.target as HTMLElement).closest('.ins-preview-wrapper')) return;
      return props.onClickOutside?.() || (() => null);
    },
  });
  useFocusTrap({ ref: modalRef, isActive: true, isFocused: props.isFocused });
  useBodyScrollLock(true);

  if (isServer) return null;

  const element = (
    <div
      id="modal"
      ref={ref}
      className={tw(
        overlay({ variant: props.variant, layout: props.layout }),
        props.containerClassName
      )}
      data-test-id={props.dataTestId}
      style={{
        margin: 0,
        border: 0,
        height: getIsMobile() ? '100%' : window.innerHeight,
        top: 0,
        left: 0,
        pointerEvents: 'auto', // Allows us to interact with the Modal when displayed over a UDS Dialog
      }}
    >
      <div
        role="dialog"
        aria-modal="true"
        className={tw(
          modal({ variant: props.variant, layout: props.layout }),
          props.className,
          props.animateClassName
        )}
        tabIndex={-1}
        ref={modalRef}
      >
        {props.children}
      </div>
    </div>
  );

  return createPortal(element, container);
};

export default Modal;
