import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { tw } from 'twind';
import {
  TooltipRoot,
  Icon,
  TooltipContent,
  TooltipTrigger,
} from '@global-ecom/nitro-uds/elements';

import { Prose } from 'utils/serializeBlockContent';
import Modal from 'ui/components/Modal';
import { Link } from 'ui/elements/Link';
import CloseButton from 'ui/components/CloseButton';
import { useGroqQuery } from 'hooks/useGroqQuery';
import {
  GlobalPromoBannerDocument,
  GlobalPromoBannerType,
} from 'groq/documents/GlobalPromoBannerDocument';
import { Ga4Data } from 'hooks/usePromotionSelect';
import { GaTrackData, usePromotionView } from 'hooks/usePromotionView';
import { getIsDesktop, getIsMobile } from 'utils/media';
import { useDeviceOrientation } from 'hooks/useDeviceOrientation';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { getUrlFromLocale } from 'utils/locale';

import { CountdownClock } from './CountdownClock';
import { ClampedInfoMessageText } from './ClampedInfoMessageText';

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    GlobalPromoBanner: GlobalPromoBannerType;
  }
}
function useGlobalPromoBannerQuery() {
  return useGroqQuery({
    query: GlobalPromoBannerDocument,
    operationName: 'GlobalPromoBanner',
  });
}

export const GlobalPromoBanner = React.forwardRef<HTMLElement>(
  (_, forwardedRef) => {
    const router = useRouter();
    const [isReadingDetails, setReadingDetails] = useState<boolean>(false);
    const [showInfoMessage, setShowInfoMessage] = useState<boolean>(false);

    const excluded = router.asPath.includes('/checkout');
    const [result] = useGlobalPromoBannerQuery();

    const messages = result.data?.content?.messages || [];
    const duration = result.data?.content?.duration
      ? result.data?.content.duration * 1000
      : 30000;

    const isMounted = useRef(false);

    const isDesktop = getIsDesktop();

    const { locale } = useSiteConfig();
    const localeUrl = getUrlFromLocale(locale);
    const readMoreLink = `${localeUrl}/puma/sale-terms-and-conditions`;

    const ref = useRef<HTMLDivElement>(null);

    const [showingIndex, setShowingIndex] = useState<number>(0);
    const currentMessage = messages[showingIndex];

    const promotion_id = '';
    const creative_name = result.data?.content?._type || 'GlobalPromoBanner';

    const gaTrackData: GaTrackData = {
      id: promotion_id,
      name: '',
      creative: creative_name,
    };
    const { ga4PromotionTrackerPosition } = usePromotionView(
      ref,
      gaTrackData,
      true
    );

    const ga4Data: Ga4Data = {
      creative_name,
      creative_slot: ga4PromotionTrackerPosition,
      promotion_id,
      fireEventFromPdp: router.asPath.includes('/pd/'),
    };

    const deviceOrientation = useDeviceOrientation();
    // close the disclaimer when the device orientation changes.
    useEffect(() => {
      setShowInfoMessage(false);
    }, [deviceOrientation]);

    useEffect(() => {
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }, []);

    useEffect(() => {
      if (isReadingDetails) return;

      let timer;
      if (messages.length > 1) {
        timer = setInterval(() => {
          if (ref.current) {
            ref.current.style.opacity = '0';
            setTimeout(() => {
              if (isMounted.current)
                setShowingIndex(index => (index + 1) % messages.length);
              if (ref.current) ref.current.style.opacity = '1';
            }, 300);
          }
        }, duration);
      }
      return () => {
        if (timer) clearInterval(timer);
      };
    }, [isReadingDetails, duration, messages.length, isMounted]);

    const infoDetails =
      currentMessage?.information?.localeDetails ??
      currentMessage?.information?.details;

    const infoMessage = infoDetails
      ?.map(detail => detail?.children.map(el => el.text).join(''))
      .join('');

    const isMobile = getIsMobile();

    const tooltipMaxWidth = isMobile ? '460px' : '375px';

    const tooltipStyles = {
      '--tooltip-max-width': tooltipMaxWidth,
      height: '10px',
      zIndex: '50',
    };

    if (excluded || !currentMessage) {
      return null;
    }

    return (
      <aside
        ref={forwardedRef}
        aria-label="Promo Banner"
        data-test-id="promo-banner"
      >
        <section
          className={`flex h-11 bg-puma-white font-display text-black items-center justify-center mobile:text-xs text-sm`}
        >
          <div
            ref={ref}
            key={currentMessage.id}
            className={tw([
              'transition-opacity duration-300',
              'inline-flex flex-row',
            ])}
          >
            <div className="flex flex-wrap text-center leading-none font-bold justify-center items-center">
              <span>{currentMessage.text}</span>

              {currentMessage.countdownClock && (
                <CountdownClock
                  {...currentMessage.countdownClock}
                  config={{
                    timeUnitClassName: 'text-sm desktop:text-base',
                    containerClassName: 'space-x-[2px] ml-3 inline-flex',
                  }}
                />
              )}

              {currentMessage?.ctas?.map(
                ({ link, title, id, newtab, popup, query }) => (
                  <div className="flex" key={id}>
                    <Link
                      href={link}
                      variant="black"
                      size="sm"
                      underlined
                      className="inline whitespace-nowrap uppercase mobile:text-xs ml-3"
                      newtab={!!newtab}
                      popup={!!popup}
                      query={query}
                      ga4Data={ga4Data}
                    >
                      {title}
                    </Link>
                    {infoMessage && (
                      <div className="flex px-2 mt-[3px] items-center">
                        <TooltipRoot
                          dataTestId="info-icon"
                          invert
                          side="bottom"
                          align="center"
                          open={showInfoMessage}
                          onOpenChange={setShowInfoMessage}
                        >
                          <TooltipTrigger>
                            <div
                              onClick={e => {
                                if (!isDesktop) {
                                  setShowInfoMessage(!showInfoMessage);
                                }
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                            >
                              <Icon name="info-outline" size="sm" />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent
                            style={tooltipStyles}
                            className="!bg-puma-gray-20 !text-puma-black"
                          >
                            <ClampedInfoMessageText
                              infoMessage={infoMessage}
                              setShowInfoMessage={setShowInfoMessage}
                              readMoreLink={readMoreLink}
                            />
                          </TooltipContent>
                        </TooltipRoot>
                      </div>
                    )}
                  </div>
                )
              )}

              {currentMessage.information?.title && (
                <Fragment>
                  {' '}
                  <Link
                    id="moreInfoLink"
                    href="#"
                    variant="black"
                    size="sm"
                    underlined
                    className="inline whitespace-nowrap uppercase mobile:text-xs  ml-3"
                    aria-label={currentMessage.information.title}
                    onClick={() => setReadingDetails(true)}
                  >
                    {currentMessage.information.title}
                  </Link>
                </Fragment>
              )}
            </div>
          </div>
          {isReadingDetails && infoDetails && (
            <Modal
              variant="dark"
              className="w-full max-w-xl relative p-5 xs:p-8"
              onClickOutside={() => {
                setReadingDetails(false);
              }}
            >
              <CloseButton
                className="float-right p-1 ml-5 xs:ml-8"
                onClick={() => setReadingDetails(false)}
              />
              <Prose content={infoDetails} />
            </Modal>
          )}
        </section>
      </aside>
    );
  }
);

GlobalPromoBanner.displayName = 'GlobalPromoBanner';
