import React from 'react';
import { tw } from 'twind/css';
import { useRouter } from 'next/router';

import { Link } from 'ui/elements/Link';
import { isUrlSegment } from 'utils/urls';
import {
  categoryHeaderNavigationEvent,
  headerNavigationClickEvent,
} from 'utils/analytics';
import { MainNavItemType } from 'groq/shared/MainNavItem';

export const NavCategoryNavChildren = (props: {
  title: string;
  navChildren: MainNavItemType[];
}) => {
  const { query } = useRouter();

  const navMenuColors = {
    black: 'text-puma-black-300 hover:text-puma-gold',
    red: 'text-puma-red hover:text-puma-gold',
    gold: 'text-puma-gold hover:text-puma-gold-light',
  };

  return (
    <ul
      className="w-1/5 min-w-48 flex flex-col pr-8 space-y-1"
      role="menu"
      aria-label={props.title}
    >
      {props.navChildren.map(child => (
        <li key={child.id} role="none" className="whitespace-normal">
          <Link
            data-link-name={child.label}
            data-link-location="top-nav-level-2-side"
            data-test-id="top-nav-level-2-side"
            role="menuitem"
            href={child.link?.href ?? '/'}
            query={child.link?.query}
            absoluteForCrawlers={true}
            className={tw(
              isUrlSegment(child.link, query?.category)
                ? 'text-puma-gold'
                : navMenuColors[(child.navColor as string) || 'black'],
              'font-bold text-lg leading-6 whitespace-normal'
            )}
            onClick={() => {
              categoryHeaderNavigationEvent(child);
              headerNavigationClickEvent(child);
            }}
          >
            {child.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};
