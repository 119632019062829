import { AnalyticsPageType } from 'types/analyticTypes';
import {
  AnalyticsEvents,
  event,
  transformListProductToAnalyticsItem,
} from 'utils/analytics';
import { setGa4ItemInMap } from 'utils/ga4Items';

import { useSiteConfig } from './useSiteConfig';
import { usePageEventsContext } from './usePageEventsContext';
import { usePreferences } from './usePreferences';

export type Ga4Data = {
  creative_name: string;
  creative_slot: string;
  promotion_id: string;
  promotion_name?: string;
  link_url?: string;
  link_page_type?: AnalyticsPageType;
  cta_click?: string;
  product?: any;
  fireEventFromPdp?: boolean;
  setPromotion?: boolean;
};
export const usePromotionSelect = () => {
  const { currency } = useSiteConfig();
  const { pageviewEventHasFired } = usePageEventsContext();
  const { preferences } = usePreferences();

  const promotionSelectEvent = ({
    creative_name,
    creative_slot,
    promotion_id,
    promotion_name,
    link_url,
    link_page_type,
    cta_click,
    product,
    fireEventFromPdp,
    setPromotion,
  }: Ga4Data) => {
    if (!pageviewEventHasFired) return;

    let item_name_ep: string | undefined;
    let item_id_ep: string | undefined;

    if (fireEventFromPdp) {
      const lastGa4Item = preferences['last-ga4-item'];
      if (lastGa4Item) {
        item_name_ep = lastGa4Item.name;
        item_id_ep = `${lastGa4Item.id}_${lastGa4Item.swatch}`;
      }
    }

    const creativeName = creative_name || undefined;
    const promotionId = promotion_id || undefined;
    const promotionName = promotion_name || undefined;

    let analyticsItem;

    if (product) {
      setGa4ItemInMap(product.id, {
        cname: creativeName,
        cslot: creative_slot,
        pid: promotionId,
        pname: promotionName,
      });
      analyticsItem = transformListProductToAnalyticsItem({
        product,
        currency: currency.code,
        quantity: 1,
        categories: {
          item_category: product.primaryCategoryId,
        },
        creativeName,
        creativeSlot: creative_slot,
        promotionId,
        promotionName,
      });
    } else if (setPromotion) {
      const promotion = {
        creativeSlot: creative_slot,
        creativeName,
        promotionId,
        promotionName,
      };
      window.sessionStorage.setItem(
        'last-clicked-promotion',
        JSON.stringify(promotion)
      );
    }

    event(AnalyticsEvents.GA4EC_PromotionSelect, {
      event_name: AnalyticsEvents.SELECT_PROMOTION,
      ecommerce: {
        creative_name: creativeName,
        creative_slot,
        promotion_id: promotionId,
        promotion_name: promotionName,
        item_id_ep,
        item_name_ep,
        link_url,
        link_page_type,
        cta_click: cta_click ? cta_click.toLowerCase() : undefined,
        items: analyticsItem ? [analyticsItem] : [],
      },
    });
  };

  return { promotionSelectEvent };
};
