import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputText } from '@global-ecom/nitro-uds/elements';
import { UseMutationState } from 'urql';

import { useTranslate } from 'hooks/useTranslations';
import { useFormField } from 'ui/forms/custom/hooks/useFormField';
import { CustomInput } from 'ui/forms/custom/types';
import { formatExpirationDate } from 'utils/creditCardFormatter';

import { buildName } from '../helpers';

const CustomFormExpirationDate = ({
  field,
  required = false,
  prefix,
  formResult,
}: {
  field: CustomInput;
  required?: boolean;
  prefix?: string;
  formResult: UseMutationState | undefined;
}) => {
  const prevExpiryDate = useRef<string>('');
  const { register, watch, setValue } = useFormContext();
  const t = useTranslate();
  const { resolveInputError, getErrorMessageOrDefault } = useFormField(field);

  const expirationDate = watch('expirationDate');

  useEffect(() => {
    const dateTrimmed = `${expirationDate}`
      .replace(/\D+/g, '')
      .replace('/', '');

    if (
      !dateTrimmed ||
      prevExpiryDate.current === expirationDate ||
      prevExpiryDate.current.length > expirationDate.length
    ) {
      prevExpiryDate.current = expirationDate;
      return;
    }

    const result = formatExpirationDate(dateTrimmed);

    setValue('expirationDate', result);

    prevExpiryDate.current = result;
  }, [expirationDate, setValue]);

  useEffect(() => {
    if (formResult?.error) {
      setValue(buildName('expirationDate', prefix), '');
    }
  }, [formResult?.error, prefix, setValue]);

  return (
    <InputText
      id="form-expiration-date"
      key={field.name}
      name={field.name}
      label={field.label}
      dataTestId={field.dataTestId}
      placeholder={field.placeholder || ''}
      errorText={resolveInputError()}
      required={required}
      autoComplete="cc-exp"
      autoCorrect="off"
      spellCheck={false}
      inputMode="numeric"
      maxLength={5}
      type="tel"
      ref={register({
        required:
          required && getErrorMessageOrDefault(field.name, 'requiredField'),
        minLength: {
          value: 5,
          message: getErrorMessageOrDefault(field.name, 'validDate'),
        },
        pattern: {
          message: t('validDate'),
          value: /^((0[1-9])|(1[0-2]))\/(\d{2})$/,
        },
        validate: value => {
          const month = Number.parseInt(value.split('/')[0]);
          const year = Number.parseInt(value.split('/')[1]);

          if (isNaN(month) || isNaN(year)) return t('validDate');

          const expiryTimeStamp = new Date(2000 + year, month, 0).getTime();

          return new Date().getTime() > expiryTimeStamp
            ? t('validDate')
            : undefined;
        },
      })}
      crossOrigin={undefined}
    />
  );
};
export default React.memo(CustomFormExpirationDate);
