import { IncomingMessage } from 'http';

import { crawlerCheck } from './crawlerCheck';

type isCrawlerCheckProps = {
  namespace: '' | 'pr-XXX' | 'pre';
  host: string | undefined;
  req: IncomingMessage;
};

export function isCrawlerCheck({ namespace, host, req }: isCrawlerCheckProps) {
  const isCrawler =
    namespace?.includes('pr-') || host?.startsWith('localhost')
      ? crawlerCheck(req?.headers?.['user-agent'])
      : req?.headers?.['x-user-agent'] === 'bot' ||
        req?.headers?.['user-agent']?.includes('botify');

  return isCrawler;
}
