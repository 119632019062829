import {
  AnalyticsProduct,
  AnalyticsProductImage,
  ItemProductInterface,
  ProductToAnalyticsProductType,
  ProductToTransform,
  VariantAvailability,
} from 'types/analyticTypes';
import { ActiveCartItemProduct } from 'types/cart';
import { getMinimalNumber } from 'utils/getMinimalNumber';
import { getProductPrice } from 'utils/getProductPrice';
import { beginsWithNumber, isNumber } from 'utils/isNumber';
import { getSizesFromSizeGroups } from 'utils/products';
import { Maybe, ProductSearchHit, Size, Variant } from '__generated__/graphql';

import {
  getAdditionalAnalyticsDimensions,
  getItemAvailability,
} from './analytics-utils';

const getVariantAvailability = (
  sizes?: Size[]
): VariantAvailability | undefined => {
  if (!sizes) return;

  const amountOrderable = sizes?.filter(size => size.orderable === true);

  if (!amountOrderable.length) return 'unavailable';
  if (amountOrderable.length === sizes.length) return 'available';
  if (amountOrderable.length < sizes.length) return 'partially available';
};

export const transformListProductToAnalyticsProduct = (
  product: ProductSearchHit,
  i: number,
  list?: string | null,
  categoryId?: string | null
): AnalyticsProduct | null => {
  if (!product.masterProduct) return null;
  const swatch = product.color;

  const selectedVariation = product.variantProduct as Variant;

  return {
    id: `${product.masterId}_${swatch}`,
    variant: product.id,
    ean: selectedVariation?.ean ?? '',
    name: product.masterProduct.name,
    price: getProductPrice(selectedVariation),
    brand: product.masterProduct.brand ?? 'PUMA',
    position: isNumber(i) ? i + 1 : undefined,
    list: list ?? '',
    category: categoryId ?? '',
  };
};

export const getAnalyticsImage = (image: string, defaultImage: string) => {
  // TO DO: Define approach in GA4 when have image comes from fluidconfigure.
  // For now we use the original product image
  const isPersonalizedImage = !!image.includes('fluidconfigure');
  const analyticsImage = isPersonalizedImage
    ? transformImageUrlToImageInteractionAnalyticsEvent(defaultImage)
    : transformImageUrlToImageInteractionAnalyticsEvent(image);
  return analyticsImage;
};

export const transformImageUrlToImageInteractionAnalyticsEvent = (
  imageURL: string
): AnalyticsProductImage => {
  const [styleID, colorID, imageView, fndImageRegion, imageRegion] = imageURL
    .split('/global/')[1]
    ?.split('/');

  // Here we have to account for two types of URL

  // normal: images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_2000,h_2000/global/374915/01/mod03/fnd/PNA/fmt/png
  // fnd: images.puma.com/image/upload/f_auto,q_auto,b_rgb:fafafa,w_2000,h_2000/global/374915/01/fnd/PNA/fmt/png

  // The `fnd` type is one part shorter than the normal URLs so we have to
  // account for the shifting in position of some destructured variables

  const isFndView = imageView === 'fnd';

  return {
    imageURL,
    imageType: isFndView ? 'fnd' : imageView.slice(0, -2),
    imageNumber: isFndView ? 'unavailable' : imageView.slice(-2),
    imageRegion: isFndView ? fndImageRegion : imageRegion,
    productID: `${styleID}_${colorID}`,
    styleID,
    colorID,
  };
};

export const transformProductToAnalyticsProduct = ({
  categoryId,
  desiredSize,
  includeExtraAnalyticsDimensions = false,
  product,
  variant,
}: ProductToAnalyticsProductType): AnalyticsProduct => {
  const variantPrice = variant?.productPrice?.price;
  const variantSalePrice = variant?.productPrice?.salePrice;
  const sizes = getSizesFromSizeGroups(variant.sizeGroups);
  const size = sizes.find(s => s.id === desiredSize);

  const additionalDimensions = includeExtraAnalyticsDimensions
    ? getAdditionalAnalyticsDimensions({
        sizeName: desiredSize ? size?.label : '',
        displayOutOfStock: product?.displayOutOfStock,
        assortmentAvailability: getVariantAvailability(sizes),
        isProductMarkedDown: !!(
          variantPrice &&
          variantSalePrice &&
          variantSalePrice < variantPrice
        ),
        orderable: size ? size.orderable : variant.orderable,
        ean: variant.ean,
      })
    : {};

  return {
    id: variant.id,
    variant: size?.productId ?? variant.variantId ?? '',
    ean: variant.ean ?? '',
    name: variant.name || product?.name || '',
    price: getProductPrice(variant),
    full_price: variantPrice,
    brand: product?.brand || 'PUMA',
    category: categoryId || product?.primaryCategoryId || '',
    ...additionalDimensions,
  };
};
export const transformVariantProductToAnalyticsProduct = (
  variantProduct: ActiveCartItemProduct,
  includeExtraAnalyticsDimensions = false
): AnalyticsProduct => ({
  id: variantProduct.styleNumber,
  variant: variantProduct.id,
  ean: variantProduct.ean ?? '',
  name: variantProduct?.name,
  price: getProductPrice(variantProduct),
  brand: variantProduct?.brand ?? 'PUMA',
  category: variantProduct?.primaryCategoryId ?? '',
  full_price: variantProduct.price,
  ...(includeExtraAnalyticsDimensions && {
    ...getAdditionalAnalyticsDimensions({
      sizeName: variantProduct.size.label,
      displayOutOfStock: variantProduct.displayOutOfStock,
      assortmentAvailability: variantProduct.assortmentAvailability,
      isProductMarkedDown: !!(
        variantProduct.salePrice &&
        variantProduct.salePrice < variantProduct.price
      ),
      orderable: variantProduct.orderable,
      ean: variantProduct.ean,
    }),
  }),
});

export const addPrefixToAnalyticProp = (
  prop: Maybe<string> | undefined,
  prefix = 'a'
) => {
  if (prop) return beginsWithNumber(prop) ? `${prefix}${prop}` : prop;
};
const isEmpty = (value: any) => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (value.hasOwnProperty('length') && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};

export const transformListProductToAnalyticsItem = ({
  product,
  currency,
  quantity,
  categories,
  itemListId,
  itemListName,
  size,
  adjustments,
  index,
  creativeName,
  creativeSlot,
  promotionId,
  promotionName,
  discovery,
  assortmentAvailabilityItem,
  itemId,
  itemName,
}: ProductToTransform) => {
  const item_id =
    itemId ??
    ((product?.masterId &&
      (product.colorValue
        ? product.masterId + '_' + product.colorValue
        : product.masterId)) ||
      product?.id);

  const productPrice = product?.productPrice
    ? product.productPrice
    : product?.variantProduct?.productPrice;
  const price = productPrice
    ? getMinimalNumber(
        productPrice.price,
        productPrice.promotionPrice,
        productPrice.salePrice
      )
    : undefined;

  const coupon = adjustments?.map(c => c.couponCode).join('|') || undefined;

  const discount = productPrice
    ? coupon && productPrice?.salePrice && productPrice?.promotionPrice
      ? productPrice.salePrice - productPrice.promotionPrice
      : 0
    : undefined;
  const saleDiscount = productPrice
    ? productPrice.price && productPrice.salePrice
      ? productPrice.price - productPrice.salePrice
      : 0
    : undefined;

  const itemAvailability = getItemAvailability(product?.displayOutOfStock);
  const availability = ['InStock', 'PreOrder'].includes(itemAvailability)
    ? 'true'
    : 'false';

  const formattedSize: Maybe<string> | undefined =
    addPrefixToAnalyticProp(size);

  const sizes = product?.sizeGroups
    ? getSizesFromSizeGroups(product.sizeGroups)
    : [];
  const assortmentAvailability = sizes?.length
    ? getVariantAvailability(sizes)
    : undefined;

  const markedDown =
    productPrice && productPrice.price && productPrice.price
      ? `${!!(productPrice.salePrice < productPrice.price)}`
      : undefined;

  const discounted =
    productPrice && productPrice.promotionPrice && productPrice.price
      ? `${!!(productPrice.promotionPrice < productPrice.price)}`
      : undefined;

  const orderable =
    product?.orderable === true || product?.variantProduct?.orderable === true
      ? 'true'
      : product?.orderable === false ||
        product?.variantProduct?.orderable === false
      ? 'false'
      : undefined;

  //To check
  const variantId =
    product?.variantId ||
    product?.productId ||
    product?.variantProduct?.variantId ||
    undefined;
  const styleNumber = product?.styleNumber || undefined;

  const colorCodeFormatted =
    product && product.colorValue
      ? `a${
          product.colorValue ||
          (product.colors && product.colors[0].value) ||
          product.color
        }`
      : undefined;

  const full_price = productPrice?.price
    ? String(productPrice.price)
    : undefined;

  const sport = !isEmpty(product?.sportName) ? product.sportName : undefined;
  const gender = !isEmpty(product?.gender) ? product.gender : undefined;
  const ageGroup = !isEmpty(product?.ageGroup) ? product.ageGroup : undefined;
  const lineName = !isEmpty(product?.lineName) ? product.lineName : undefined;
  const collection = !isEmpty(product?.collectionMark)
    ? product.collectionMark
    : undefined;
  const division = !isEmpty(product?.division) ? product.division : undefined;
  const businessUnit = !isEmpty(product?.businessUnit)
    ? product.businessUnit
    : undefined;
  const businessSubSegment = !isEmpty(product?.businessSubSegment)
    ? product.businessSubSegment
    : undefined;
  const franchise = !isEmpty(product?.franchise)
    ? product.franchise
    : undefined;

  const item: ItemProductInterface = {
    item_id,
    item_name: itemName || product?.name || product?.productName,
    affiliation: 'PUMA',
    coupon,
    currency,
    discount: discount ? Math.round(discount * 100) / 100 : undefined,
    index,
    item_brand: 'PUMA',
    ...categories,
    item_list_id: itemListId,
    item_list_name: itemListName,
    item_variant: variantId,
    price,
    quantity,
    creative_name: creativeName,
    creative_slot: creativeSlot,
    promotion_id: promotionId,
    promotion_name: promotionName,
    full_price,
    sale_discount:
      saleDiscount !== undefined
        ? Number((saleDiscount * quantity).toFixed(2))
        : undefined,
    size: formattedSize,
    availability,
    assortment_availability:
      assortmentAvailability || assortmentAvailabilityItem,
    discounted,
    orderable,
    item_ean: product?.ean || product?.variantProduct?.ean,
    color: product?.colorName || product?.variantProduct?.colorName,
    style_number: styleNumber,
    marked_down: markedDown,
    discovery,
    sport,
    gender,
    age_group: ageGroup,
    line_name: lineName,
    collection,
    division,
    color_code: colorCodeFormatted,
    business_unit: businessUnit,
    business_subsegment: businessSubSegment,
    franchise,
    department: undefined,
    class: undefined,
  };

  return item;
};
