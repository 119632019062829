import { useIsomorphicLayoutEffect } from '@global-ecom/nitro-uds/hooks';

import { useWindowSize } from 'hooks/useWindowSize';
import { useIsServer } from 'hooks/useIsServer';

let scrollLockCounter = 0;

export function useBodyScrollLock(isActive: boolean) {
  const { width, height } = useWindowSize();
  const isServer = useIsServer();
  const scrolbarWidth = isServer
    ? 0
    : window.innerWidth - document.body.offsetWidth;

  useIsomorphicLayoutEffect(() => {
    if (!isActive) return;

    const scrollY = window.scrollY;
    if (scrollLockCounter++ === 0) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY}px`;
      document.body.style.width = `calc(100vw - ${scrolbarWidth}px)`;
    }

    return () => {
      if (--scrollLockCounter === 0) {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = `100%`;
        window.scrollTo(0, scrollY);
      }
    };
  }, [isActive, width, height]);
}
