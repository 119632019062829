import { Maybe } from 'utils/types';
import { ImageAssetDocument } from 'groq/documents/ImageAssetDocument';
import { LocaleString } from 'groq/shared/LocaleString';
import { BackgroundOverlay } from '__generated__/graphql';

// Schema: https://github.com/Global-eCom/sanity-studio-cms/blob/main/studio/schemas/objects/figure.ts

export type SanityImageObjectType = {
  alt?: Maybe<string>;
  // FIXME: CHANGE TYPES
  asset?: any;
  overlay?: Maybe<BackgroundOverlay>;
};

const _SanityImageObject = (
  field,
  platform: 'all' | 'desktop' | 'mobile' = 'all'
) => `
  ${field} {
    ${LocaleString('alt')},
    ${ImageAssetDocument('asset->', platform)},
    overlay
  }
`;

export const SanityImageObject = (
  field,
  platform: 'all' | 'desktop' | 'mobile' = 'all',
  mobileField?: string
) => {
  if (platform === 'desktop' && mobileField) {
    return `
      ${field} {
        defined(^.${mobileField}.asset) => ${_SanityImageObject('', 'desktop')},
        !defined(^.${mobileField}.asset) => ${_SanityImageObject('', 'all')}
      }
    `;
  } else {
    return _SanityImageObject(field, platform);
  }
};
