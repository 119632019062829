import { gql } from 'urql';

import { CART_PRODUCT_ITEM_FRAGMENT } from 'gql/fragments/cart';
import {
  ORDER_CONFIRMATION_FRAGMENT,
  PAYMENT_INSTRUMENT_FRAGMENT,
} from 'gql/fragments/baseOrder';

export const ORDER_PREVIEW_FRAGMENT = gql`
  fragment orderPreviewFields on StandardOrder {
    orderNo
    createdAt
    currency
    orderStatus {
      code
      label
    }
    estimatedTotal
    isReturnable
    shipment {
      address {
        id
        firstName
        lastName
        postalCode
        salutation
      }
    }
    refundInformation
    products {
      ... on ProductCartItem {
        __typename
        id
        quantity
        inCartPrice
        purchasedPrice {
          price
          promotionPrice
        }
        product {
          id
          name
          header
          subHeader
          colorValue
          colorName
          masterId
          images {
            href
            alt
          }
          styleNumber
          offline
          orderable
        }
        personalizationImageURL
      }
      ... on GiftCardCartItem {
        id
        imageUrl
        amount
        recipientEmail
        recipientName
        senderName
        __typename
      }
    }
    customer {
      email
      id
    }
  }
`;

export const STANDARD_ORDER_DETAILS_FRAGMENT = gql`
  fragment standardOrderDetailsFields on StandardOrder {
    __typename
    orderNo
    orderToken
    status
    orderStatus {
      code
      label
    }
    refundInformation
    trackingNumber
    isReturnable
    statuses {
      status
      label
      order
    }
    taxTotal
    taxation
    createdAt
    currency
    oms {
      trackingLink
    }
    subTotal
    shippingCosts
    estimatedTotal
    gmoRedirectUrl
    gmoChallengeUrl
    gmoFlow
    gmoAccessID
    gmoAccessPass
    promoDiscountValue
    shippingSurcharge {
      amount
      label
      tooltip
    }
    orderAdjustments {
      promotionId
      itemText
      price
      couponCode
    }
    shippingAdjustments {
      promotionId
      itemText
      price
      couponCode
    }
    couponItems {
      code
      couponItemId
      statusCode
      valid
    }
    products {
      ...productCartItemFields
    }
    ...orderPaymentInstrumentFields
    ...orderConfirmationFields
  }
  ${CART_PRODUCT_ITEM_FRAGMENT}
  ${ORDER_CONFIRMATION_FRAGMENT}
  ${PAYMENT_INSTRUMENT_FRAGMENT}
`;
