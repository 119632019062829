import { useState, RefObject } from 'react';
import { useIsomorphicLayoutEffect } from '@global-ecom/nitro-uds/hooks';

import { useIsServer } from './useIsServer';

type Data<DataType> = DataType & {
  hidden: boolean;
};
interface useAvailableSpacePayload<DataType> {
  calculated: boolean;
  items: Data<DataType>[];
}
const useAvailableSpace = <ParentElementType extends HTMLElement, DataType>(
  parentRef: RefObject<ParentElementType>,
  data: DataType[]
): useAvailableSpacePayload<DataType> => {
  const isServer = useIsServer();
  const [state, setState] = useState<useAvailableSpacePayload<DataType>>(
    () => ({
      calculated: false,
      items: data.map(
        dataObject =>
          ({
            ...(dataObject as any),
            hidden: false,
          } as Data<DataType>)
      ),
    })
  );

  useIsomorphicLayoutEffect(() => {
    if (isServer || !parentRef.current) return;

    const ro = new window.ResizeObserver(entries => {
      let { width } = entries[0].contentRect;
      let visible = 0;
      if (parentRef?.current?.children) {
        for (const child of Array.from(
          parentRef.current.children
        ) as HTMLElement[]) {
          if (width > child.offsetWidth) visible++;
          const style = window.getComputedStyle(child);
          width =
            width -
            (child.offsetWidth +
              parseFloat(style.marginLeft!) +
              parseFloat(style.marginRight!));
        }
        const items = data.map(
          (dataObject, i) =>
            ({
              ...(dataObject as any),
              hidden: i >= visible,
            } as Data<DataType>)
        );
        setState({ calculated: true, items });
      }
    });

    const element = parentRef.current;
    ro.observe(element);

    return () => ro.unobserve(element);
  }, [JSON.stringify(data), parentRef.current]);

  return state;
};

export default useAvailableSpace;
