import { FieldPropsConfig } from 'ui/forms/custom/types';

import { commonFieldsProps } from '../common/fields';

const ALLOWED_CHARACTERS =
  '^([\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uffef\u4e00-\u9faf\u0041-\u005A\u0061-\u007A])+$';

const ALLOWED_CITY_STREET =
  '^[\u0000-~\u0080-ž’”\u3000-\u303F\u3040-\u309F\u30A0-\u30FF\uFF00-\uFFEF\u4E00-\u9FAF\u2605-\u2606\u2190-\u2195\u203B]+$';

const HIRAGANA_ALL_CHARACTERS = 'ぁ-ん';
const KANJI_ALL_CHARACTERS = '一-龯';
const KATAKANA_FULL_WIDTH_CHARACTERS = 'ァ-ン';
const KATAKANA_HALF_WIDTH_CHARACTERS = 'ｧ-ﾝﾞﾟ';
const LATIN_FULL_WIDTH_CHARACTERS = 'Ａ-ｚ';
const NUMBERS_FULL_WIDTH_CHARACTERS = '０-９';
const NUMBERS_HALF_WIDTH_CHARACTERS = '0-9';
const ASCII_SYMBOLS_FULL_WIDTH_CHARACTERS =
  '！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝～';
const ASCII_SYMBOLS_HALF_WIDTH_CHARACTERS =
  '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

// put some of the not allowed characters in a separate variable
// so that when you enter these characters, display a separate message
// https://pumaglobal.atlassian.net/browse/CBC-7137?focusedCommentId=972373
export const HOLDER_NAME_NOT_ALLOWED_CHARACTERS =
  HIRAGANA_ALL_CHARACTERS +
  KANJI_ALL_CHARACTERS +
  KATAKANA_FULL_WIDTH_CHARACTERS +
  KATAKANA_HALF_WIDTH_CHARACTERS +
  LATIN_FULL_WIDTH_CHARACTERS +
  NUMBERS_FULL_WIDTH_CHARACTERS +
  NUMBERS_HALF_WIDTH_CHARACTERS +
  ASCII_SYMBOLS_FULL_WIDTH_CHARACTERS +
  ASCII_SYMBOLS_HALF_WIDTH_CHARACTERS;

const HOLDER_NAME_ALLOWED_CHARACTERS = 'a-zA-Z\\s';

const HOLDER_NAME_CHARACTERS_EXTENDED =
  '^[' +
  HOLDER_NAME_ALLOWED_CHARACTERS +
  HOLDER_NAME_NOT_ALLOWED_CHARACTERS +
  ']+$';

const STATE_CODE =
  '^[' +
  HIRAGANA_ALL_CHARACTERS +
  KANJI_ALL_CHARACTERS +
  KATAKANA_FULL_WIDTH_CHARACTERS +
  KATAKANA_HALF_WIDTH_CHARACTERS +
  ']+$';

export const JPFieldProps: Record<string, FieldPropsConfig> = {
  ...commonFieldsProps,
  firstName: {
    fieldType: 'input',
    pattern: ALLOWED_CHARACTERS,
    maxLength: 50,
  },
  lastName: {
    fieldType: 'input',
    pattern: ALLOWED_CHARACTERS,
    maxLength: 50,
  },
  firstNamePronunciation: {
    fieldType: 'input',
    pattern: ALLOWED_CHARACTERS,
    maxLength: 50,
  },
  lastNamePronunciation: {
    fieldType: 'input',
    pattern: ALLOWED_CHARACTERS,
    maxLength: 50,
  },
  phone: {
    fieldType: 'input',
    pattern: '^[\\+]?[\\d]{7,15}$',
    maxLength: 30,
  },
  phoneNumber: {
    fieldType: 'input',
    pattern:
      '^[\\+(/. 0-9 \\)-]+[ ]*((( |x|ext|ext.|extension|Ext|Ext.|Extension|#){1}[ ]?([0-9]){1,7}){0,1})$',
    maxLength: 30,
  },
  password: {
    fieldType: 'password',
    pattern: '^(?=.*[a-zA-Z])(?=.*[0-9]).{8,255}$',
    validations: ['safeInput'],
    minLength: 8,
    maxLength: 255,
  },
  email: {
    fieldType: 'input',
    pattern: '^[\\w-\\.\\%\\+]+@(([\\w-]{1,})+\\.)+[\\w-]{2,6}$',
    maxLength: 50,
  },
  postalCode: {
    fieldType: 'yubinbango-address-look-up',
    pattern: '^([0-9]{3}-[0-9]{4})$|^([0-9]{7})$',
    maxLength: 8,
  },
  stateCode: {
    fieldType: 'input',
    pattern: STATE_CODE,
    maxLength: 50,
  },
  city: {
    fieldType: 'input',
    pattern: ALLOWED_CITY_STREET,
    maxLength: 50,
  },
  address1: {
    fieldType: 'input',
    pattern: ALLOWED_CITY_STREET,
    maxLength: 50,
  },
  backCancel: {
    fieldType: 'link',
    href: '/account',
    className: 'w-full',
    size: 'lg',
    variant: 'secondary',
  },
  holderName: {
    fieldType: 'input',
    maxLength: 40,
    pattern: HOLDER_NAME_CHARACTERS_EXTENDED, // pass not allowed characters including to validate them in 'validations'
    validations: ['holderNameExcludeCharacters'], // render separate message for not allowed characters passed in 'pattern'
  },
  holderNameAccount: {
    fieldType: 'input',
    maxLength: 40,
    pattern: HOLDER_NAME_CHARACTERS_EXTENDED, // pass not allowed characters including to validate them in 'validations'
    validations: ['holderNameExcludeCharacters'], // render separate message for not allowed characters passed in 'pattern'
  },
  cardNumber: {
    fieldType: 'card-number',
  },
  expirationDate: {
    fieldType: 'expiration-date',
  },
  cvv: {
    fieldType: 'cvv',
  },
  gender: {
    fieldType: 'input-radio',
    values: ['male', 'female'],
  },
  cancel: {
    fieldType: 'link',
    variant: 'secondary',
    href: '/account',
    size: 'lg',
    className: 'flex-auto',
  },
  backToMyAccount: {
    fieldType: 'link',
    href: '/account',
    class: 'col-span-2',
  },
  currentPassword: {
    fieldType: 'password',
    maxLength: 255,
    minLength: 8,
  },
  newPassword: {
    fieldType: 'password',
    pattern: '^(?=.*[a-zA-Z])(?=.*[0-9]).{8,255}$',
    validations: ['password'],
    maxLength: 255,
    minLength: 8,
  },
  newPasswordConfirmation: {
    fieldType: 'password',
    maxLength: 255,
    minLength: 8,
  },
  orderEmail: {
    fieldType: 'input',
    pattern: '^[\\w-\\.\\%\\+]+@(([\\w-]{1,})+\\.)+[\\w-]{2,6}$',
    maxLength: 50,
  },
};
