import { MutableRefObject, useEffect, useRef, useState } from 'react';

import { debounce } from 'utils/debounce';
import { useIsServer } from 'hooks/useIsServer';

export const useResizeObserver = <T = any>(
  elementRef?: MutableRefObject<any>
): {
  width: number;
  height: number;
  ref: MutableRefObject<T>;
} => {
  const isServer = useIsServer();
  const isMounted = useRef(false);
  const newRef = useRef<any>(null);
  const ref = elementRef || newRef;
  const [state, setState] = useState({ width: 0, height: 0 });

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!ref.current || isServer) return;

    if (isMounted.current) {
      setState({
        width: Math.round(ref.current.offsetWidth),
        height: Math.round(ref.current.offsetHeight),
      });
    }

    const ro = new window.ResizeObserver(
      debounce(entries => {
        requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) return;

          const { width, height } = entries[0].contentRect;

          if (isMounted.current)
            setState({ width: Math.round(width), height: Math.round(height) });
        });
      }, 100)
    );

    const element = ref.current;
    ro.observe(element);
    return () => ro.unobserve(element);
  }, [ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return { ref, ...state };
};
