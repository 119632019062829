// source -> https://github.com/uidotdev/usehooks/blob/dfa6623fcc2dcad3b466def4e0495b3f38af962b/index.js#L1344
// docs -> https://usehooks.com/usewindowsize

import { useState } from 'react';
import { useIsomorphicLayoutEffect } from '@global-ecom/nitro-uds/hooks';

type WindowSize = {
  width: number | null;
  height: number | null;
};

export function useWindowSize() {
  const [size, setSize] = useState<WindowSize>({
    width: null,
    height: null,
  });

  useIsomorphicLayoutEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return size;
}

// usage: `const { width, height } = useWindowSize();`
