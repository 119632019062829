import { useRef } from 'react';
import { useIsomorphicLayoutEffect } from '@global-ecom/nitro-uds/hooks';

import { getFocusTargets, getFirstFocusTarget, isRelatedNode } from './focus';
import { addFocusVisible, isFocusVisible } from './useFocusVisible';

type UseFocusTrapProps<T> = {
  ref: React.RefObject<T | null>;
  isActive: boolean;
  isFocused?: boolean;
};

/** Activates a focus trap on the element targeted with the `React.RefObject`. This will trap focus within the container and prevent it from leaving it until it's closed. */
export function useFocusTrap<T extends HTMLElement>(
  props: UseFocusTrapProps<T>
) {
  const { ref, isActive, isFocused } = props;

  const restoreFocusTo: React.MutableRefObject<HTMLElement | null> =
    useRef(null);

  useIsomorphicLayoutEffect(() => {
    const { current: container } = ref;
    if (!container) {
      return;
    } else {
      container.setAttribute('tabindex', '-1');
    }

    if (!isActive) {
      container.setAttribute('aria-hidden', 'true');
    } else {
      container.setAttribute('aria-hidden', 'false');
      if (!container.hasAttribute('aria-modal')) {
        container.setAttribute('aria-modal', 'false');
      }
    }

    return () => {
      // Mark container as hidden, to indicate to screen readers that it's inactive
      container.setAttribute('aria-hidden', 'true');
    };
  }, [ref.current!, isActive]);

  useIsomorphicLayoutEffect(() => {
    if (!isActive) return;

    restoreFocusTo.current = document.activeElement as HTMLElement;

    // Focus the first focusable element in the container
    if (ref.current) {
      const focusTarget = getFirstFocusTarget(ref.current);
      if (focusTarget && focusTarget !== document.activeElement) {
        focusTarget.focus();
      }
    }

    const onBlur = (event: FocusEvent) => {
      const { current: container } = ref;
      if (!container) return;

      // If focus has left the container then move it back to the first focus target
      if (
        isRelatedNode(container, event.target as HTMLElement) &&
        (!event.relatedTarget ||
          !isRelatedNode(container, event.relatedTarget as HTMLElement))
      ) {
        const focusTarget = getFirstFocusTarget(container);
        if (focusTarget) focusTarget.focus();
      }
    };

    const onKeydown = (event: KeyboardEvent) => {
      const { current: container } = ref;
      if (!container) return;

      if (!event.defaultPrevented && container && event.code === 'Tab') {
        const focusTargets = getFocusTargets(container);
        const focusedIndex = focusTargets.indexOf(
          document.activeElement as HTMLElement
        );

        // Cycle focus when the focus is about to leave the container
        if (!focusTargets.length) {
          return;
        } else if (event.shiftKey && focusedIndex === 0) {
          event.preventDefault();
          focusTargets[focusTargets.length - 1].focus();
        } else if (
          (!event.shiftKey && focusedIndex === focusTargets.length - 1) ||
          (!event.shiftKey && !isFocusVisible() && focusedIndex === 0)
        ) {
          event.preventDefault();
          addFocusVisible();
          focusTargets[0].focus();
        }
      }
    };
    if (!isFocused) {
      document.body.addEventListener('focusout', onBlur);
    }
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.body.removeEventListener('focusout', onBlur);
      document.removeEventListener('keydown', onKeydown);

      // Restore focus to the previously focused element
      if (restoreFocusTo.current) restoreFocusTo.current.focus();
    };
  }, [ref, isActive]);
}
