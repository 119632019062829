import { PaymentMethod } from 'utils/constants';
import { Maybe } from '__generated__/graphql';

import { useSiteConfig } from './useSiteConfig';

type Flag =
  | typeof PaymentMethod.PAYPAL
  | typeof PaymentMethod.AFTERPAY
  | typeof PaymentMethod.GIFT_CARD
  | typeof PaymentMethod.STORE_CREDIT
  | typeof PaymentMethod.APPLE_PAY
  | typeof PaymentMethod.GOOGLE_PAY
  | typeof PaymentMethod.AMAZON_PAY
  | typeof PaymentMethod.PAY_PAY
  | 'GOOGLE_ADDRESS_LOOKUP'
  | 'GOOGLE_REVERSE_POSTAL_CODE_LOOKUP'
  | 'RATINGS_AND_REVIEWS'
  | 'PINCODE_SERVICEABILITY'
  | 'MY_CUSTOMIZER'
  | 'NESTED_ORDERS'
  | 'ADDRESS_RESUME_IN_CHECKOUT'
  | 'SIMPLIFIED_ADDRESS_RESUME_IN_CHECKOUT'
  | 'USE_SAVED_CARDS'
  | 'MY_ACCOUNT_ORDER_DETAILS_CONTACT_INFO'
  | 'MY_ACCOUNT_ORDER_DETAILS_BILLING_INFO'
  | 'ADDITIONAL_PRICE_TEXT_FOR_SALE'
  | 'RETURN_ORDER'
  | 'MY_ACCOUNT_EDIT_PASSWORD_SHOW_ADDITIONAL_CANCEL_BUTTON'
  | 'MY_ACCOUNT_EDIT_PASSWORD_USE_LABEL_INSTEAD_OF_PLACEHOLDER'
  | 'ACCOUNT_DASHBOARD_SHOW_MEMBERSHIP_SECTION'
  | 'CREATE_ACCOUNT_IN_CHECKOUT'
  | 'SHOW_USER_REGISTRATION_ON_CHECKOUT_CONFIRMATION'
  | 'SAVE_ADDRESS_CHECKBOX_ENABLED'
  | 'CART_SUMMARY_PROMO_CODE_EXPANDED_ON_MOBILE'
  | 'MY_ACCOUNT_LEAVE_RESET_PASSWORD_CONFIRM_POPUP_OPEN'
  | 'DESIRED_DELIVERY_DATE'
  | 'OTP_LOGIN'
  | 'REGISTER_OTP_VERIFICATION_ENABLED'
  | 'PHONE_IN_PROFILE'
  | 'AUTO_SHOW_NEWSLETTER_POPUP'
  | 'SHOW_PRODUCT_KEYWORDS'
  | 'MY_ACCOUNT_HIDE_NOTIFICATION_SETTINGS_IN_DASHBOARD'
  | 'SC_SHOW_CART_ABANDONMENT_MODAL'
  | 'MINICART_SEPARATE_VIEW_AND_CHECKOUT_BUTTONS'
  | 'SHOW_ADDITIONAL_FIELDS_IN_ACCOUNT_EDIT'
  | 'PRICES_EXTENDED'
  | 'SHOW_ONLY_ORDERABLE_SIZES'
  | 'REVIEW_ORDER_ON_CHECKOUT'
  | 'CHECKOUT_ACCORDION_COLLAPSED_BY_DEFAULT'
  | 'MY_ACCOUNT_DASHBOARD_ADD_ADDRESS_BUTTON'
  | 'MY_ACCOUNT_ORDER_HISTORY_ORDER_TITLE_TRANSLATION'
  | 'SHOW_DYNAMIC_TAX_MESSAGE'
  | 'PRODUCT_FILTER_COLLAPSIBLE'
  | 'CAN_REMOVE_WISHLIST_ITEMS_FROM_PDP'
  | 'CAN_SHOW_PHONEICON_IN_CHECKOUT'
  | 'SHOW_SIMPLIFIED_PDP_FOR_GOOGLE_SHOPPING'
  | 'HIDE_YOUR_SAVINGS'
  | 'SHOW_PRODUCT_STORY_KEYWORD_TITLE'
  | 'SHOW_NEWSLETTER_SIGNUP_ON_ORDER_CONFIRMATION'
  | 'SHOW_SHIPPING_DISCOUNT_IN_CHECKOUT'
  | 'SHOW_DISCOUNT_AMOUNT_AND_TEXT_ENABLED'
  | 'SHOW_METRIC_AS_DEFAULT'
  | 'PAYMENT_PROMOTION'
  | 'USE_STOCK_MESSAGE_FROM_RESPONSE'
  | 'PDP_ENABLE_STOCK_MESSAGE'
  | 'PDP_SHOW_DETAILED_STOCK_MESSAGES'
  | 'PDP_DISABLE_RETURNS'
  | 'SHOW_SALES_BADGE_ON_PRODUCT_IMAGE'
  | 'MY_ACCOUNT_REDIRECT_AFTER_PROFILE_UPDATE'
  | 'CHECKOUT_HIDE_SHIPPING_AND_RETURNS_MESSAGE'
  | 'MY_ACCOUNT_HIDE_NEWSLETTER_SETTINGS_IN_DASHBOARD'
  | 'SHOW_COOKIE_CONSENT_LAYER'
  | 'SHOW_ORDER_TRACKING_NUMBER_IN_ORDER_DETAILS'
  | 'SHOW_BREADCRUMBS_ON_ORDER_DETAILS'
  | 'PARCEL_LAB_TRACKING_ENABLED'
  | 'SHOW_PRICE_DIFFERENCE'
  | 'CHECKOUT_SHOW_SAVE_CARD_CHECKBOX'
  | 'USE_SFCC_DIVIDER_FOR_SEO_DESCRIPTION'
  | 'SHOW_ORDER_SUMMARY_AT_ORDER_HISTORY'
  | 'SHOW_REFUND_INFORMATION_AT_ORDER'
  | 'PERSONALIZATION_ENABLED'
  | 'PROMO_CODE_ACCORDION_EXPANDED_BY_DEFAULT'
  | 'HIDE_SOLD_OUT_COLORS_IN_STYLES_SELECTION'
  | 'HIDE_CALLOUT_MESSAGE'
  | 'HIDE_EAN_COLOR_CODE'
  | 'OPLUX_ENABLED'
  | 'SHOW_ALL_PRICES_AT_ONCE'
  | 'DISPLAY_PROMO_DISCOUNT_BREAKDOWN'
  | 'EDIT_PASS_RESULT_ERROR_DISABLED'
  | 'HIDE_TERMS_AND_CONDITIONS_ON_LOGIN'
  | 'DISPLAY_PROMO_APPLIED_PROMOTION'
  | 'SHOW_ONE_FINAL_PRICE_ON_PURCHASED_PRODUCT'
  | 'SET_AUTO_CAPITALIZE_OFF'
  | 'HIDE_MY_ACCOUNT_SUBTITLE'
  | 'SHOW_WISHLIST_ICON_BADGE_ON_DROPDOWN_MENU'
  | 'HIDE_PAYMENT_INFORMATION_ON_ACCOUNT_PAGE'
  | 'ALLOW_EDIT_INCORRECT_SAVED_ADDRESS_CHECKOUT'
  | 'STORE_CREDITS_ENABLED'
  | 'OUTFITS_ENABLED'
  | 'HIDE_BULLETS_LISTS_PDP'
  | 'SHOW_KLARNA_DISCLAIMER_MESSAGE'
  | 'SHOW_SEARCH_FORM_ON_ERROR'
  | 'SHOW_PRODUCT_RECOMMENDER_ON_ERROR'
  | 'AUTHORITY_TO_LEAVE_ENABLED'
  | 'SHOW_SEARCH_TIPS_ON_ERROR'
  | 'USE_ACCOUNT_NOTIFICATION_FORM_V2'
  | 'MY_ACCOUNT_HIDE_EMAIL_NOTIFICATIONS'
  | 'QUICK_ADDRESS_FINDER'
  | 'SHOW_PROMO_EXCLUSION_MSG_ABOVE_PRODUCT_DETAILS'
  | 'SHOW_PROMO_EXCLUSION_MSG_IN_BASE_SIZE'
  | 'USE_DEFAULT_JAPAN_CHART'
  | 'POPOVER_LOGIN_ENABLED'
  | 'POPOVER_LOGIN_ENABLED_JP'
  | 'REMOVE_RETURN_IFRAME'
  | 'KLARNA_ENABLED'
  | 'USER_QUERY_DYNAMIC_YIELD'
  | 'SET_DY_LANGUAGE_IN_ENGLISH'
  | 'SHOW_GIFT_WITH_PURCHASE_MODAL_IN_MOBILE'
  | 'GIFT_WITH_PURCHASE_NEW_DESIGN'
  | 'SEARCH_LOCAL_IMAGES_ON_PRODUCT_COMPARISON'
  | 'SEARCH_LOCAL_IMAGES_ON_DROP_CALENDAR'
  | 'STYLE_BAZAAR_VOICE_MODAL'
  | 'CART_QUERY_DYNAMIC_YIELD'
  | 'KLARNA_ENABLED'
  | 'USE_VARIANT_PROMOTION_EXCLUSION'
  | 'SHOW_PROMO_EXCLUSION_MSG_IN_GRAY'
  | 'SHOW_FREE_SHIPPING_PROGRESS_BAR'
  | 'SHOW_PINTEREST_FOOTER_ICON'
  | 'SHOW_PUMA_CARD'
  | 'RETURNS_PAGE_DISABLED'
  | 'HIDE_WEIGHT_IN_PRODUCT_MEASUREMENTS'
  | 'AMBASSADOR_CARDS_ENABLED'
  | 'ADD_ADDRESS_RESULT_ERROR_DISABLED'
  | 'TRIGGER_CART_TOTAL_EVENT'
  | 'ENABLE_ITEM_LINKS_IN_CART_SUMMARY'
  | 'USE_MASTER_IDS_IN_CART_RECOMMENDERS'
  | 'IS_GMO_ENABLED'
  | 'IS_SALESFLOOR_ENABLED';

type FlagContainer = {
  [key in Flag]: Maybe<boolean> | undefined;
};

export const useFeature = (flagName: Flag): boolean => {
  const { features, staticFeatures } = useSiteConfig();
  const flags: FlagContainer = {
    [PaymentMethod.PAYPAL]: features.payPalEnabled,
    [PaymentMethod.APPLE_PAY]: features.applePayEnabled,
    [PaymentMethod.GOOGLE_PAY]: features.googlePayEnabled,
    [PaymentMethod.AFTERPAY]: features.afterPayEnabled,
    [PaymentMethod.GIFT_CARD]: features.giftCardEnabled,
    [PaymentMethod.STORE_CREDIT]: features.storeCreditsEnabled,
    [PaymentMethod.AMAZON_PAY]: features.amazonPayEnabled,
    [PaymentMethod.PAY_PAY]: features.payPayEnabled,
    KLARNA_ENABLED: features.klarnaEnabled,
    GOOGLE_ADDRESS_LOOKUP: features.googleAddressLookupEnabled,
    GOOGLE_REVERSE_POSTAL_CODE_LOOKUP: features.googleReversePostalCodeLookup,
    RATINGS_AND_REVIEWS: features.ratingsAndReviewsEnabled,
    MY_CUSTOMIZER: features.myCustomizerEnabled,
    PINCODE_SERVICEABILITY: features.pincodeServiceabilityEnabled,
    REVIEW_ORDER_ON_CHECKOUT: staticFeatures.reviewOrderOnCheckout,
    NESTED_ORDERS: features.nestedOrdersEnabled,
    ADDRESS_RESUME_IN_CHECKOUT: features.addressResumeInCheckoutEnabled,
    SIMPLIFIED_ADDRESS_RESUME_IN_CHECKOUT:
      features.simplifiedAddressResumeInCheckoutEnabled,
    USE_SAVED_CARDS: features.useSavedCardsEnabled,
    MY_ACCOUNT_ORDER_DETAILS_CONTACT_INFO:
      features.myAccountOrderDetailsContactInfoEnabled,
    MY_ACCOUNT_ORDER_DETAILS_BILLING_INFO:
      features.myAccountOrderDetailsBillingInfoEnabled,
    ADDITIONAL_PRICE_TEXT_FOR_SALE: features.additionalPriceTextForSaleEnabled,
    RETURN_ORDER: features.returnOrderEnabled,
    MY_ACCOUNT_EDIT_PASSWORD_SHOW_ADDITIONAL_CANCEL_BUTTON:
      features.myAccountEditPasswordShowAdditionalCancelButtonEnabled,
    MY_ACCOUNT_EDIT_PASSWORD_USE_LABEL_INSTEAD_OF_PLACEHOLDER:
      features.myAccountEditPasswordUseLabelInsteadOfPlaceholderEnabled,
    ACCOUNT_DASHBOARD_SHOW_MEMBERSHIP_SECTION:
      features.myAccountDashboardMembershipSectionEnabled,
    CREATE_ACCOUNT_IN_CHECKOUT: features.createAccountInCheckoutEnabled,
    SHOW_USER_REGISTRATION_ON_CHECKOUT_CONFIRMATION:
      features.showUserRegistrationOnCheckoutEnabled,
    SAVE_ADDRESS_CHECKBOX_ENABLED: features.saveAddressCheckboxEnabled,
    CART_SUMMARY_PROMO_CODE_EXPANDED_ON_MOBILE:
      features.cartSummaryPromoCodeExpandedOnMobile,
    MY_ACCOUNT_LEAVE_RESET_PASSWORD_CONFIRM_POPUP_OPEN:
      features.myAccountLeaveResetPasswordConfirmPopupOpenEnabled,
    SHOW_SALES_BADGE_ON_PRODUCT_IMAGE:
      staticFeatures.showSaleBadgeOnProductImageEnabled,
    DESIRED_DELIVERY_DATE: features.desiredDeliveryDateEnabled,
    OTP_LOGIN: features.otpLoginEnabled && staticFeatures.otpLoginEnabled, //Both flags have to be true for the feature to be active
    REGISTER_OTP_VERIFICATION_ENABLED: features.registerOTPVerificationEnabled,
    PHONE_IN_PROFILE: features.phoneInProfileEnabled,
    AUTO_SHOW_NEWSLETTER_POPUP: features.autoShowNewsletterPopupEnabled,
    SHOW_PRODUCT_KEYWORDS: features.showProductKeywordsEnabled,
    SC_SHOW_CART_ABANDONMENT_MODAL: features.scShowCartAbandonmentModalEnabled,
    MINICART_SEPARATE_VIEW_AND_CHECKOUT_BUTTONS:
      features.miniCartSeparateViewAndCheckoutButtons,
    SHOW_ADDITIONAL_FIELDS_IN_ACCOUNT_EDIT:
      features.myAccountShowAdditionalFieldsInEditEnabled,
    PRICES_EXTENDED: features.priceWithDataEnabled,
    SHOW_ONLY_ORDERABLE_SIZES: features.showOnlyOrderableSizesEnabled,
    MY_ACCOUNT_HIDE_NOTIFICATION_SETTINGS_IN_DASHBOARD:
      features.myAccountHideNotificationSettingsInDashboard,
    PROMO_CODE_ACCORDION_EXPANDED_BY_DEFAULT:
      features.promoCodeAccordionIsExpandedByDefault,
    CHECKOUT_ACCORDION_COLLAPSED_BY_DEFAULT:
      features.collapsableOrderDetailsIsCollapsedOnLoad,
    SHOW_SIMPLIFIED_PDP_FOR_GOOGLE_SHOPPING:
      features.simplifiedGoogleShoppingPdpEnabled,
    HIDE_YOUR_SAVINGS: features.hideYourSavings,
    MY_ACCOUNT_DASHBOARD_ADD_ADDRESS_BUTTON:
      staticFeatures.showAddAddressButtonInAccountDashboard,
    PRODUCT_FILTER_COLLAPSIBLE: staticFeatures.productFilterCollapsible,
    MY_ACCOUNT_ORDER_HISTORY_ORDER_TITLE_TRANSLATION:
      staticFeatures.showTitleTranslationInAccountOrderHistory,
    CAN_REMOVE_WISHLIST_ITEMS_FROM_PDP:
      staticFeatures.canRemoveWishlistItemsFromPDP,
    CAN_SHOW_PHONEICON_IN_CHECKOUT: staticFeatures.showPhoneInCheckout,
    SHOW_PRODUCT_STORY_KEYWORD_TITLE:
      staticFeatures.showProductStoryKeywordTitle,
    SHOW_DYNAMIC_TAX_MESSAGE: features.showDynamicTaxMessage,
    SHOW_DISCOUNT_AMOUNT_AND_TEXT_ENABLED:
      features.showDiscountAmountAndTextEnabled,
    PAYMENT_PROMOTION: features.paymentPromotionEnabled,
    SHOW_NEWSLETTER_SIGNUP_ON_ORDER_CONFIRMATION:
      staticFeatures.showNewsletterSignupOnOrderConfirmation,
    USE_STOCK_MESSAGE_FROM_RESPONSE: staticFeatures.useStockMessageFromResponse,
    PDP_ENABLE_STOCK_MESSAGE: staticFeatures.enableStockStatusInPDP,
    PDP_SHOW_DETAILED_STOCK_MESSAGES:
      staticFeatures.pdpShowStockMessageBySFCCLogic,
    SHOW_SHIPPING_DISCOUNT_IN_CHECKOUT:
      staticFeatures.showShippingDiscountInCheckout,
    SHOW_METRIC_AS_DEFAULT: staticFeatures.showMetricAsDefault,
    MY_ACCOUNT_REDIRECT_AFTER_PROFILE_UPDATE:
      staticFeatures.redirectToAccountDashboardAfterProfileUpdate,
    CHECKOUT_HIDE_SHIPPING_AND_RETURNS_MESSAGE:
      staticFeatures.checkoutHideShippingAndReturnsMessage,
    PDP_DISABLE_RETURNS: staticFeatures.pdpDisableReturns,
    MY_ACCOUNT_HIDE_NEWSLETTER_SETTINGS_IN_DASHBOARD:
      features.myAccountHideNewsletterSettingsInDashboard,
    SHOW_COOKIE_CONSENT_LAYER: staticFeatures.showCookieConsentLayer,
    SHOW_ORDER_TRACKING_NUMBER_IN_ORDER_DETAILS:
      staticFeatures.showOrderTrackingNumberInOrderDetails,
    SHOW_BREADCRUMBS_ON_ORDER_DETAILS:
      staticFeatures.showBreadcrumbsOnOrderDetails,
    PARCEL_LAB_TRACKING_ENABLED: staticFeatures.parcelLabTrackingEnabled,
    SHOW_PRICE_DIFFERENCE: staticFeatures.showPriceDifference,
    CHECKOUT_SHOW_SAVE_CARD_CHECKBOX:
      staticFeatures.checkoutShowSaveCardCheckbox,
    USE_SFCC_DIVIDER_FOR_SEO_DESCRIPTION:
      staticFeatures.useSFCCDividerForSEODescription,
    SHOW_ORDER_SUMMARY_AT_ORDER_HISTORY:
      staticFeatures.showOrderSummaryAtOrderHistory,
    SHOW_REFUND_INFORMATION_AT_ORDER:
      staticFeatures.showRefundInformationAtOrder,
    PERSONALIZATION_ENABLED: staticFeatures.personalizationEnabled,
    HIDE_SOLD_OUT_COLORS_IN_STYLES_SELECTION:
      staticFeatures.hideSoldOutColorsInStylesSelection,
    HIDE_CALLOUT_MESSAGE: staticFeatures.hideCalloutMessage,
    HIDE_EAN_COLOR_CODE: staticFeatures.hideEanColorCode,
    OPLUX_ENABLED: features.opluxEnabled,
    SHOW_ALL_PRICES_AT_ONCE: staticFeatures.showAllPricesAtOnce,
    DISPLAY_PROMO_DISCOUNT_BREAKDOWN:
      staticFeatures.displayPromoDiscountBreakdown,
    EDIT_PASS_RESULT_ERROR_DISABLED:
      staticFeatures.editPasswordResultErrorDisabled,
    HIDE_TERMS_AND_CONDITIONS_ON_LOGIN: staticFeatures.hideTermsOnLoginForm,
    DISPLAY_PROMO_APPLIED_PROMOTION:
      staticFeatures.displayPromoAppliedPromotion,
    SHOW_ONE_FINAL_PRICE_ON_PURCHASED_PRODUCT:
      staticFeatures.showOneFinalPriceOnPurchasedProduct,
    SET_AUTO_CAPITALIZE_OFF: staticFeatures.setAutoCapitalizeOff,
    HIDE_MY_ACCOUNT_SUBTITLE: staticFeatures.hideMyAccountSubtitle,
    SHOW_WISHLIST_ICON_BADGE_ON_DROPDOWN_MENU:
      staticFeatures.showWishlistIconBadgeOnDropdownMenu,
    HIDE_PAYMENT_INFORMATION_ON_ACCOUNT_PAGE:
      staticFeatures.hidePaymentInformationOnAccountPage,
    ALLOW_EDIT_INCORRECT_SAVED_ADDRESS_CHECKOUT:
      staticFeatures.allowEditIncorrectSavedAddressCheckout,
    STORE_CREDITS_ENABLED: features.storeCreditsEnabled,
    OUTFITS_ENABLED: features.outfitsEnabled,
    HIDE_BULLETS_LISTS_PDP: staticFeatures.hideBulletsListsPdp,
    SHOW_KLARNA_DISCLAIMER_MESSAGE: staticFeatures.showKlarnaDisclaimerMessage,
    SHOW_SEARCH_FORM_ON_ERROR: staticFeatures.showSearchFormOnError,
    SHOW_PRODUCT_RECOMMENDER_ON_ERROR:
      staticFeatures.showProductRecommenderOnError,
    SHOW_SEARCH_TIPS_ON_ERROR: staticFeatures.showSearchTipsOnError,
    AUTHORITY_TO_LEAVE_ENABLED: staticFeatures.authorityToLeaveEnabled,
    USE_ACCOUNT_NOTIFICATION_FORM_V2:
      staticFeatures.useAccountNotificationFormV2,
    MY_ACCOUNT_HIDE_EMAIL_NOTIFICATIONS: staticFeatures.hideEmailNotifications,
    QUICK_ADDRESS_FINDER: staticFeatures.quickAddressFinder,
    SHOW_PROMO_EXCLUSION_MSG_ABOVE_PRODUCT_DETAILS:
      staticFeatures.showPromoExclusionMsgAboveProductDetails,
    SHOW_PROMO_EXCLUSION_MSG_IN_BASE_SIZE:
      staticFeatures.showPromoExclusionMsgInBaseSize,
    USE_DEFAULT_JAPAN_CHART: staticFeatures.useDefaultJapanChart,
    POPOVER_LOGIN_ENABLED: staticFeatures.popoverLoginEnabled,
    POPOVER_LOGIN_ENABLED_JP: staticFeatures.popoverLoginEnabledJP,
    REMOVE_RETURN_IFRAME: staticFeatures.removeReturnIframe,
    USER_QUERY_DYNAMIC_YIELD: staticFeatures.userQueryDY,
    SET_DY_LANGUAGE_IN_ENGLISH: staticFeatures.setDyLanguageInEnglish,
    SHOW_GIFT_WITH_PURCHASE_MODAL_IN_MOBILE:
      staticFeatures.showGiftWithPurchaseModalInMobile,
    GIFT_WITH_PURCHASE_NEW_DESIGN: staticFeatures.giftWithPurchaseNewDesign,
    SEARCH_LOCAL_IMAGES_ON_PRODUCT_COMPARISON:
      staticFeatures.searchLocalImagesOnProductComparison,
    SEARCH_LOCAL_IMAGES_ON_DROP_CALENDAR:
      staticFeatures.searchLocalImagesOnDropCalendar,
    STYLE_BAZAAR_VOICE_MODAL: staticFeatures.styleBazaarVoiceModal,
    SHOW_FREE_SHIPPING_PROGRESS_BAR: staticFeatures.showFreeShippingProgressBar,
    CART_QUERY_DYNAMIC_YIELD: staticFeatures.cartQueryDY,
    USE_VARIANT_PROMOTION_EXCLUSION:
      staticFeatures.useVariantPromotionExclusion,
    SHOW_PROMO_EXCLUSION_MSG_IN_GRAY:
      staticFeatures.showPromoExclusionMsgInGray,
    SHOW_PINTEREST_FOOTER_ICON: staticFeatures.showPinterestFooterIcon,
    SHOW_PUMA_CARD: staticFeatures.showPumaCard,
    RETURNS_PAGE_DISABLED: staticFeatures.returnsPageDisabled,
    HIDE_WEIGHT_IN_PRODUCT_MEASUREMENTS:
      staticFeatures.hideWeightInProductMeasurements,
    AMBASSADOR_CARDS_ENABLED: staticFeatures.ambassadorCardsEnabled,
    ADD_ADDRESS_RESULT_ERROR_DISABLED:
      staticFeatures.addAddressResultErrorDisabled,
    TRIGGER_CART_TOTAL_EVENT: staticFeatures.triggerCartTotalEvent,
    ENABLE_ITEM_LINKS_IN_CART_SUMMARY:
      staticFeatures.enableItemLinksInCartSummary,
    USE_MASTER_IDS_IN_CART_RECOMMENDERS:
      staticFeatures.useMasterIdsInCartRecommenders,
    IS_GMO_ENABLED: staticFeatures.isGmoEnabled,
    IS_SALESFLOOR_ENABLED: staticFeatures.isSalesfloorEnabled,
  };

  return !!flags[flagName];
};
