import { encodeProductNameForUri } from './encodeProductNameForUri';

/**
 * This function builds a PDP url based on the given `masterId` and `productName`.
 * It also accepts an optional `params` object which will be converted to a query string.
 * Note: 'color' will be replaced with 'swatch' in the query string.
 *
 * @example
 * buildProductUrl('123456', 'My Product');
 * // returns '/pd/my-product/123456'
 * @example
 * buildProductUrl('123456', 'My Product', { swatch: '75', size: '0120' });
 * // returns '/pd/my-product/123456?swatch=75&size=0120'
 * @example
 * buildProductUrl('123456', 'My Product', { color: '75' });
 * // returns '/pd/my-product/123456?swatch=75'
 * @example
 * buildProductUrl('123456', 'My Product', { search: true });
 * // returns '/pd/my-product/123456?search=true'
 */
export const buildProductUrl = (
  masterId: string,
  productName: string,
  params?: Record<string, string | boolean>
): string => {
  const url = `/pd/${encodeProductNameForUri(productName)}/${masterId}`;

  if (!params) return url;

  const queryString = Object.entries(params)
    .filter(([_, value]) => value !== '')
    .map(([key, value]) => {
      if (key === 'color') key = 'swatch';
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return queryString ? `${url}?${queryString}` : url;
};
