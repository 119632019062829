import { MutableRefObject } from 'react';

import {
  Category,
  CouponItem,
  Currency,
  GmoTokenInput,
  PaymentInstrument,
  ProductSearchHit,
} from '__generated__/graphql';
import { ItemProductInterface } from 'types/analyticTypes';

declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
    Multipayment: {
      getToken: (
        creditCard: GmoTokenInput,
        cb: any
      ) => {
        resultCode: `000` | number;
        tokenObject: {
          isSecurityCodeSet: boolean | string;
          maskedCardNo: string;
          toBeExpiredAt: string;
          token: string;
        };
      };
      init: (apiKey: string) => void;
    };
    onAmazonLoginReady?: () => void;
    OffAmazonPayments: {
      Button: (
        amazonPayButtonId: string,
        amazonPayMerchantId: string,
        params: any
      ) => void;
      Widgets: {
        AddressBook: (params: any) => void;
        Wallet: (params: any) => void;
      };
    };
    Razorpay: any;
  }
}

/* eslint sort-keys: ["error", "asc"] */
export const AnalyticsEvents = {
  ACCOUNT: 'account',
  ACCOUNT_AMBASSADOR: 'my_account_ambassador',
  ACCOUNT_GENERAL: 'my_account_general',
  ACCOUNT_LOYALTY_CARD: 'my_account_loyalty_card',
  ACCOUNT_OVERVIEW: 'my_account_overview',
  ACCOUNT_SETTINGS: 'my_account_settings',
  ADD_ITEM_LIST_TO_CART: 'add_to_cart',
  ADD_PAYMENT_INFO: 'add_payment_info',
  ADD_SHIPPING_INFO: 'add_shipping_info',
  ADD_TO_CART: 'add to cart',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  BEGIN_CHECKOUT: 'begin_checkout',
  CART: 'cart',
  CHANGE_COLOR: 'change_color',
  CHECKOUT: 'checkout', // Collection - review, billing, address,
  CHOOSE_SIZE: 'choose_size',
  ELEVATED_PDP_IMPRESSION: 'elevated pdp impression',
  EMAIL_SIGNUP: 'email signup',
  FOOTER_LINK_CLICKED: 'footer link clicked', // Clarity around Sanity needed
  FORM_ERROR: 'form_error',
  GA4EC_AddItemToCart: 'GA4EC_AddItemToCart',
  GA4EC_AddItemToWishlist: 'GA4EC_AddItemToWishlist',
  GA4EC_AddPaymentInfo: 'GA4EC_AddPaymentInfo',
  GA4EC_AddShippingInfo: 'GA4EC_AddShippingInfo',
  GA4EC_BeginCheckout: 'GA4EC_BeginCheckout',
  GA4EC_ItemListClick: 'GA4EC_ItemListClick',
  GA4EC_ItemListView: 'GA4EC_ItemListView',
  GA4EC_ItemView: 'GA4EC_ItemView',
  GA4EC_NewsletterImpression: 'newsletter_impression',
  GA4EC_OrderConfirmation: 'GA4EC_OrderConfirmation',
  GA4EC_PromotionSelect: 'GA4EC_PromotionSelect',
  GA4EC_PromotionView: 'GA4EC_PromotionView',
  GA4EC_Refinement: 'refinement',
  GA4EC_RemoveItemFromCart: 'GA4EC_RemoveItemFromCart',
  GA4EC_SignUp: 'sign_up',
  GA4EC_SignUpFormOpen: 'sign_up_form_open',
  GA4EC_Sizechart: 'sizechart',
  GA4EC_Stylitics: 'stylitics',
  GA4EC_ViewCart: 'GA4EC_ViewCart',
  GA4_ADD_TO_CART: 'add_to_cart',
  GA4_ADD_TO_WISHLIST: 'add_to_wishlist',
  GA4_CustomEvent: 'GA4customEvent',
  GA4_FORM_ERROR: 'form_error',
  GA4_GENERIC: 'GA4generic',
  GA4_IMAGE_OPENED: 'image_opened',
  GA4_IMPRESSION: 'impression',
  GA4_OVERLAY_VIEW: 'overlay_view',
  GA4_PRODUCT_CLICK: 'product_click',
  GA4_SELECT_PRODUCT_OVERLAY: 'select_product_overlay',
  GA4_SessionCartTotal: 'GA4sessionCartTotal',
  GENERAL: 'general',
  HEADER_LINK_CLICKED: 'header link clicked',
  IMAGE_VIEWED: 'image_viewed',
  LANGUAGE_CHANGED: 'language changed',
  LOGIN: 'login',
  LOGOUT: 'logout',
  MENU_OPEN: 'menu_open',
  MINICART: 'minicart',
  MORE_INFORMATION: 'more_information',
  MY_ACCOUNT_CARD: 'my_account_card',
  NAVIGATION_CLICK: 'navigation_click',
  ORDER_CONFIRMATION: 'order confirmation', // https://pumaglobal.atlassian.net/wiki/spaces/CKS/pages/5421466739/24-02-2021+-+Headless+Enhancements+-+DRAFT
  ORDER_LOOKUP: 'order lookup', // TODO, not implemented
  PAYMENT: 'payment', // Payment Collection, // https://pumaglobal.atlassian.net/browse/CBC-1221
  PAYMENT_CLICK: 'payment_click',
  PAYMENT_TYPE_SELECTED: 'payment type selected',
  PDP_INTERACTION: 'pdp interaction',
  PIXLEE: 'pixlee',
  PRODUCT_CLICKED: 'product clicked', // We clicka  product on a PLP/Search/...
  PRODUCT_DISCOVERY_CHANNEL: 'product_discovery_channel',
  PRODUCT_LIST_LOADED: 'product list loaded', // First load of a PLP/search
  PRODUCT_LIST_REFRESHED: 'product list refreshed', // When a filter/sorting is applied
  PRODUCT_LIST_UPDATED: 'product list updated', // When we paginate further along the list
  PRODUCT_RECOMMENDER_CLICKED: 'product recommender click', // einstein recommender clicked
  PRODUCT_RECOMMENDER_VIEWED: 'product recommender view', // einstein recommender viewed
  PRODUCT_VIEWED: 'product viewed', // Navigating to a PDP
  PROMOTION_CLICK: 'promotion click',
  PROMOTION_VIEW: 'promotion view',
  PROMO_CODE_SUBMITTED: 'promo code submitted', // Used when Promo Codes are entered in the Cart
  PURCHASE: 'purchase',
  QUANTITY_CHANGE: 'quantity_change',
  RATINGS_AND_REVIEWS: 'ratings_and_reviews',
  REFINEMENT_SELECTED: 'refinement selected',
  REMOVE_FROM_CART: 'remove from cart',
  REMOVE_ITEM_FROM_CART: 'remove_from_cart',
  SCROLL: 'scroll',
  SEARCH: 'search',
  SEARCH_MODAL_IMPRESSION: 'search modal impression', // opening the search flyout
  SEARCH_OPEN: 'search_open',
  SEARCH_RECENTLY_VIEWED_PRODUCT_CLICK: 'recently viewed click', // products recently viewed by the customer
  SEARCH_RECOMMENDED_PRODUCT_CLICK: 'suggested product', // products from the recommender (yes, "suggested product" is the event name they want)
  SEARCH_SUGGESTED_PRODUCT_CLICK: 'related product click', // products returned as search suggestions
  SEARCH_SUGGESTED_SEARCH_TERM_CLICK: 'suggested search term', // search terms returned as suggestions
  SEARCH_TRENDING_SEARCH_TERM_CLICK: 'trending searches', // ranked search terms from Google
  SELECT_ITEM: 'select_item',
  SELECT_LOCATION: 'select_location',
  SELECT_PROMOTION: 'select_promotion',
  SHOW_MORE_ALL: 'show_more_all',
  SIGN_UP_FORM_CLOSE: 'sign_up_form_close',
  SIGN_UP_FORM_ERROR: 'sign_up_form_error',
  SMS_OPT_IN: 'sms signup',
  SORT_SELECTED: 'sort selected',
  STYLITICS_INTERACTION: 'stylitics interaction', // Clicking the View Details CTA on the "Shop the Model" bundle for Stylitics
  THUMBNAIL_ARROW: 'thumbnail_arrow',
  VIDEO_COMPLETE: 'video_complete',
  VIDEO_PROGRESS: 'video_progress',
  VIDEO_START: 'video_start',
  VIEW_CART: 'view_cart',
  VIEW_ITEM: 'view_item',
  VIEW_ITEM_LIST: 'view_item_list',
  VIEW_PROMOTION: 'view_promotion',
  WISHLIST_TRANSFER: 'wishlist_transfer',
} as const;

type AnalyticsEventKeys = keyof typeof AnalyticsEvents;

export type PromoTileProps = {
  id: string;
  variantProduct: {
    id: string;
  };
};

export type Promotion = {
  creativeSlot?: string;
  creativeName?: string;
  promotionId?: string;
  promotionName?: string;
};

export type ProductSearchHitWithIndex = ProductSearchHit & { idx?: number };

export type HandleIntersectionPLPProps = {
  productId: string;
  intersectedProductsRef: MutableRefObject<
    Array<ProductSearchHitWithIndex | PromoTileProps>
  >;
  intersectAllProductsRef: MutableRefObject<
    Array<ProductSearchHitWithIndex | PromoTileProps>
  >;
  listProducts: ProductSearchHit[];
  screenSize: number;
  currency: Currency & {
    code: string;
    symbol: string;
    decimalsToDisplay: number;
  };
  offset: number;
  pageviewEventHasFired: boolean;
  currentCategory?: Category | null;
  searchTerm?: string;
  promotion?: Promotion;
};

export type HandleIntersectionCarouselProps = {
  productId: string;
  intersectedProductsRef: MutableRefObject<Array<ItemProductInterface>>;
  intersectAllProductsRef: MutableRefObject<Array<ItemProductInterface>>;
  itemListId: string;
  itemListName: string;
  itemEpId: string;
  itemEpName: string;
  screenSizeCarousel: number;
  pageviewEventHasFired: boolean;
  listProducts: ItemProductInterface[];
  countProductsTriggeredRef: MutableRefObject<number>;
};

export type AnalyticsEventValues = (typeof AnalyticsEvents)[AnalyticsEventKeys];

export type AddPaymentInfoAnalyticsEvent = {
  currency: string;
  products: any[];
  couponItems: CouponItem[];
  paymentInstruments?: PaymentInstrument[];
  paymentMethodGA4?: string;
};

export type ViewCartAnalyticsEvent = {
  currency: string;
  products: any[];
  couponItems: CouponItem[];
};

export type NavigationEvent = {
  event_name: string;
  user_action?: string;
  nav_path?: string;
};

export enum SearchType {
  regular = 'regular search',
  noResults = 'no results search bar',
}

export enum UserAction {
  SHOW_BALANCE = 'show balance',
  NEW_AMBASSADOR_CARD = 'new ambassador card',
}

export enum FormType {
  SHIPPING = 'shipping_form',
  BILLING = 'billing_form',
  CHECKOUT = 'checkout_promo_form',
  CART_PROMO = 'cart_promo_form',
  GIFT_CARD = 'gift_card_form',
}
