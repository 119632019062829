import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  InputCheckbox,
  InputPassword,
  InputText,
  LiveRegion,
  Stack,
} from '@global-ecom/nitro-uds/elements';
import { useRouter } from 'next/router';
import { tw } from 'twind';

import { LoginInput, useUpdateProfileMutation } from '__generated__/graphql';
import { useTranslate } from 'hooks/useTranslations';
import { useAuth } from 'hooks/useAuth';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { useValidation } from 'hooks/useValidation';
import { useFormData } from 'hooks/useFormData';
import { useLoginAndRegister } from 'hooks/useLoginAndRegister';
import { useFeature } from 'hooks/useFeature';
import { useTranslationFieldOrDefault } from 'hooks/useTranslationFieldOrDefault';
import { useAmazon } from 'hooks/useAmazon';
import { usePreferences } from 'hooks/usePreferences';
import TermsAndConditions from 'ui/components/TermsAndConditions';
import TermsAndConditionsBackup from 'ui/components/TermsAndConditionsBackup';
import { extractCode, extractError } from 'utils/extractError';
import { dyLoginEvent } from 'utils/dynamicYield';
import { loginEvent } from 'utils/analytics';
import { usePageEventsContext } from 'hooks/usePageEventsContext';
import { useMainNavContext } from 'hooks/useMainNav';

import { NavigationFunction, OTPAuthStage } from './OTPForm';

type LoginFormData = LoginInput & { phoneNo: string; rememberMe: boolean };

export const LoginWithEmailAndPasswordForm = ({
  onSuccess,
  navigate,
  onForgotPasswordClick,
  isInPopover,
}: {
  onSuccess?: () => void;
  navigate?: NavigationFunction;
  onForgotPasswordClick: () => void;
  isInPopover?: boolean;
}) => {
  const popoverLoginEnabled = useFeature('POPOVER_LOGIN_ENABLED');

  const t = useTranslate();
  const router = useRouter();
  const [validate] = useValidation();
  const getErrorMessageOrDefault = useTranslationFieldOrDefault();
  const auth = useAuth();
  const { data, setValues } = useFormData();
  const { preferences, setPreferences } = usePreferences();
  const { pageviewEventHasFired, setCartEventFired } = usePageEventsContext();

  const lastLoginEmail = preferences['last-login-email'];
  const rememberMeChecked = lastLoginEmail !== undefined;

  const {
    validation: {
      phone: {
        prefix,
        length: { max },
      },
    },
    staticFeatures,
  } = useSiteConfig();

  const {
    register: registerField,
    handleSubmit,
    errors,
    formState,
    watch,
    setValue,
    trigger,
  } = useForm<LoginFormData>({
    defaultValues: {
      email: data.email || lastLoginEmail,
      phoneNo: data.phoneNo ?? prefix,
      rememberMe: rememberMeChecked,
    },
    mode: 'onChange',
  });

  const {
    loginWithEmailAndPassword: [loginResult, login],
  } = useLoginAndRegister();

  const emailFieldValue = watch('email');

  const isOTPEnabled = useFeature('OTP_LOGIN');
  const hideTermsAndConditions = useFeature(
    'HIDE_TERMS_AND_CONDITIONS_ON_LOGIN'
  );

  const { isForgotPasswordFormOpen, mobileMenuOpen } = useMainNavContext();

  const [, updateProfile] = useUpdateProfileMutation();

  const hasExistingAccount = !!(
    data.email &&
    data.phoneNo &&
    data.phoneNo !== prefix
  );

  const { isAmazonpayRegisteredAccount, getAmazonpayUserEmail } = useAmazon();
  const [showedAmazonEmail, setShowedAmazonEmail] = useState(false);

  useEffect(() => {
    setValues && setValues({ email: emailFieldValue });
  }, [emailFieldValue, setValues]);

  useEffect(() => {
    if (showedAmazonEmail) return;

    if (!isAmazonpayRegisteredAccount()) return;

    const amazonpayUserEmail = getAmazonpayUserEmail();
    if (!amazonpayUserEmail) return;

    setValue && setValue('email', amazonpayUserEmail);
    trigger('email');
    setShowedAmazonEmail(true);
  }, [
    isAmazonpayRegisteredAccount,
    getAmazonpayUserEmail,
    showedAmazonEmail,
    setShowedAmazonEmail,
    setValue,
    trigger,
  ]);

  const setLastLoginEmail = (email: string | undefined) => {
    setPreferences(curr => ({
      ...curr,
      'last-login-email': email,
    }));
  };

  const onSubmit = async (loginData: LoginFormData) => {
    const result = await login({
      ...loginData,
      email: data.email || loginData.email,
      uniqueShopperId: auth.uniqueShopperId,
    });

    if (hasExistingAccount) {
      await updateProfile({
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNo,
      });
    }

    if (staticFeatures?.injectDynamicYieldScripts && result.data?.login) {
      dyLoginEvent(result.data?.login?.user?.email);
    }

    if (!result.data?.login) return;

    if (!hasExistingAccount) {
      if (loginData.rememberMe) {
        setLastLoginEmail(loginData.email);
      } else {
        setLastLoginEmail(undefined);
      }
    }

    if (result.data?.login) {
      if (
        popoverLoginEnabled &&
        !window.location.pathname.includes('/account')
      ) {
        window.scrollTo(0, 0);
      }
      if (pageviewEventHasFired) {
        setCartEventFired(false);
        const { email } = result.data?.login.user || {};
        const { customerId } = result.data?.login || {};
        loginEvent(router.asPath, email, customerId);
      }
    }

    if (onSuccess) onSuccess();
  };

  let loginError: string | undefined = undefined;

  if (extractCode(loginResult) === 'UNAUTHENTICATED') {
    loginError = t('invalidLoginPassword');
  } else if (extractError(loginResult)) {
    loginError = extractError(loginResult) as string;
  } else if (loginResult.error?.networkError) {
    loginError = t('networkError');
  }

  const error = data.error ?? loginError;

  // Only show the email and password helper text if the user doesn't
  // have an account, and a translation for this text exists.
  const showEmailAndPasswordHelperText = Boolean(
    !hasExistingAccount && t('enterEmailAndPassword')
  );

  return (
    <Stack
      className={tw(isForgotPasswordFormOpen && !mobileMenuOpen && 'hidden')}
      asChild
      dataTestId="login-form"
      gapY={isInPopover ? 'sm' : 'base'}
    >
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        {showEmailAndPasswordHelperText && <p>{t('enterEmailAndPassword')}</p>}
        {error && (
          <LiveRegion type="assertive">
            <Alert
              variant="error"
              dataTestId="login-form-error"
              content={error}
            />
          </LiveRegion>
        )}
        {hasExistingAccount && (
          <InputText
            id="phoneNo"
            label={t('phoneNumberLabel')}
            dataTestId="auth-field-phoneNo"
            ref={registerField({
              required: t('requiredField'),
              validate: validate.phoneNumber,
              maxLength: {
                value: max,
                message: t<'genericFieldMaxLength'>('genericFieldMaxLength', {
                  length: max,
                }),
              },
            })}
            maxLength={max}
            name="phoneNo"
            disabled
            errorText={errors.phoneNo?.message}
            placeholder={t('phonePlaceholder')}
            type="tel"
            required
            crossOrigin={undefined}
          />
        )}

        <InputText
          id="email"
          label={t('emailPlaceholder')}
          dataTestId="auth-field-email"
          ref={registerField({
            required: getErrorMessageOrDefault('email', 'requiredField'),
            validate: validate.email,
          })}
          name="email"
          type="email"
          disabled={hasExistingAccount}
          errorText={errors.email?.message}
          placeholder={t('email')}
          required
          crossOrigin={undefined}
        />

        <InputPassword
          id="password"
          label={t('passwordLabel')}
          dataTestId="auth-field-password"
          name="password"
          minLength={8}
          maxLength={255}
          placeholder={t('passwordPlaceholder')}
          ref={registerField({
            required: t('requiredFieldPassword'),
            validate: validate.password,
            minLength: {
              value: 8,
              message: t<'passwordMinLength'>('passwordMinLength', {
                length: 8,
              }),
            },
            maxLength: {
              value: 255,
              message: t<'passwordMaxLength'>('passwordMaxLength', {
                length: 255,
              }),
            },
          })}
          errorText={errors.password?.message}
          showErrorIcon
          required
          crossOrigin={undefined}
        />

        {!hasExistingAccount && !isInPopover && (
          <InputCheckbox
            id="rememberMe"
            name="rememberMe"
            ref={registerField()}
            dataTestId="auth-field-remember-me"
            onChange={e => {
              if (!e.target.checked) {
                setLastLoginEmail(undefined);
              }
            }}
          >
            {t('rememberMe')}
          </InputCheckbox>
        )}

        {isAmazonpayRegisteredAccount() && (
          <div
            data-test-id="auth-amazon-pay-notes"
            className="text-xs normal-case"
          >
            <p>{t('amazonPayLoginNote1')}</p>
            <p>{t('amazonPayLoginNote2')}</p>
          </div>
        )}

        {hideTermsAndConditions || isInPopover ? (
          <Button
            dataTestId="auth-button-login"
            label={hasExistingAccount ? t('linkPhone') : t('login')}
            id="login-submit"
            loading={loginResult.fetching}
            disabled={loginResult.fetching || !formState.isValid}
            type="submit"
            dimmedReason={
              formState.isValid ? '' : t('pleaseCorrectInputErrors')
            }
            size={!isInPopover ? 'lg' : 'base'}
            className="mt-4"
          />
        ) : (
          <TermsAndConditions
            page="tacOnCreateAccount"
            backupContent={
              <TermsAndConditionsBackup page="tacOnCreateAccount" />
            }
          >
            <Button
              dataTestId="auth-button-login"
              label={hasExistingAccount ? t('linkPhone') : t('login')}
              id="login-submit"
              loading={loginResult.fetching}
              disabled={loginResult.fetching || !formState.isValid}
              type="submit"
              dimmedReason={
                formState.isValid ? '' : t('pleaseCorrectInputErrors')
              }
              size="lg"
            />
          </TermsAndConditions>
        )}
        <Stack
          direction={{ initial: 'column' }}
          items="start"
          className="flex flex-wrap"
          gapY={'none'}
        >
          {isOTPEnabled && (
            <Button
              dataTestId="auth-button-otp-login"
              variant="underline"
              onClick={() => navigate?.(OTPAuthStage.login)}
              label={t('loginWithOTP')}
            />
          )}
          <Button
            variant="underline"
            dataTestId="forgotten-password-link"
            onClick={onForgotPasswordClick}
            label={t('forgotPassword')}
            style={{ marginTop: isInPopover && !isOTPEnabled ? '-10px' : '0' }}
          />
        </Stack>
      </form>
    </Stack>
  );
};
