import { getMinimalNumber } from 'utils/getMinimalNumber';
import { WishListItem } from '__generated__/graphql';

import { transformListProductToAnalyticsItem } from './analytics-pdp';
import { event, removeNullUndefined } from './analytics-utils';
import { AnalyticsEvents, UserAction } from './analytics.types';

export const myAccountGeneralEvent = ({ userAction }: { userAction: string }) =>
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.ACCOUNT_GENERAL,
    event_params: {
      user_action: userAction,
    },
  });

export const myAccountCardEvents = (params: {
  user_action?: string;
  card_amount?: string;
}) => {
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.MY_ACCOUNT_CARD,
    event_params: removeNullUndefined(params),
  });
};

export const myAccountLoyaltyCardEvent = () =>
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.ACCOUNT_LOYALTY_CARD,
    user_action: 'click loyalty card',
  });

export const addToWishListEvent = (
  currency: string,
  wishListItem: WishListItem,
  quantity?: number,
  imageType?: string,
  imageNumber?: string
) => {
  const productPrice = wishListItem.product?.productPrice;
  const price = productPrice
    ? getMinimalNumber(
        productPrice.price,
        productPrice.promotionPrice,
        productPrice.salePrice
      )
    : undefined;
  const value = (quantity ?? 0) * (price ?? 0);
  const product = wishListItem.product;

  const size = product?.size?.label;
  const item = transformListProductToAnalyticsItem({
    product: product,
    currency,
    quantity: quantity ?? 0,
    categories: {
      item_category: product?.primaryCategoryId,
    },
    size,
    index: undefined,
    itemId: product.styleNumber,
  });
  event(AnalyticsEvents.GA4EC_AddItemToWishlist, {
    event_name: AnalyticsEvents.ADD_TO_WISHLIST,
    ecommerce: {
      currency,
      value: Number(value.toFixed(2)),
      item_id_ep: product.styleNumber,
      item_name_ep: product.name,
      image_type: imageType,
      image_number: imageNumber,
      items: [item],
    },
  });
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.GA4EC_Stylitics,
    event_params: {
      user_action: AnalyticsEvents.GA4_ADD_TO_WISHLIST,
      item_id_ep: product.styleNumber, // The ID of the item presented on the Product page on which a user interacts with the Stylitics feature
      item_name_ep: product.name, // The name of the item presented on the Product page on which a user interacts with the Stylitics feature
      stylitics_outfit_id: undefined, // this field can be undefined in the cases we cannot get the outfit ID
      stylitics_product_id: product.styleNumber, // if (user_action === "add_to_wishlist") => stylitics_product_id: "the ID of the item a user is currently interacting with within the Stylitics feature"
    },
  });
};

export const myAccountAmbassadorEvent = (params: {
  user_action?: UserAction;
  card_amount?: string;
}) =>
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.ACCOUNT_AMBASSADOR,
    event_params: removeNullUndefined(params),
  });

export const myAccountOverviewEvent = ({
  userAction,
}: {
  userAction?: string;
}) =>
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.ACCOUNT_OVERVIEW,
    event_params: { user_action: userAction },
  });

export const myAccountUserSettingsEvent = ({
  userAction,
  section,
}: {
  userAction?: string;
  section?: string;
}) =>
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.ACCOUNT_SETTINGS,
    event_params: {
      user_action: userAction,
      section: section,
    },
  });
