import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Alert,
  Button,
  ButtonCta,
  InputCheckbox,
  InputPassword,
  InputText,
  LiveRegion,
  Stack,
  Text,
} from '@global-ecom/nitro-uds/elements';
import globalRouter from 'next/router';

import { useTranslate } from 'hooks/useTranslations';
import { useValidation } from 'hooks/useValidation';
import { useFormData } from 'hooks/useFormData';
import { dySignUpEvent } from 'utils/dynamicYield';
import { useLoginAndRegister } from 'hooks/useLoginAndRegister';
import { useSiteConfig } from 'hooks/useSiteConfig';
import Modal from 'ui/components/Modal';
import { NavigationFunction, OTPAuthStage } from 'ui/forms/OTPForm';
import { extractError, extractCode } from 'utils/extractError';
import { AnalyticsEvents, getPageType, event } from 'utils/analytics';
import { useAuth } from 'hooks/useAuth';
import { usePageEventsContext } from 'hooks/usePageEventsContext';
import { useMainNavContext } from 'hooks/useMainNav';
import { useIsServer } from 'hooks/useIsServer';
import { useFeature } from 'hooks/useFeature';
import { hashEmail } from 'utils/analytics/hashEmail';

type RegisterForm = {
  phoneNo: string;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  emailList: boolean;
  terms: boolean;
  otpVerifyToken?: string | undefined;
};

export const SignupOTPForm = ({
  onSuccess,
  navigate,
}: {
  onSuccess?: () => void;
  navigate: NavigationFunction;
}) => {
  const t = useTranslate();
  const [validate] = useValidation();
  const { data, setValues } = useFormData();
  const { staticFeatures } = useSiteConfig();
  const auth = useAuth();
  const { injectDynamicYieldScripts } = staticFeatures;
  const { pageviewEventHasFired } = usePageEventsContext();

  const { isRegisterOpen, setLoginOpen, setRegisterOpen } = useMainNavContext();
  const isServer = useIsServer();
  const registerOTPVerificationEnabled = useFeature(
    'REGISTER_OTP_VERIFICATION_ENABLED'
  );

  const {
    register: registerField,
    handleSubmit,
    errors,
    formState,
  } = useForm<RegisterForm>({
    defaultValues: {
      emailList: true,
      phoneNo: data.phoneNo,
      terms: true,
    },
    mode: 'onChange',
  });

  const {
    register: [registerResult, register],
  } = useLoginAndRegister();
  const hasSubmitted = React.useRef(false);

  const shouldShowVerifyPhoneModal =
    !data.phoneNo &&
    !isServer &&
    isRegisterOpen &&
    registerOTPVerificationEnabled;

  useEffect(() => {
    if (pageviewEventHasFired) {
      event(AnalyticsEvents.GA4_CustomEvent, {
        event_name: AnalyticsEvents.GA4EC_SignUpFormOpen,
        event_params: {
          method: getPageType(globalRouter.asPath),
        },
      });
    }
  }, [pageviewEventHasFired]);

  useEffect(() => {
    return () => {
      if (!hasSubmitted.current && pageviewEventHasFired) {
        event(AnalyticsEvents.GA4_CustomEvent, {
          event_name: AnalyticsEvents.SIGN_UP_FORM_CLOSE,
          event_params: {
            method: getPageType(globalRouter.asPath),
          },
        });
      }
    };
  }, [pageviewEventHasFired]);

  const onSubmit = async (data: RegisterForm) => {
    hasSubmitted.current = true;
    const result = await register({
      ...data,
      otpVerifyToken: window.localStorage.getItem('otpToken'),
    });

    const triggerGA4Event = () => {
      event(AnalyticsEvents.GA4_CustomEvent, {
        event_name: AnalyticsEvents.GA4EC_SignUp,
        event_params: {
          method: getPageType(globalRouter.asPath),
          is_guest: 'false',
          hashed_email: hashEmail(data.email),
          custom_user_id: auth.customerId,
        },
      });
    };

    if (extractCode(result) === 'LOGIN_ALREADY_EXISTS') {
      setValues({
        error:
          'This login is already in use. Please log in with your password.',
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNo,
      });

      navigate?.(OTPAuthStage.login_with_email);
    }

    if (!result.data?.register) return;
    if (pageviewEventHasFired) triggerGA4Event();

    if (onSuccess) onSuccess();

    if (onSuccess && injectDynamicYieldScripts) {
      dySignUpEvent(data.email);
    }
  };

  const error = extractError(registerResult) || data.error;

  return (
    <Stack asChild>
      <form
        data-test-id="login-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {error && (
          <LiveRegion type="assertive">
            <Alert
              dataTestId="register-otp-form-error"
              content={error}
              variant="error"
            />
          </LiveRegion>
        )}
        <InputText
          id="firstName"
          label={t('firstNameLabel')}
          dataTestId="auth-field-firstName"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.name,
          })}
          name="firstName"
          errorText={errors.firstName?.message}
          placeholder={t('firstNamePlaceholder')}
          required
          crossOrigin={undefined}
        />
        <InputText
          id="lastName"
          label={t('lastNameLabel')}
          dataTestId="auth-field-lastName"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.name,
          })}
          name="lastName"
          errorText={errors.lastName?.message}
          placeholder={t('lastNamePlaceholder')}
          required
          crossOrigin={undefined}
        />
        <InputText
          id="phoneNo"
          label={t('phoneLabel')}
          dataTestId="auth-field-phoneNo"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.phoneNumber,
          })}
          name="phoneNo"
          errorText={errors.phoneNo?.message}
          placeholder={t('phonePlaceholder')}
          type="tel"
          required
          crossOrigin={undefined}
        />
        <InputText
          id="email"
          label={t('emailLabel')}
          dataTestId="auth-field-email"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.email,
          })}
          name="email"
          errorText={errors.email?.message}
          placeholder={t('emailPlaceholder')}
          required
          crossOrigin={undefined}
        />
        <InputPassword
          id="password"
          label={t('passwordLabel')}
          dataTestId="auth-field-password"
          ref={registerField({
            required: t('requiredField'),
            validate: validate.password,
          })}
          name="password"
          errorText={errors.password?.message}
          placeholder={t('passwordPlaceholder')}
          required
          crossOrigin={undefined}
        />
        <Button
          dataTestId="auth-button-register"
          label={t('continue')}
          id="register-submit"
          loading={registerResult.fetching}
          disabled={registerResult.fetching || !formState.isValid}
          type="submit"
          dimmedReason={t('pleaseCorrectInputErrors')}
        />
        <InputCheckbox
          dataTestId="auth-field-emailList"
          id="emailList"
          name="emailList"
          ref={registerField}
        >
          <span className="font-bold">{t('emailListPlaceholder')}</span>
        </InputCheckbox>
        <InputCheckbox
          id="terms"
          name="terms"
          dataTestId="auth-field-toc"
          ref={registerField({
            validate: value => {
              if (!value) {
                return t('acceptTerms');
              }
            },
          })}
        >
          {t('reviewTerms')}
        </InputCheckbox>
        {errors.terms?.message && (
          <Text asChild dataTestId="toc-message">
            <p>{errors.terms.message}</p>
          </Text>
        )}
        {shouldShowVerifyPhoneModal && (
          <Modal
            variant="darkOpacity"
            dataTestId="verify-my-phone"
            className="max-w-xl p-6 border-none rounded-none origin-center"
          >
            <div className="flex flex-col space-y-6 p-4 sm:p-6">
              <div className="text-center">
                <span className="text-base text-gray-600 leading-none">
                  {t('verifyPhoneNoMessage')}
                </span>
              </div>
              <div className="text-center">
                <ButtonCta
                  id="verify-my-phone-btn"
                  onClick={() => (setLoginOpen(true), setRegisterOpen(false))}
                  label={t('verifyPhoneNoCta')}
                  className="w-full"
                  type="button"
                  dataTestId="verify-my-phone-btn"
                ></ButtonCta>
              </div>
            </div>
          </Modal>
        )}
      </form>
    </Stack>
  );
};
