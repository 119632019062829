import { MainNavItemType } from 'groq/shared/MainNavItem';
import { event, removeNullUndefined } from 'utils/analytics';
import { capitalize } from 'utils/capitalize';

import { AnalyticsEvents, NavigationEvent } from './analytics.types';

export const navigationEvent = ({
  event_name,
  user_action,
  nav_path,
}: NavigationEvent) => {
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name,
    event_params: removeNullUndefined({
      user_action,
      nav_path,
    }),
  });
};

export const footerNavigationEvent = ({
  parentNavigation,
  linkClicked = '',
}: {
  parentNavigation: string;
  linkClicked?: string;
}) => {
  event(AnalyticsEvents.FOOTER_LINK_CLICKED, {
    parentNavigation,
    linkClicked,
  });
  navigationEvent({
    event_name: AnalyticsEvents.NAVIGATION_CLICK,
    user_action: 'footer',
    nav_path: parentNavigation.toLocaleLowerCase(),
  });
};

export const headerNavigationClickEvent = ({ link }: MainNavItemType) => {
  if (link?.href) {
    const parts = categoryUrlToParts(link.href);
    navigationEvent({
      event_name: AnalyticsEvents.NAVIGATION_CLICK,
      user_action: 'header',
      nav_path: categoryPartsToParentNavigationClick(parts).toLowerCase(),
    });
  }
};

export const iconHeaderNavigationClickEvent = (icon: string) =>
  navigationEvent({
    event_name: AnalyticsEvents.NAVIGATION_CLICK,
    user_action: 'header',
    nav_path: `Icon:${icon}`.toLowerCase(),
  });

export const footerNavigationClickEvent = nav_path => {
  navigationEvent({
    event_name: AnalyticsEvents.NAVIGATION_CLICK,
    user_action: 'footer',
    nav_path: nav_path.toLowerCase(),
  });
};

/*
 *  header navigation
 */
const categoryUrlToParts = (url: string): string[] =>
  // '/women/clothing/plus-size' => ['Women', 'Clothing', 'Plus Sizes']
  url
    .split('/')
    .filter(Boolean) // remove any empty string parts (first one caused by leading slash)
    .map(x => capitalize(x.replace(/-/g, ' '))); // remove dashes from and capitalize words in each part

const categoryPartsToParentNavigation = (parts: string[]): string => {
  // '/men' => 'Shop:Men' (level 1) or '/men/shoes/suede' => 'Shop:Men:Shoes' (level 2+)
  if (parts.length > 1) {
    parts = parts.slice(0, -1); // remove the last part for categories greater than level 1 for reporting purposes
  }
  const reportingPrefix = 'Shop';
  return [reportingPrefix, ...parts].join(':');
};

const categoryPartsToParentNavigationClick = (parts: string[]): string => {
  const reportingPrefix = 'Shop';
  return [reportingPrefix, ...parts].join(':');
};

export const headerNavigationEvent = ({
  parentNavigation,
  linkClicked = '',
}: {
  parentNavigation: string;
  linkClicked?: string;
}) => {
  event(AnalyticsEvents.HEADER_LINK_CLICKED, {
    parentNavigation,
    linkClicked,
  });
};

export const categoryHeaderNavigationEvent = ({ link }: MainNavItemType) => {
  if (link?.href) {
    const parts = categoryUrlToParts(link.href);
    headerNavigationEvent({
      parentNavigation: categoryPartsToParentNavigation(parts),
      linkClicked: parts[parts.length - 1], // prefer last part to label
    });
  }
};

export const iconHeaderNavigationEvent = (icon: string) =>
  headerNavigationEvent({
    parentNavigation: `Icon:${icon}`,
    linkClicked: icon,
  });

export const mobileMenuOpenEvent = () =>
  event(AnalyticsEvents.GA4_CustomEvent, {
    event_name: AnalyticsEvents.MENU_OPEN,
  });
