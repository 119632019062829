import {
  CallToActionObject,
  CallToActionObjectType,
} from 'groq/objects/CallToActionObject';
import {
  SignUpFormDocument,
  SignUpFormDocumentType,
} from 'groq/documents/SignUpFormDocument';
import { Maybe } from '__generated__/graphql';

export interface SignUpFormDataObjectType {
  displayOnMobile?: Maybe<boolean>;
  signUpForm?: Maybe<SignUpFormDocumentType>;
}

export type MainFooterQueryType = {
  _id: string;
  _type: 'MainFooter';
  supportLinks?: Maybe<Array<CallToActionObjectType>>;
  aboutLinks?: Maybe<Array<CallToActionObjectType>>;
  legalLinks?: Maybe<Array<CallToActionObjectType>>;
  featuredLinks?: Maybe<Array<CallToActionObjectType>>;
  signUpForm?: SignUpFormDocumentType;
  signUpForms?: Maybe<Array<SignUpFormDocumentType>>;
  signUpFormsData?: Maybe<Array<SignUpFormDataObjectType>>;
};

declare module 'groq/GroqOperationNames' {
  interface GroqOperationNames {
    MainFooter: MainFooterQueryType;
  }
}

export const MainFooterQuery = `*[_type == "MainFooter" && country == $country][0] {
    _id,
    _type,
    ${CallToActionObject('supportLinks[]')},
    ${CallToActionObject('aboutLinks[]')},
    ${CallToActionObject('legalLinks[]')},
    ${CallToActionObject('featuredLinks[]')},
    ${SignUpFormDocument('signUpForm ->')},
    ${SignUpFormDocument('signUpForms[] ->')},
    signUpFormsData[]{
      displayOnMobile,
      ${SignUpFormDocument('signUpForm ->')},
    },
  }`;
