import type { CookieSerializeOptions } from 'cookie';
import { parseCookies, setCookie, destroyCookie } from 'nookies';

import { getMappedCountryCode } from 'utils/locale';
import {
  AuthPayloadFragment,
  TokenPayloadFragment,
} from '__generated__/graphql';
import { clientLogger } from 'utils/clientLogger';

import { AuthState, authStateFromPayload } from './authState';
import { ImpersonationAgent } from './impersonationAgent';

export const AGENT_COOKIE_NAME = 'cybercat-agent';
export const AUTH_COOKIE_NAME =
  process.env.NEXT_PUBLIC_GQL_MOCKS === 'true'
    ? 'cybercat-auth-mocks'
    : 'cybercat-auth';
const IS_DEPLOYED = ['development', 'qa', 'production'].includes(
  process.env.STAGE
);

const maxAge = 90 * 24 * 60 * 60; // 90 days
const defaultCookieOptions: CookieSerializeOptions = {
  maxAge,
  expires: new Date(Date.now() + maxAge * 1000),
  path: '/',
  secure: IS_DEPLOYED,
  httpOnly: false,
  sameSite: 'strict',
};

type AgentAuthDataParams = { agent: ImpersonationAgent; country: string };
export type AuthDataParams = {
  payload: TokenPayloadFragment | AuthPayloadFragment;
  country: string;
};
type RequestContext = Parameters<typeof parseCookies>[0];
type ResponseContext = Parameters<typeof setCookie>[0];

function getCookieCountryPath(country: string) {
  return `/${(getMappedCountryCode(country) || country).toLowerCase()}`;
}

/**
 * Destroy the cookies used for authentication
 */
export function destroyStoredAuthData(country: string, ctx?: ResponseContext) {
  try {
    destroyCookie(ctx, AUTH_COOKIE_NAME, {
      path: getCookieCountryPath(country),
    });
  } catch (err) {
    clientLogger.error({ err }, 'authExchange: failed to destroy auth cookie');
  }
}

/**
 * Destroy the cookies used for agent impersonation
 */
export function destroyStoredAgentAuthData(
  country: string,
  ctx?: ResponseContext
) {
  try {
    destroyCookie(ctx, AGENT_COOKIE_NAME, {
      path: getCookieCountryPath(country),
    });
  } catch (err) {
    clientLogger.error(
      { err },
      'authExchange: failed to destroy agent auth cookie'
    );
  }
}

/**
 * Get the agent impersonation information
 * @param ctx NextJS page or API context, express context, null or undefined.
 * @returns The impersonation agent identification
 */
export function getStoredAgentAuthData(
  ctx?: RequestContext
): ImpersonationAgent | null {
  const cookies = parseCookies(ctx);
  const agentAuthStateCookie = cookies[AGENT_COOKIE_NAME];

  if (agentAuthStateCookie) {
    return JSON.parse(agentAuthStateCookie) as ImpersonationAgent;
  }

  return null;
}

/**
 * Get the currently authenticated users information if exists.
 * If the browser is not authenticated, returns a guest identifier
 * @param ctx NextJS page or API context, express context, null or undefined.
 * @returns The user information
 */
export function getStoredAuthData(ctx?: RequestContext): AuthState {
  const cookies = parseCookies(ctx);
  const authStateCookie = cookies[AUTH_COOKIE_NAME];

  if (authStateCookie) {
    return JSON.parse(authStateCookie);
  }

  return { guest: true };
}

/**
 * Store the agent impersonation data
 * @param ctx NextJS page or API context, express context, null or undefined.
 * @param agent The agent information
 */
export function storeAgentAuthData(
  { agent, country }: AgentAuthDataParams,
  ctx?: ResponseContext
) {
  try {
    const { maxAge, ...withoutMaxAge } = defaultCookieOptions;
    const options = {
      ...withoutMaxAge,
      path: getCookieCountryPath(country),

      // since the request to this API endpoint comes from a different
      // domain, the default of sameSite=Strict would prevent the cookies
      // we create from being forwarded on to the redirect, so we have
      // to remove this restriction for agent logins
      sameSite: IS_DEPLOYED ? 'none' : 'lax',
    } as CookieSerializeOptions;

    setCookie(ctx, AGENT_COOKIE_NAME, JSON.stringify(agent), options);
  } catch (err) {
    clientLogger.error(
      { err },
      'authExchange: failed to set agent auth cookie'
    );
  }
}

/**
 * Store the users authentication data
 * @param ctx NextJS page or API context, express context, null or undefined.
 * @param payload The token payload from the auth API response
 */
export function storeAuthData(
  { payload, country }: AuthDataParams,
  ctx?: ResponseContext
): AuthState {
  try {
    const authState = authStateFromPayload(payload);
    const options = {
      ...defaultCookieOptions,
      path: getCookieCountryPath(country),
    };

    setCookie(ctx, AUTH_COOKIE_NAME, JSON.stringify(authState), options);

    return authState;
  } catch (err) {
    clientLogger.error({ err }, 'authExchange: failed to set auth cookie');
    return { guest: true };
  }
}
